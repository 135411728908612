@import '../../colors.scss';

.research {
  .research-header,
  .highlight-blog,
  .research-list {
    padding-left: 20px;
    padding-right: 20px;
    max-width: 1200px;
    margin: 0 auto;
  }

  .research-title {
    font-size: 60px;
    font-style: italic;
    font-family: 'EB Garamond';
    color: $primary_text;
    margin-top: 0;
    margin-bottom: 30px;
  }

  .research-border {
    width: 100%;
    height: 1px;
    background-color: $border;
    margin: 0;
  }

  .research-header {
    margin-top: 40px;
    margin-bottom: 120px;
    position: relative;

    .blog-category-list {
      position: absolute;
      top: 30px;
      right: 20px;
      text-align: right;
    }

    .category-item {
      cursor: pointer;
      color: $secondary_text;
      text-transform: uppercase;
      font-size: 12px;
      margin-bottom: 5px;

      &:hover {
        color: $success;
      }
    }

    .down-arrow-icon {
      margin-right: 10px;
      margin-top: 15px;
      width: 15px !important;
      cursor: pointer;
      
      * {
        stroke: $secondary_text;
        fill: $secondary_text;
      }
    }

    .down-arrow-icon {
      width: 20px;
      display: inline-block;
    }
  }

  .highlight-blog {
    margin-top: 60px;
    cursor: pointer;
    padding-bottom: 60px;
  
    .highlight-blog-image {
      width: 100%;
      border-radius: 5px;
    }
  
    .highlight-blog-content {
      display: flex;
      align-items: center;
    }
  
    .highlight-blog-title {
      font-size: 30px;
      font-weight: 500;
      color: $primary_text;
      margin-bottom: 30px;
      line-height: 1.3;
    }
  
    .highlight-blog-description {
      font-size: 16px;
      color: $secondary_text;
      margin-top: 30px;
      margin-bottom: 30px;
    }
  
    .highlight-blog-catgory {
      color: $secondary_text;
      font-size: 16px;
      margin-bottom: 10px;
    }
  
    .highlight-blog-published {
      font-size: 16px;
      color: $primary_text;
      margin-top: 10px;
      margin-bottom: 0;
    }
  }

  .research-list-header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .research-title {
      margin: 30px 0;
    }

    .research-filter {
      width: calc(100% - 280px);
      text-align: right;

      @media (max-width: 768px) {
        display: none;
      }
    }

    .research-filter-label {
      font-size: 13px;
      text-transform: uppercase;
      font-weight: 300;
      color: $secondary_text;
      margin-right: 20px;
    }
  
    .research-filter-tabs {
      display: inline-block;
      width: auto;

      .ant-tabs-nav:before,
      .ant-tabs-nav {
        border-bottom: 0 !important;
      }
  
      .ant-tabs-tab {
        font-size: 13px;
        text-transform: uppercase;
        font-weight: 300;
        color: $primary_text;
        padding: 3px 10px;
        border-bottom: 0;
        background: $secondary_bg;
        color: $primary_text;
      }

      .ant-tabs-tab-active {
        border-bottom: 0 !important;
        background: $success;

        .ant-tabs-tab-btn {
          color: white !important;
          text-shadow: none;
        }
      }

      .ant-tabs-ink-bar {
        display: none;
      }
    }
  }

  .research-list {
    padding-top: 0px;
    margin-bottom: 60px;
  }
}