@import '../../colors.scss';

.cac-videos-drawer {
  .ant-drawer-body {
    padding: 0;
  }

  .ant-drawer-title {
    font-size: 21px;
    line-height: 1.3;
  }
}

.cac-videos {
  .selected-cac-video {
    width: 100%;
  }

  .cac-videos-list {
    padding-left: 10px;
    padding-right: 10px;
  }
  
  .cac-video-container {
    border-bottom: 1px solid $border;
    padding: 20px 20px 20px 20px; 
  }
  
  .selected-cac-video-container {
    background: $success_bg;
    border-bottom: 1px solid rgba(0,0,0,0);
    border-radius: 5px;
  }
  
  .cac-video-overlay {
    position: relative;
    padding-top: 56.25%; /* 16:9 Aspect Ratio */
    background: black;
    border-radius: 5px;
    overflow: hidden;
    cursor: pointer;

    &:hover {
      .cac-video,
      .cac-video-icon,
      .video-duration {
        opacity: 0.9;
        transition: opacity 0.5s ease-out;
      }

      .vimeo-thumbnail {
        opacity: 0.7;
        transition: opacity 0.5s ease-out;
      }

      .cac-video-icon {
        transform: translate(-50%, -50%) scale(1.2);
        transition: transform 0.5s ease-out;
      }
    }

    &:active {
      .cac-video-icon {
        transform: translate(-50%, -50%) scale(1.1);
        transition: transform 0.5s ease-out;
      }
    }
  }
  
  .cac-video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100% !important;
    height: 100% !important;
    opacity: 0.3;
    cursor: pointer;
    display: block;
    transition: opacity 0.3s ease;
  }
  
  .cac-video-icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 10;
    font-size: 30px;
    color: white;
    opacity: 0.7;
    cursor: pointer;
    transition: opacity 0.3s ease;
    z-index: 3;
  }

  .vimeo-thumbnail {
    opacity: 0.5;
  }
  
  .video-duration {
    color: white;
    position: absolute;
    bottom: 10px;
    right: 15px;
    opacity: 0.7;
    transition: opacity 0.3s ease;
  }
  
  .video-title {
    font-weight: 600;
    font-size: 21px;
    color: $primary_text;
    margin-bottom: 10px;
    line-height: 1.3;
  }
  
  .video-caption {
    font-size: 14px;
    color: $secondary_text;
    margin-bottom: 20px;
  }
  
  .loading-caption {
    font-size: 14px;
    color: $secondary_text;
  }
  
  .watch-cac-btn {
    height: 40px;
    padding: 0 20px;
  }
  
  .video-controls {
    display: flex;
    align-items: center;
    margin-bottom: 8px;
    
    .watched-tag {
      margin-left: 10px;
      background: rgba(0,0,0,0);
      border: 0;
    }
  }
  
  .selected-video-container {
    margin-bottom: 10px;

    .video-wrapper {
      position: relative;
      padding-top: 56.25%; /* 16:9 Aspect Ratio */
      
      .selected-cac-video {
        position: absolute;
        top: 0;
        left: 0;
        width: 100% !important;
        height: 100% !important;
      }
      
      .video-play-overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: rgba(0, 0, 0, 0.3);
        cursor: pointer;
        
        .video-play-icon {
          font-size: 60px;
          color: white;
        }
      }
    }
  }
}
