@import '../../colors.scss';
@import '../../global.scss';

$mobile_height: 60px;

.mobile-header-padding {
  height: calc($mobile_height);
}

.mobile-header {
  position: fixed;
  height: $mobile_height;
  top: 0;
  left: 0;
  right: 0;
  z-index: 10;
  width: 100vw;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px;
  background: white;
  border-bottom: 1px solid $border;
  overflow-x: hidden;

  .menu-btn {
    position: relative;
    right: 0;
    border: none;
    box-shadow: none;
    font-size: 24px;
    padding: 4px;
    height: 40px;
    width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;

    .anticon {
      font-size: 20px;
    }
  }

  .search-icon {
    margin-right: 12px;
    font-size: 20px;
    display: flex;
    align-items: center;
  }

  .logo-container {
    position: relative;
    left: 5px;
    transform: none;
    display: flex;
    align-items: center;

    .logo-icon {
      width: 22px;
      height: 22px;
    }

    .logo-text {
      font-size: 20px;
      margin-left: 8px;
      font-weight: 600;
    }
  }

  .right-controls {
    display: flex;
    align-items: center;
  }
}

.mobile-drawer {
  .ant-drawer-body {
    padding: 0;
    background: $secondary_bg;
  }
}