@import '../../global.scss';
@import '../../colors.scss';

@import '../../colors.scss';
@import '../../global.scss';

@mixin styling($horizontal_padding,
    $vertical_padding,
    $h1,
    $h1_line_height,
    $h2,
    $h2_line_height,
    $h3,
    $h3_line_height,
    $h4,
    $h4_line_height,
    $h5,
    $h5_line_height,
    $h6,
    $h6_line_height,
) {

    .test-index-page {
        padding: 40px 24px;
        max-width: 1200px;
        margin: 0 auto;

        .page-header {
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-bottom: 32px;
            border-bottom: 0px;

            .header-icon {
                font-size: 36px;
                color: $success;
                margin-bottom: 16px;
            }

            h1 {
                margin: 0 0 8px;
                color: #303030;
                font-weight: 600;
            }

            .page-description {
                font-size: 16px;
                color: #666;
            }
        }

        .search-filters {
            margin-bottom: 24px;

            .filter-controls {
                display: flex;
                align-items: center;
                gap: 0px 16px;

                .search-input {
                    flex: 1;
                    min-width: 240px;
                }

                .select-filters {
                    display: flex;
                    gap: 12px;

                    .type-select,
                    .category-select {
                        min-width: 180px;
                    }
                }
            }

            .active-filters {
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin: 8px 0px;

                .filter-tags {
                    display: flex;
                    flex-wrap: wrap;
                    gap: 8px;

                    .ant-tag {
                        margin-right: 0;
                        padding: 4px 8px;
                        font-size: 14px;
                    }
                }

                .ant-btn-link {
                    padding: 0;
                    margin: 0;
                    color: #666;
                }
            }

            .filter-results-count {
                font-size: 14px;
                margin-top: 8px;
            }
        }

        .loading-container {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            padding: 60px 0;

            p {
                margin-top: 16px;
                color: #666;
            }
        }

        .view-tabs {
            margin-bottom: 24px;

            .ant-tabs-nav {
                margin-bottom: 24px;


            }

            .anticon {
                margin-right: 4px;
            }
        }

        .alphabet-nav {
            position: sticky;
            top: 0;
            z-index: 10;
            background: #f5f5f5;
            padding: 16px;
            border-radius: 8px;
            margin-bottom: 24px;
            display: flex;
            justify-content: center;
            box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);

            .letter-link {
                text-decoration: none;

                &:hover {
                    text-decoration: none;
                }

                .ant-btn {
                    width: 36px;
                    height: 36px;
                    padding: 0;
                    font-weight: 500;
                }
            }
        }

        .letter-group {
            scroll-margin-top: 80px;

            .letter-heading {
                display: flex;
                align-items: center;
                margin-bottom: 20px;
                color: #333;

                .letter-badge {
                    background-color: #0ca37f;
                    color: white;
                    width: 40px;
                    height: 40px;
                    border-radius: 50%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-size: 22px;
                    margin-right: 12px;
                }

                .letter-count {
                    font-size: 16px;
                    color: #666;
                    font-weight: normal;
                    margin-left: 12px;
                }
            }
        }

        .letter-heading,
        .category-heading {
            display: flex;
            align-items: center;
            margin-bottom: 20px;
            color: #333;

            .letter-badge {
                background-color: #0ca37f;
                color: white;
                width: 40px;
                height: 40px;
                border-radius: 50%;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 22px;
                margin-right: 12px;
            }

            .letter-count,
            .category-count {
                font-size: 16px;
                color: #666;
                font-weight: normal;
                margin-left: 12px;
            }
        }

        .tests-grid {
            margin-bottom: 40px;
        }

        .test-card {
            height: 100%;
            transition: all 0.2s;
            border-radius: 8px;
            overflow: hidden;
            box-shadow: 0 2px 8px rgba(0, 0, 0, 0.06);
            cursor: pointer;
            transition: all 0.2s ease;

            &:hover {
                transform: translateY(-3px);
                box-shadow: 0 6px 16px rgba(0, 0, 0, 0.12);
            }

            &:active {
                transform: translateY(-1px);
                box-shadow: 0 4px 8px rgba(0, 0, 0, 0.08);
            }

            &.panel-card {
                // border-left: 3px solid #1890ff;
                //border-left: 2px solid #666;
            }

            .ant-card-body {
                height: 100%;
                padding: 16px;
                display: flex;
                flex-direction: column;
            }

            .test-card-content {
                display: flex;
                flex-direction: column;
                height: 100%;

                .card-header {
                    // margin-bottom: 10px;
                    display: flex;
                    flex-wrap: wrap;
                    align-items: flex-start;
                    gap: 8px;


                    .test-title {
                        font-size: $h4;
                        font-weight: 600;
                        margin: 0;
                        color: #303030;
                        line-height: 1.4;
                        flex: 1;
                        min-width: 0;
                    }

                    .tests-count-tag {
                        margin: 0;
                        font-size: 12px;
                        background-color: #f5f5f5;
                        color: #666;
                        border: 1px solid #e8e8e8;
                        white-space: nowrap;
                    }
                }

                .test-full-name {
                    font-size: 13px;
                    color: #666;
                    flex: 0 0 auto;
                }

                .item-tagline {
                    font-size: 13px;
                    color: #666;
                    margin-top: 10px;
                    margin-bottom: 12px;
                    line-height: 1.4;
                    flex: 0 0 auto;
                }

                .content-spacer {
                    flex: 1 0 auto;
                    min-height: 8px;
                }

                .card-footer {
                    margin-top: auto;
                    padding-top: 10px;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    width: 100%;

                    .price-tag {
                        font-weight: 500;
                        color: #666;
                        font-size: $h6;

                    }


                    .tests-count-tag {
                        margin-left: auto;
                        margin-inline-end: 0;
                    }
                }
            }
        }

        .category-group {
            margin-bottom: 40px;

            .category-heading {
                margin-bottom: 16px;
                padding-bottom: 8px;
                border-bottom: 2px solid $success;
            }
        }

        .tests-counter {
            text-align: center;
            margin-top: 40px;
            color: #666;
        }
    }

    // Responsive design
    @media (max-width: 768px) {
        .test-index-page {
            padding: 24px 16px;

            .search-filters {
                .filter-controls {
                    flex-direction: column;
                    align-items: stretch;

                    .search-input {
                        width: 100%;
                        margin-bottom: 12px;
                    }

                    .select-filters {
                        width: 100%;
                        gap: 8px;

                        .type-select,
                        .category-select {
                            flex: 0 0 calc(50% - 4px);
                            min-width: 0;
                        }
                    }
                }
            }

            .alphabet-nav {
                overflow-x: auto;
                padding: 12px 8px;
                justify-content: flex-start;

                .letter-button {
                    width: 32px;
                    height: 32px;
                    font-size: 12px;
                }
            }

            .letter-heading,
            .category-heading {
                .letter-badge {
                    width: 36px;
                    height: 36px;
                    font-size: 18px;
                }
            }
        }

        // Smaller mobile devices
        @media (max-width: 480px) {
            .search-filters {
                .filter-controls {
                    .select-filters {

                        .type-select,
                        .category-select {
                            .ant-select-selector {
                                padding-left: 8px;
                                padding-right: 24px;
                            }

                            .ant-select-selection-item {
                                font-size: 13px;
                            }
                        }
                    }
                }
            }
        }
    }

}


@media screen and (max-width: 768px) {
    @include styling($horizontal_padding: $mobile_horizontal_padding,
        $vertical_padding: $mobile_vertical_padding,
        $h1: $mobile_h1,
        $h1_line_height: $mobile_h1_line_height,
        $h2: $mobile_h2,
        $h2_line_height: $mobile_h2_line_height,
        $h3: $mobile_h3,
        $h3_line_height: $mobile_h3_line_height,
        $h4: $mobile_h4,
        $h4_line_height: $mobile_h4_line_height,
        $h5: $mobile_h5,
        $h5_line_height: $mobile_h5_line_height,
        $h6: $mobile_h6,
        $h6_line_height: $mobile_h6_line_height,
    );
}

@media screen and (min-width: 769px) {
    @include styling($horizontal_padding: $desktop_horizontal_padding,
        $vertical_padding: $desktop_vertical_padding,
        $h1: $desktop_h1,
        $h1_line_height: $desktop_h1_line_height,
        $h2: $desktop_h2,
        $h2_line_height: $desktop_h2_line_height,
        $h3: $desktop_h3,
        $h3_line_height: $desktop_h3_line_height,
        $h4: $desktop_h4,
        $h4_line_height: $desktop_h4_line_height,
        $h5: $desktop_h5,
        $h5_line_height: $desktop_h5_line_height,
        $h6: $desktop_h6,
        $h6_line_height: $desktop_h6_line_height,
    );
}