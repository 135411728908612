@import '../../colors.scss';
@import '../../global.scss';

@mixin styling($horizontal_padding,
  $vertical_padding,
  $h1,
  $h1_line_height,
  $h2,
  $h2_line_height,
  $h3,
  $h3_line_height,
  $h4,
  $h4_line_height,
  $h5,
  $h5_line_height,
  $h6,
  $h6_line_height,
) {

  // Loading
  .test-chart-loading {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    background: $secondary_bg;
    border-radius: 5px;

    .loading-icon {
      margin-right: 10px;
    }

    .loading-text {
      font-size: 16px;
      color: $secondary_text;
    }
  }

  // Prescription event
  .prescription-event {
    position: absolute;
    transform: translate(-50%, 0);
    background: white;
    border-radius: 3px;
    border: 1px solid $border;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    padding: 5px 7px;

    &:hover {
      z-index: 10; // Bring to front on hover
      transform: translateX(-50%) scale(1.05); // Optional: slight scale effect
      box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15); // Optional: add shadow on hover
    }

    &.paused {
      border-left: 3px solid $error;
    }

    &.started {
      border-left: 3px solid $success;
    }

    .event-type {
      font-weight: bold;
      font-size: 11px;
      margin-bottom: 1px;
      white-space: nowrap;
    }

    .timestamp {
      color: #999;
      font-size: 11px;
      white-space: nowrap;
    }
  }

  .test-chart-component-container {

    .mobile-footer {
      // display: none;
      position: fixed;
      left: 0;
      right: 0;
      padding: 12px 16px;
      background-color: white;
      box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
      z-index: 10000;


      .mobile-order-button-container {
        gap: 12px;
        width: 100%;
      }



      .order-button {
        width: 100%;
        padding: 14px;
        font-size: 16px;
        height: 50px;
        color: white;
        border: none;
        border-radius: 4px;
        font-weight: 600;
        cursor: pointer;
        transition: background-color 0.2s;
        box-shadow: 0 2px 4px rgba(12, 163, 127, 0.2);
        display: flex;
        align-items: center;
        justify-content: center;

      }
    }

  }

  // Component
  .test-chart-component {
    background-color: #fff;
    padding: 0;
    width: 100%;
    overflow-x: hidden;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.05);

    /* Prevent horizontal overflow */

    .chart-header {
      padding: 32px 24px 16px;
      // border-bottom: 1px solid #f0f0f0;
      display: flex;
      justify-content: space-between;
      align-items: flex-start;

      .header-content {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
      }

      .test-info {
        flex-grow: 1;

        .test-tagline {
          font-size: $h5;
          font-weight: 400;
          color: #666;
        }

        .test-value-container {
          display: flex;
          align-items: baseline;
          gap: 12px;
        }

        .test-name-container {
          // margin-bottom: 16px;

          .test-name {
            font-size: calc($h3 * 1.33);
            font-weight: 600;
            color: #333;
            margin: 0;
            line-height: 1.3;
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            gap: 12px;

            .test-abbreviation {
              font-size: calc($h3);
              color: #666;
              font-weight: 500;
              padding: 6px 8px;
              background-color: #f5f5f5;
              border-radius: 8px;
              display: inline-flex; // Change to inline-flex
              align-items: center; // Center content vertically
              // height: calc($h3 * 1.33);

              &::before {
                content: '';
                display: none; // Hide parentheses in favor of visual styling
              }

              &::after {
                content: '';
                display: none; // Hide parentheses in favor of visual styling
              }
            }
          }
        }

        .test-tagline {
          font-size: $h5;
          font-weight: 400;
          color: #666;
        }

        .test-value {
          font-size: calc($h3 * 1.33);
          font-weight: 500;
          color: #000;
        }

        .test-unit {
          font-size: 16px;
          color: #666;
          margin-left: 4px;
        }

        .delta-value {
          font-size: 16px;
          font-weight: 500;


          &.improved {
            color: $success;
          }

          &.worsened {
            color: $error;
          }

          .delta-label {
            color: #666;
            margin-left: 4px;
          }
        }

        .time-selector {
          display: flex;
          gap: 8px;
          margin-top: 16px;
          overflow-x: auto;
          padding-bottom: 4px;

          .time-button {
            background: none;
            border: none;
            padding: 6px 12px;
            border-radius: 16px;
            font-size: 14px;
            cursor: pointer;
            color: #666;

            &:hover {
              background-color: #f5f5f5;
            }

            &.active {
              background-color: $success;
              color: white;
              font-weight: 500;
            }
          }
        }
      }

      .order-button-container {
        flex-shrink: 0; // Prevent button from shrinking
        margin-left: 24px;
        display: flex;
        align-items: center;
        align-self: center;
        top: 24px;
        background: #fff;
        margin-top: 24px;

      }

      .order-button {
        font-weight: 600;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 15px 25px;
        font-size: $h5;
        height: 50px;
      }
    }

    .chart-area {

      margin-top: -16px;
      padding: 0px 0px 16px 16px;
      height: 350px;
      /* Fixed height for desktop */
      width: 100%;
      position: relative;

      .chart-container {
        height: 100%;
        width: 100%;
        position: relative;
        overflow: hidden;
        /* Prevent chart overflow */
      }
    }

    .test-chart-section {
      padding: 24px;
      border-top: 1px solid #f0f0f0;
      border-bottom: 1px solid #f0f0f0;

      h2 {
        font-size: $h3;
        font-weight: 500;
        margin-bottom: 16px;
        display: block;
        margin-top: 0;
      }
    }


    .stats-cards {
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
      gap: 16px;

      .stats-card {
        display: flex;
        flex-direction: column;
        gap: 4px;
        // background-color: $secondary_bg;
        // padding: 16px;
        // border-radius: 8px;

        .stats-label {
          font-size: 14px;
          color: #666;
        }

        .stats-value {
          font-size: 18px;
          font-weight: 500;
          color: #000;

          &.risk-optimal {
            color: $success;
          }

          &.risk-moderate,
          &.risk-borderline {
            color: $warning;
          }

          &.risk-high,
          &.risk-abnormal {
            color: $error;
          }

          &.increase {
            color: $error;
          }

          &.decrease {
            color: $success;
          }

          &.improved {
            color: $success;
          }

          &.worsened {
            color: $error;
          }
        }
      }
    }

    .about-section,
    .faqs-section,
    .references-section,
    .recommendations-section {
      padding: 24px;

      .about-content,
      .faqs-content {
        color: #333;
        line-height: $h5_line_height;
        font-size: $h5;

        h3 {
          font-size: $h4;
          font-weight: 600;
          margin-bottom: 16px;
        }

        h4 {
          font-size: $h5;
          font-weight: 600;
          margin-bottom: 16px;
        }

        p,
        ul,
        ol {
          margin-bottom: 16px;

        }

        strong {
          font-weight: 600;
        }

        li {
          margin-bottom: 16px;
        }


        table {
          width: 100%;
          border-collapse: collapse;
          margin-bottom: 16px;

          th {
            font-weight: 500;

          }

          td {
            padding: 8px;
            border: 1px solid #ddd;
          }
        }
      }

      .faqs-content {
        background: #FBFBFB;
        border: 1px solid #E6E6E6;
        padding: 10px 10px 0 10px;
        margin: 1rem 0;


        .questions {
          padding: 0;
          margin: 0;

          .ant-collapse-arrow {
            font-size: 12px !important;
          }
        }

        .question-collapse {


          .ant-collapse-item {
            border-top: 1px solid rgba(0, 0, 0, 0.15) !important;
          }

          .question-panel {
            box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.1);
            border: 1px solid rgba(0, 0, 0, 0.15);
            border-radius: 5px;
            margin-bottom: 10px;
            cursor: pointer;


            .ant-collapse-header {
              align-items: center !important;
              background: white;
              border-radius: 8px !important;
              padding: 20px !important;

              .ant-collapse-header-text {
                font-size: $h4;
                line-height: $h4_line_height;
                font-weight: 500;
                color: #2c3e50;
              }

              .ant-collapse-expand-icon {
                margin-top: 0px !important;
              }

            }

          }

        }

        .answer {
          font-size: $h5;
          line-height: $h5_line_height;
          font-weight: 400;
          color: #333;
        }


        .ant-collapse-content-box {
          padding: 20px;
          color: #595959;
          font-size: $h5;
          line-height: $h5_line_height;
          border-top: 1px solid #d9d9d9;
          background: white;
        }

      }
    }



    .recommendations-section {


      .recommendations-content {
        background: #FBFBFB;
        border: 1px solid #E6E6E6;
        padding: 10px 10px 0 10px;
      }
    }


    .references-section {


      .references-list {
        ol {
          padding-left: 16px;

          .reference-item {
            position: relative;
            margin-bottom: 16px;

            color: #444;
            padding-left: 8px;

            .reference-link {
              color: #333;
              text-decoration: none;
              font-size: $h5;

              &:hover {
                text-decoration: underline;
              }

              &:visited {
                color: #551a8b;
              }
            }

            .reference-text {
              font-style: normal;
            }
          }
        }
      }
    }

    .test-chart-loading {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 400px;

      .loading-text {
        display: flex;
        align-items: center;
        gap: 8px;
        font-size: 16px;
        color: #666;
      }
    }

    .test-results-table {
      width: 100%;
      padding: 20px;
      background-color: #fff;


      .table-container {
        max-height: 400px;
        overflow-y: auto;

        &::-webkit-scrollbar {
          width: 6px;
          height: 6px;
        }

        &::-webkit-scrollbar-thumb {
          background-color: rgba(0, 0, 0, 0.2);
          border-radius: 3px;
        }

        &::-webkit-scrollbar-track {
          background-color: rgba(0, 0, 0, 0.05);
        }
      }

      table {
        width: 100%;
        border-collapse: separate;
        border: 1px solid #f0f0f0;
        border-spacing: 0;
        // border-radius: 8px;
        // box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);

        th,
        td {
          padding: 14px 16px;
          text-align: left;
          border-bottom: 1px solid #f0f0f0;
        }

        th {
          font-weight: 500;
          color: #666;
          background-color: #fafafa;
          position: sticky;
          top: 0;
          z-index: 10;
          box-shadow: 0 1px 0 #f0f0f0;
        }

        tr {
          transition: background-color 0.2s;

          &:hover {
            background-color: #f9f9f9;
          }

          &:last-child td {
            border-bottom: none;
          }
        }

        .date-cell {
          color: #666;
          font-weight: 500;
          width: 30%;
        }

        .value-cell {
          font-weight: 500;
          color: #333;
          width: 40%;
          font-size: 16px;
        }

        .risk-cell {
          width: 30%;

          .risk-badge {
            display: inline-block;
            padding: 4px 12px;
            border-radius: 16px;
            font-size: 13px;
            font-weight: 500;
            text-align: center;

            &.risk-optimal {
              background-color: rgba(12, 163, 127, 0.1);
              color: $success;
            }

            &.risk-moderate {
              background-color: rgba(255, 192, 0, 0.1);
              color: $warning;
            }

            &.risk-high,
            &.risk-abnormal {
              background-color: rgba(237, 110, 127, 0.1);
              color: $error;
            }

            &.risk-unknown {
              background-color: rgba(0, 0, 0, 0.05);
              color: #999;
            }
          }
        }
      }

      // Responsive adjustments
      @media (max-width: 768px) {
        padding: 16px;

        h3 {
          font-size: 18px;
          margin-bottom: 16px;
        }

        table {

          th,
          td {
            padding: 12px;
          }

          .value-cell {
            font-size: 15px;
          }

          .risk-cell .risk-badge {
            padding: 3px 8px;
            font-size: 12px;
          }
        }
      }

      @media (max-width: 480px) {
        padding: 12px;

        table {

          th,
          td {
            padding: 10px 8px;
          }

          .date-cell,
          .value-cell,
          .risk-cell {
            width: auto;
          }
        }
      }
    }

    @media (max-width: 768px) {
      .chart-header {
        padding-bottom: 0px;

        .test-value {
          font-size: 28px;
          padding: 8px 0;
        }

        .chart-area {
          height: 300px;
          /* Smaller height on tablets */
        }

      }

      .stats-cards {
        grid-template-columns: repeat(2, 1fr);
      }

      .test-name-container {
        .test-name {
          font-size: 24px;

          .test-abbreviation {
            font-size: 16px;
          }
        }
      }
    }

    @media (max-width: 480px) {
      .chart-area {
        height: 250px;
        /* Even smaller height on mobile phones */
      }
    }
  }

  // order button container for desktop view

  .order-button-container {
    position: sticky;
    top: 0px;
    display: block; // Change from flex to block for full width
    width: 100%;
    max-width: 100%; // Ensure it doesn't exceed the column width
    margin: 0; // Remove any margin that might be limiting width
    padding: 0; // Remove any padding that might be affecting layout

  }

  /* Fix for Chart.js responsive behavior */
  canvas.chartjs-render-monitor {
    max-width: 100% !important;
    height: auto !important;
  }

  // Media queries for responsive design
  @media (max-width: 767px) {
    .chart-header {
      .header-content {
        flex-direction: column;
        align-items: flex-start;
      }

      .test-info {
        width: 100%;
        margin-bottom: 10px;
      }

      .order-button-container {
        display: none; // Hide desktop button on mobile
      }


    }

    .mobile-footer {
      display: block; // Show mobile footer on small screens
    }

  }
}


@media screen and (max-width: 768px) {
  @include styling($horizontal_padding: $mobile_horizontal_padding,
    $vertical_padding: $mobile_vertical_padding,
    $h1: $mobile_h1,
    $h1_line_height: $mobile_h1_line_height,
    $h2: $mobile_h2,
    $h2_line_height: $mobile_h2_line_height,
    $h3: $mobile_h3,
    $h3_line_height: $mobile_h3_line_height,
    $h4: $mobile_h4,
    $h4_line_height: $mobile_h4_line_height,
    $h5: $mobile_h5,
    $h5_line_height: $mobile_h5_line_height,
    $h6: $mobile_h6,
    $h6_line_height: $mobile_h6_line_height,
  );
}

@media screen and (min-width: 769px) {
  @include styling($horizontal_padding: $desktop_horizontal_padding,
    $vertical_padding: $desktop_vertical_padding,
    $h1: $desktop_h1,
    $h1_line_height: $desktop_h1_line_height,
    $h2: $desktop_h2,
    $h2_line_height: $desktop_h2_line_height,
    $h3: $desktop_h3,
    $h3_line_height: $desktop_h3_line_height,
    $h4: $desktop_h4,
    $h4_line_height: $desktop_h4_line_height,
    $h5: $desktop_h5,
    $h5_line_height: $desktop_h5_line_height,
    $h6: $desktop_h6,
    $h6_line_height: $desktop_h6_line_height,
  );
}