@import '../../colors.scss';
@import '../../global.scss';

@mixin styling($horizontal_padding,
    $vertical_padding,
    $h1,
    $h1_line_height,
    $h2,
    $h2_line_height,
    $h3,
    $h3_line_height,
    $h4,
    $h4_line_height,
    $h5,
    $h5_line_height,
    $h6,
    $h6_line_height) {

    .footer-message-form {
      width: 100%;
      padding: 10px 15px 10px 20px;
      background: #fff;
      border-radius: 8px;
      margin-top: 30px;
      display: block;
      box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
      transition: background 0.3s ease;

      input {
        transition: background 0.3s ease;
      }

      &.error {
        background: $error_bg;
        transition: background 0.3s ease;

        input {
          transition: all 0.3s ease;
          background: $error_bg;
          color: $error;
        }
      }
  
      .message-form-label {
        font-weight: 600;
        display: block;
        font-size: 16px;
        margin-top: 10px;
      }
  
      .message-form-email {
        border: none;
        width: 280px;
        padding-left: 0;
        padding-right: 0;
        font-size: 16px;
      }
  
      .message-btn {
        width: 100%;
        height: 69px;
        margin-bottom: 4px;
        font-size: 18px;
        font-weight: 600;

        @media (max-width: 767px) {
          margin-top: 20px;
        }
      }
    }

    .messaging-container {
      margin-bottom: 50px;
      width: 100%;
      max-width: 500px;
      margin: 0 auto;
      padding: 0 10px;
    }

    .messaging-h1 {
      text-align: center;
      font-weight: 600;
      font-size: 55px;
      line-height: 1.2;
      color: $primary_text;
      margin-top: 0;
      margin-bottom: 15px !important;
    }

    .messaging-p1 {
      text-align: center;
      font-weight: 400;
      font-size: 26px;
      line-height: 1.5;
      color: $primary_text;
      margin-top: 0;
      margin-bottom: 0;
    }

    .messaging-border {
      height: 1px;
      background: $border;
      margin: 60px 0 50px 0;
    }

    .footer-section {
      background: #F9FBFC;
      padding: 64px 0 32px;
      margin-top: auto;
      border-top: 1px solid #e2e8f0;

      &.has-messaging {
        border-top: 0;
      }

      .footer-content {
          display: grid;
          grid-template-columns: 2fr repeat(3, 1fr);
          gap: 48px;
          margin-bottom: 48px;

          @media (max-width: 768px) {
            grid-template-columns: 1fr;
            gap: 32px;
          }

          .company-info {
            .footer-logo {
              height: 40px;
              margin-bottom: 16px;
            }

            p {
              color: #4a5568;
              margin-bottom: 24px;
            }

            .social-links {
              display: flex;
              gap: 16px;

              a {
                color: #4a5568;
                font-size: 20px;
                transition: color 0.2s ease;

                &:hover {
                  color: #2d3748;
                }
              }
            }
          }

          .footer-column {
              h4 {
                  color: #2d3748;
                  font-size: 16px;
                  font-weight: 600;
                  margin-bottom: 16px;
                  margin-top: 0px;
              }

              ul {
                  list-style: none;
                  padding: 0;
                  margin: 0;

                  li {
                      margin-bottom: 12px;

                      a {
                          color: #4a5568;
                          text-decoration: none;
                          transition: color 0.2s ease;

                          &:hover {
                              color: #2d3748;
                          }
                      }
                  }
              }
          }
      }



      .logo-container {
          cursor: pointer;
      }

      .logo {
          width: 26px;
          vertical-align: middle;
          float: left;
          margin-right: 10px;
          margin-top: 5px;
      }

      .logo-name {
          font-size: $h3;
          line-height: $h3_line_height;
          display: inline-block;
          font-weight: 600;
      }

      .web-chat-link {
          color: inherit;
          text-decoration: none;
          padding: 0;
          font-size: inherit;
          background: none;
          border: none;
          cursor: pointer;
          transition: color 0.3s ease;
          margin-left: 3px;
          height: unset;

          &:hover {
              color: $success;
          }
      }
  }
}


@media screen and (max-width: 768px) {
  @include styling($horizontal_padding: $mobile_horizontal_padding,
      $vertical_padding: $mobile_vertical_padding,
      $h1: $mobile_h1,
      $h1_line_height: $mobile_h1_line_height,
      $h2: $mobile_h2,
      $h2_line_height: $mobile_h2_line_height,
      $h3: $mobile_h3,
      $h3_line_height: $mobile_h3_line_height,
      $h4: $mobile_h4,
      $h4_line_height: $mobile_h4_line_height,
      $h5: $mobile_h5,
      $h5_line_height: $mobile_h5_line_height,
      $h6: $mobile_h6,
      $h6_line_height: $mobile_h6_line_height );


}

@media screen and (min-width: 769px) {
  @include styling($horizontal_padding: $desktop_horizontal_padding,
      $vertical_padding: $desktop_vertical_padding,
      $h1: $desktop_h1,
      $h1_line_height: $desktop_h1_line_height,
      $h2: $desktop_h2,
      $h2_line_height: $desktop_h2_line_height,
      $h3: $desktop_h3,
      $h3_line_height: $desktop_h3_line_height,
      $h4: $desktop_h4,
      $h4_line_height: $desktop_h4_line_height,
      $h5: $desktop_h5,
      $h5_line_height: $desktop_h5_line_height,
      $h6: $desktop_h6,
      $h6_line_height: $desktop_h6_line_height );
}