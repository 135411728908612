.test-panel-modal {

    .ant-modal-body {
        max-height: 60vh;
        overflow: auto;
    }

    .ant-collapse-header {
        border-radius: 8px;
    }

    .test-panel-list {
        .ant-collapse-content-box {
            padding: 0;
        }

    }


    .single-test-item {
        border: 1px solid rgb(230, 230, 230);
        padding: 12px 16px;
        margin: 12px 0;
        border-radius: 8px;

        &:last-child {
            margin-bottom: 0;
            border-bottom: 1px solid rgb(230, 230, 230);
        }

    }


    .test-name {
        font-size: 14px;
        font-weight: 500;
        color: #262626;
    }

    .test-tagline {
        color: #8c8c8c;
        font-size: 14px;
        font-weight: 400;
    }

    .single-test {
        background: #fff;
        border-radius: 8px;
        padding: 16px 24px;
        border: 1px solid #f0f0f0;
    }


    .ant-list-items {
        .ant-collapse {
            margin-bottom: 12px;
        }
    }

}