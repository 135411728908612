@import '../../../../colors.scss';
@import '../../../../global.scss';

@mixin styling($horizontal_padding,
  $vertical_padding,
  $h1,
  $h1_line_height,
  $h2,
  $h2_line_height,
  $h3,
  $h3_line_height,
  $h4,
  $h4_line_height,
  $h5,
  $h5_line_height) {
  .heart-health-v2-confirmed {
    position: relative;


    .member-confirmed-body {
      background: white;
      max-width: 800px;
      margin: 0 auto;
      height: 100%;
      position: relative;
      padding: $vertical_padding $horizontal_padding;
    }

    .member-title {
      text-align: center;
      font-weight: 600;
      margin-top: 0px;
      margin-bottom: calc($vertical_padding/3);
      font-size: calc($h2 * 0.8);
    }

    .member-description {
      text-align: center;
      font-size: $h4;
    }

    .member-description a {
      text-decoration: underline;
      color: $success;
    }

    .close-icon {
      color: rgba(0, 0, 0, 0.3);
      margin: calc($vertical_padding/3) 0;
    }

    .next-steps-container {
      max-width: 800px;
      margin: 40px auto;
      padding: 0 20px;

      .next-steps-title {
        text-align: center;
        font-size: 24px;
        margin-bottom: 30px;
      }

      .step-card {
        display: flex;
        gap: 20px;
        padding: 24px;
        background: #f8f8f8;
        border-radius: 8px;
        margin-bottom: 20px;

        .step-number {
          width: 32px;
          height: 32px;
          background: #0ca37f;
          color: #fff;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          font-weight: 600;
        }

        .step-button {
          margin-top: 12px;
          margin-right: 12px;
        }


        .completed-tag {
          margin-left: 8px;
          color: $success;
          font-size: 14px;
          font-weight: normal;
        }

        .step-content {
          flex: 1;

          h4 {
            margin: 0 0 12px 0;
            font-size: 18px;
          }

          p {
            margin: 0;
            color: #666;
          }
        }
      }
    }

    .welcome-message {
      text-align: center;
      max-width: 600px;
      margin: 0 auto 20px;
      font-size: $h4;
    }

    .step-card.disabled {
      opacity: 0.6;
      cursor: not-allowed;

      .step-number {
        background-color: #d9d9d9;
      }

      h4,
      p {
        color: #8c8c8c;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  @include styling($horizontal_padding: $mobile_horizontal_padding,
    $vertical_padding: $mobile_vertical_padding,
    $h1: $mobile_h1,
    $h1_line_height: $mobile_h1_line_height,
    $h2: $mobile_h2,
    $h2_line_height: $mobile_h2_line_height,
    $h3: $mobile_h3,
    $h3_line_height: $mobile_h3_line_height,
    $h4: $mobile_h4,
    $h4_line_height: $mobile_h4_line_height,
    $h5: $mobile_h5,
    $h5_line_height: $mobile_h5_line_height,
  );
}

@media screen and (min-width: 769px) {
  @include styling($horizontal_padding: $desktop_horizontal_padding,
    $vertical_padding: $desktop_vertical_padding,
    $h1: $desktop_h1,
    $h1_line_height: $desktop_h1_line_height,
    $h2: $desktop_h2,
    $h2_line_height: $desktop_h2_line_height,
    $h3: $desktop_h3,
    $h3_line_height: $desktop_h3_line_height,
    $h4: $desktop_h4,
    $h4_line_height: $desktop_h4_line_height,
    $h5: $desktop_h5,
    $h5_line_height: $desktop_h5_line_height,
  );
}