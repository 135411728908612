@import '../../colors.scss';
@import '../../global.scss';

@mixin styling($horizontal_padding,
  $vertical_padding,
  $h1,
  $h1_line_height,
  $h2,
  $h2_line_height,
  $h3,
  $h3_line_height,
  $h4,
  $h4_line_height,
  $h5,
  $h5_line_height,
  $h6,
  $h6_line_height,
) {
  .recommendation-drawer {
    position: relative;

    .ant-drawer-body {
      position: relative;
      background-color: $secondary_bg;
      overflow-y: auto;
      padding: 0;

    }

    .recommendation-drawer-content {
      padding: 24px;
      overflow-y: auto;
      width: 100%;
      overflow-x: hidden;

    }

    .recommendation-title {
      font-size: $h3;
      line-height: $h3_line_height;
      font-weight: 500;
      margin-bottom: 16px;
    }

    .recommendation-description {
      font-size: $h4;
      line-height: $h4_line_height;
      color: $secondary_text;

      h3 {
        font-size: $h5;
        line-height: $h5_line_height;
        font-weight: 600;
        margin-bottom: 16px;
      }

      strong {
        font-weight: 600;
      }


      .test-link {
        padding: unset;
        background-color: unset;
      }

    }

    .step-product-type-code {
      margin-top: 16px;
      padding: 16px;
      background-color: $primary_bg;
      // border-radius: $border_radius;
      border: 1px solid darken($primary_bg, 7%);
      box-shadow: 0 3px 10px rgba(0, 0, 0, 0.06);
      transition: all 0.3s ease;
      position: relative;

      // New: Use flexbox to create a two-column layout
      display: flex;
      flex-wrap: wrap;

      // Instalab recommended badge
      &.recommend-tag:before {
        content: 'Instalab Recommended';
        position: absolute;
        top: -10px;
        left: 16px;
        background-color: $success;
        color: white;
        font-size: 11px;
        font-weight: 600;
        padding: 3px 10px;
        // border-radius: 12px;
        letter-spacing: 0.3px;
        text-transform: uppercase;
        box-shadow: 0 2px 4px rgba(24, 144, 255, 0.3);
        z-index: 1;
      }

      &.recommend-tag {
        margin-top: 32px;
        padding-top: 24px;
      }



      // Content wrapper - left side
      .product-content {
        flex: 1;
        min-width: 0; // Prevents flex items from overflowing
        padding-right: 16px; // Space between content and images
      }

      // Product title
      &-title {
        font-size: $h4;
        line-height: $h4_line_height;
        font-weight: 600;
        color: $success;
        margin-bottom: 8px;
        margin-top: 6px;
        display: flex;
        align-items: center;


      }

      // Product description
      &-description {
        font-size: $h6;
        line-height: $h6_line_height;
        margin-bottom: 12px;
        color: $secondary_text;
      }

      // Call to action
      &-cta {
        font-size: $h6;
        font-weight: 600;
        color: white;
        margin-top: 8px;
        padding: 8px 16px;
        background-color: $success;
        border-radius: 6px;
        display: inline-block;
        cursor: pointer;
        transition: all 0.2s ease;
        box-shadow: 0 2px 4px rgba(24, 144, 255, 0.2);
      }

      // Product photos section - right side
      .step-product-type-photos {
        // Width for photos section
        width: 90px;
        display: flex;
        flex-direction: column;
        gap: 8px;
        align-self: center; // Center vertically

        // For multiple images
        &:not(.single-product-image) {
          .product-image {
            margin-bottom: 8px;

            &:last-child {
              margin-bottom: 0;
            }
          }
        }

        // Product image styling
        .product-image {
          width: 75px;
          object-fit: cover;
          // border-radius: 8px;
          // border: 1px solid #e6e6e6;
          // transition: all 0.2s ease;
          // box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);

          &:hover {
            transform: scale(1.05);
            box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
            border-color: #bfdbfe;
          }
        }
      }



      // Responsive adjustments
      @media (max-width: 640px) {
        // On small screens, make photos appear below content
        flex-direction: column;

        .product-content {
          padding-right: 0;
          margin-bottom: 16px;
        }

        .step-product-type-photos {
          width: 100%;
          flex-direction: row;
          overflow-x: auto;
          padding-bottom: 8px;

          // Hide scrollbar but keep functionality
          &::-webkit-scrollbar {
            height: 4px;
          }

          &::-webkit-scrollbar-track {
            background: #f0f0f0;
            border-radius: 4px;
          }

          &::-webkit-scrollbar-thumb {
            background-color: #d0d0d0;
            border-radius: 4px;
          }

          .product-image {
            flex-shrink: 0;
          }
        }
      }
    }

    // Flow Type Card - Similar to productType but optimized for tracking trends
    .step-flow-type {
      margin-top: 16px;
      margin-bottom: 16px;
      padding: 16px;
      background-color: #f0f7ff; // Slightly different background than productType
      border: 1px solid #d4e6ff;
      box-shadow: 0 3px 10px rgba(0, 0, 0, 0.05);
      transition: all 0.3s ease;
      position: relative;
      display: flex;
      flex-wrap: wrap;

      // Content wrapper - left side
      .flow-content {
        flex: 1;
        min-width: 0; // Prevents flex items from overflowing
        padding-right: 16px; // Space between content and right side
      }

      // Title
      &-title {
        font-size: $h4;
        font-weight: 600;
        color: #2f54eb;
        margin-bottom: 8px;
        margin-top: 6px;
        display: flex;
        align-items: center;

      }


      // Description
      &-description {
        font-size: $h6;
        color: $secondary_text;
        line-height: $h6_line_height;
        margin-bottom: 12px;


      }

      // Call to action
      &-cta {
        font-size: 14px;
        font-weight: 600;
        color: white;
        margin-top: 8px;
        padding: 8px 16px;
        background-color: #2f54eb;
        border-radius: 6px;
        display: inline-block;
        cursor: pointer;
        transition: all 0.2s ease;
        box-shadow: 0 2px 4px rgba(47, 84, 235, 0.2);

        &:hover {
          background-color: #1d39c4;
          box-shadow: 0 3px 6px rgba(47, 84, 235, 0.3);
        }
      }



      // Responsive styles
      @media (max-width: 640px) {
        flex-direction: column;

        .flow-content {
          padding-right: 0;
          margin-bottom: 16px;
        }

      }
    }


    // Checklist item styling
    .step {
      position: relative;
      padding: 12px 20px;
      margin-bottom: 12px;
      border-bottom: 1px solid #f0f0f0;
      border-radius: 0px;

      // Checkbox circle
      &:before {
        content: '';
        position: absolute;
        left: 14px;
        top: 40px;
        width: 20px;
        height: 20px;
        border: 2px solid #d9d9d9;
        border-radius: 50%;
        background-color: white;
      }

      // Last step has no border
      &:last-child {
        margin-bottom: 0;
        border-bottom: none;
      }


    }

    // Step title styling (checklist item text)
    .step-title {
      font-size: $h4;
      font-weight: 600;
      color: $primary_text;
      margin-bottom: 6px;
      line-height: $h4_line_height;
    }

    // Step description styling (subtext under checklist item)
    .step-description {
      font-size: $h5;
      color: $secondary_text;
      line-height: $h5_line_height;
      margin-bottom: 2px;
      padding-right: 8px;

      p:last-child {
        margin-bottom: 0;
      }


      ul,
      ol {
        padding-left: 14px;
      }

      strong {
        font-weight: 600;
      }

      table {
        margin: 10px 0;
        border: 1px solid #e8e8e8;
        border-radius: 4px;
        overflow: hidden;
        width: 100%;
      }
    }

    // Divider styling for "Game Plan" header
    .ant-divider-with-text-left {
      margin: 24px 0 8px;
      font-weight: 500;

      &::before,
      &::after {
        border-top-color: #e8e8e8;
      }

      .ant-divider-inner-text {
        font-size: 18px;
        font-weight: 500;
        color: #333;
      }
    }

    @media (max-width: 768px) {}

    .drawer-back-button {
      display: flex;
      height: 32px;
      color: #333;

      .anticon {
        margin-right: 6px;
        background: none;

        &:hover {
          color: #333;
          background: none;
        }
      }
    }

    // Override Ant Design's default drawer header padding
    .ant-drawer-header {
      padding: 16px;
      display: flex;
      align-items: center;
    }

  }
}

@media screen and (max-width: 768px) {
  @include styling($horizontal_padding: $mobile_horizontal_padding,
    $vertical_padding: $mobile_vertical_padding,
    $h1: $mobile_h1,
    $h1_line_height: $mobile_h1_line_height,
    $h2: $mobile_h2,
    $h2_line_height: $mobile_h2_line_height,
    $h3: $mobile_h3,
    $h3_line_height: $mobile_h3_line_height,
    $h4: $mobile_h4,
    $h4_line_height: $mobile_h4_line_height,
    $h5: $mobile_h5,
    $h5_line_height: $mobile_h5_line_height,
    $h6: $mobile_h6,
    $h6_line_height: $mobile_h6_line_height,
  );

}

@media screen and (min-width: 769px) {
  @include styling($horizontal_padding: $desktop_horizontal_padding,
    $vertical_padding: $desktop_vertical_padding,
    $h1: $desktop_h1,
    $h1_line_height: $desktop_h1_line_height,
    $h2: $desktop_h2,
    $h2_line_height: $desktop_h2_line_height,
    $h3: $desktop_h3,
    $h3_line_height: $desktop_h3_line_height,
    $h4: $desktop_h4,
    $h4_line_height: $desktop_h4_line_height,
    $h5: $desktop_h5,
    $h5_line_height: $desktop_h5_line_height,
    $h6: $desktop_h6,
    $h6_line_height: $desktop_h6_line_height,
  );
}

// Checklist-style Game Plan