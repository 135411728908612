@import '../../colors.scss';

.blog-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 24px;
  width: 100%;

  @media (max-width: 1200px) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media (max-width: 900px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 600px) {
    grid-template-columns: 1fr;
  }
}

.blog-grid-item {
  width: 100%;
  
  .blog-item {
    width: 100%;
    margin: 0;
    padding: 0;
  }
}

.blog-item {
  cursor: pointer;
  margin-bottom: 30px;

  .image-wrapper {
    position: relative;
    width: 100%;
    padding-top: 75%;
    height: auto;
    margin-bottom: 15px;
  }

  .blog-item-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    display: block;
    border-radius: 5px;
  }

  .blog-item-title {
    font-size: 18px;
    font-weight: 500;
    color: $primary_text;
    margin-bottom: 15px;
    line-height: 1.4;
  }

  .blog-item-description {
    font-size: 14px;
    color: $secondary_text;
    margin-top: 15px;
    margin-bottom: 15px;
  }

  .blog-item-category {
    color: $secondary_text;
    font-size: 14px;
    margin-bottom: 5px;
  }

  .blog-item-published {
    font-size: 14px;
    color: $primary_text;
    margin-top: 5px;
  }
}

.research-header,
.highlight-blog {
  @media (max-width: 600px) {
    display: none;
  }
}