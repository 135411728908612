@import '../../colors.scss';

$width: 1020px;
$bg: #F9FBFC;

.cac {
  // Shared

  .cac-section {
    padding: 0 10px;
    width: 100%;
    max-width: $width;
    margin: 0 auto;
    position: relative;
  }

  .cac-h1 {
    font-weight: 600;
    font-size: 55px;
    line-height: 1.2;
    color: $primary_text;
    margin-bottom: 20px;
    width: 100%;
    max-width: $width;
  }   


  .cac-p1 {
    font-weight: 400;
    font-size: 26px;
    line-height: 1.5;
    color: $primary_text;

    &.bold {
      font-weight: 600;
    }
  }     

  .cac-p2 {
    font-size: 21px;
    color: $primary_text;

    &.bold {
      font-weight: 600;
    }
  }

  .divider-container {
    display: flex;
    align-items: center;
    text-align: center;
    margin: 20px 0;
    width: 100%;
    
    .cac-h1 {
      position: relative;
      padding: 0 20px;
      margin: 0;
      font-weight: 500;
      z-index: 1;
      
      &::before,
      &::after {
        content: "";
        position: absolute;
        top: 50%;
        width: 120px;
        height: 1px;
        background-color: #d9d9d9; /* Light grey color */
        z-index: -1;
      }
      
      &::before {
        right: calc(100% - 320px);
      }
      
      &::after {
        left: calc(100% - 320px);
      }

      /* Hide decorative lines on mobile */
      @media (max-width: 767px) {
        &::before,
        &::after {
          display: none; /* Hide both lines on mobile */
        }
      }
    }
  }

  // Hero

  .cac-hero {
    text-align: center;
    padding-top: 80px;
    background: $bg;
    padding-bottom: 40px;
    margin-bottom: 140px;
  }

  .schedule-cac-btn {
    padding: 0px 40px;
    height: 54px;
    font-size: 18px;
    font-weight: 600;
    display: inline-block;
    padding: 0;
    margin-top: 20px;
  }

  .schedule-cac-btn-text {
    padding: 0 40px;
    border-right: 1px solid rgba(255,255,255,0.3);
  }

  .schedule-cac-btn-icon {
    padding: 0 15px;
  }

  .cac-quote {
    font-weight: 400;
    font-size: 15px;
    color: $primary_text;
    width: 100%;
    max-width: 500px;
    margin-top: 40px;
    margin-left: auto;
    margin-right: auto;
    text-align: left;
    border-left: 3px solid $success;
    padding: 10px 20px;
    background: $success_bg;
  }

  .cac-hero-img-container {
    margin-top: 10px;
    margin-bottom: -160px;
    background: black;
    display: inline-block;
    border-radius: 5px;
    overflow: hidden;
    position: relative;
    cursor: pointer;

    &:hover {
      .cac-play-icon {
        opacity: 1;
        transform: translate(-50%, -50%) scale(1.1);
      }
    }

    &:active {
      .cac-play-icon {
        transform: translate(-50%, -50%) scale(1.05);
      }
    }
  }

  .cac-hero-img {
    width: 100%;
    max-width: 500px;
    margin-left: auto;
    margin-right: auto;
    border-radius: 5px;
    opacity: 0.7;
    margin-bottom: 0;
    float: left;
  }

  .cac-play-icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 50px;
    color: white;
    z-index: 10;
    cursor: pointer;
    transition: transform 0.3s ease, opacity 0.3s ease;
    filter: drop-shadow(0 2px 5px rgba(0, 0, 0, 0.5));
    opacity: 1;
  }

  .cac-video-attribute {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    text-align: center;
    text-align: left;
    padding: 1px 12px;
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0.9) 0%, transparent 100%);
  }

  .cac-video-attribute-name {
    font-weight: 600;
    font-size: 15px;
    line-height: 1.5;
    color: white;
    margin-top: 10px;
    margin-bottom: 0;
  }

  .cac-video-attribute-title {  
    font-weight: 400;
    font-size: 15px;
    line-height: 1.5;
    color: white;
    margin-top: 0px;
    margin-bottom: 0;
  }

  // How

  .how-section {
    padding-top: 60px;
    padding-bottom: 50px;

    @media (max-width: 767px) {
      padding-top: 30px;
      padding-bottom: 0px;
    }
  }

  .how-section .divider-container {
    margin-bottom: 50px;
  }

  .read-more-btn {
    color: $success !important;
    padding: 0;
    font-weight: 600;
    font-size: 18px;
    float: left;
    margin-bottom: 20px;
  }

  .cac-how-img {
    width: 100%;
    border-radius: 8px;
    margin-top: 10px;
    margin-bottom: 60px;
  }

  .cac-how-video-container {
    margin-top: 45px;
  }

  .cac-how-video {
    width: 100%;
    border-radius: 8px;
    display: block;
    margin-bottom: 60px;
  }

  // Pricing

  .pricing-section {
    background: $bg;
    padding-top: 60px;
    padding-bottom: 90px;

    @media (max-width: 767px) {
      padding-top: 30px;
      padding-bottom: 50px;
    }
  }

  .pricing-p1 {
    text-align: center;
  }

  // FAQ

  .faq-section {
    padding: 60px 0;

    @media (max-width: 767px) {
      padding-top: 30px;
      padding-bottom: 50px;
    }
  }

  .faq-answer {
    padding-left: 25px;
  }
  
  .faq-video {
    width: 100%;
    max-width: 400px;
    border-radius: 8px;
    margin-top: 20px;
  }

  .faq-link {
    color: $success;
  }

  .faq-video-container, .cac-how-video-container {
    position: relative;
    width: 100%;

    iframe {
      border-radius: 8px;
    }
    
    .react-player {
      position: absolute;
      border-radius: 8px;
      top: 0;
      left: 0;
      width: 100% !important;
      height: 100% !important;
    }
  }
}
