@import '../../colors.scss';
@import '../../global.scss';

@mixin styling($horizontal_padding,
    $vertical_padding,
    $h1,
    $h1_line_height,
    $h2,
    $h2_line_height,
    $h3,
    $h3_line_height,
    $h4,
    $h4_line_height,
    $h5,
    $h5_line_height,
    $h6,
    $h6_line_height) {


    .section {
        padding: 64px 0;

        &:nth-child(even) {
            background-color: #ffffff;
        }

        &:nth-child(odd) {
            background-color: #f8f9fa;

        }

        &.green-bg {
            background-color: $success_bg;
        }


        .section-header {
            text-align: center;
            margin-bottom: 48px;

            h2 {
                font-size: $h2;
                font-weight: 600;
            }


            .section-subheader {
                font-size: $h4;
                color: #666;
                margin-top: 16px;
            }
        }

        .section-cta {
            text-align: center;
            margin-top: 48px;

            .main-cta-btn {
                min-width: 200px;
            }

            .secondary-cta-btn {

                font-size: 16px;
                height: 48px;
                padding: 0 32px;

                .anticon {
                    margin-left: 8px;
                }
            }
        }


    }

    .home-v2 {


        .max-width {
            max-width: $max_width;
            margin: 0 auto;
        }

        .dark-bg {
            background: #f9f9f9;
        }


        .main-cta-btn-wrapper {

            margin: 2.75rem 0 1rem;

            .main-cta-btn-footer {
                width: 325px;
                margin-top: 10px;
                font-size: calc($h6);
                line-height: calc($h6_line_height * 0.9);
            }


            .main-cta-btn {
                font-weight: 600;
                font-size: $h4;
                height: 50px;
                transition: background 0.3s ease;
                min-width: 260px;
                border: 0;


                &:hover,
                &:focus {
                    transform: scale(1.02);
                }

            }

        }


        .introduction {
            background: $success_bg;
            width: 100vw;
            align-items: center; // Vertically center content
            justify-content: space-between; // Distribute space between text and image

            .introduction-row {
                align-items: center;
            }

            .introduction-main-col {
                padding: $vertical_padding 0 $vertical_padding;
                max-width: 725px; // Keep the text area a manageable size
            }

            .introduction-title {
                width: 100%;
                font-size: calc($h1);
                line-height: $h1_line_height;
                font-weight: 600;
                margin-bottom: $h4_line_height;
                margin-top: $h4_line_height;

            }

            .introduction-description {
                font-size: $h4;
                line-height: $h4_line_height;
                margin: $h4_line_height 0;
            }

            .introduction-pricing {
                background: rgba(39, 174, 96, 0.05);
                border: 1px solid rgba(39, 174, 96, 0.1);
                border-radius: 8px;
                padding: 18px 20px;
                margin: 24px 0;
                display: flex;
                justify-content: space-between;
                align-items: center;
                max-width: 375px;

                @media (max-width: 768px) {
                    flex-direction: column;
                    align-items: stretch;
                    max-width: unset;
                }

                .pricing-label {
                    font-weight: 600;
                    color: $success;
                    font-size: $h4;

                    @media (max-width: 768px) {
                        margin-bottom: 16px;
                        text-align: center;
                    }
                }

                // .pricing-options {
                //     display: flex;
                //     align-items: center;
                //     gap: 20px;

                //     @media (max-width: 768px) {
                //         align-items: center;
                //         justify-content: center;
                //         flex-wrap: wrap;
                //     }
                // }

                .pricing-option {
                    padding: 0;
                    position: relative;
                    text-align: center;

                    @media (max-width: 768px) {
                        text-align: center;
                    }
                }

                .price-container {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    gap: 8px;
                }

                .current-price {
                    display: flex;
                    align-items: baseline;
                }

                .price {
                    font-size: calc($h3*1.1);
                    font-weight: 700;
                    color: $success;
                    line-height: 1;
                }


                .previous-price {
                    display: flex;
                    align-items: center;
                    gap: 8px;
                }

                .strike-through {
                    font-size: calc($h6);
                    color: #999;
                    text-decoration: line-through;
                }

                .save-badge {
                    background: $success_bg;
                    color: $success;
                    padding: 2px 8px;
                    border-radius: 12px;
                    font-size: calc($h6);
                    font-weight: 500;
                }


                .price-tag {
                    display: inline-flex;
                    flex-direction: column;
                    align-items: flex-start;
                    gap: 8px;
                }

                .current-price {
                    display: flex;
                    align-items: baseline;
                }

                .dollar {
                    font-size: calc($h3*1.1);
                    font-weight: 600;
                    color: $success;
                }

                .amount {
                    font-size: calc($h3*1.25);
                    font-weight: 700;
                    color: $success;
                    line-height: 1;
                }

                .period {
                    font-size: 16px;
                    color: #666;
                    margin-left: 4px;
                }


                .original {
                    font-size: calc($h6);
                    color: #666;
                    text-decoration: line-through;
                }


                @media (max-width: 768px) {
                    .price {
                        font-size: 36px;
                    }

                    .period {
                        font-size: 16px;
                    }

                    .strike-through {
                        font-size: 14px;
                    }
                }
            }

            .phone-container {
                position: relative;
                width: 270px;
                height: 600px;
                margin: 0 auto;

                .phone-frame {
                    padding: 85px 16px 0px 17px;
                    position: relative;
                    width: 100%;
                    height: 100%;
                    background-image: url('../../assets/png/phone-small-frame.png');
                    background-size: contain;
                    background-repeat: no-repeat;
                    background-position: center;

                    .gif-slide {
                        width: 100%;
                        height: 100%;

                        img.phone-video {
                            width: 100%;
                            height: 100%;
                            object-fit: cover;
                            object-position: center;
                        }
                    }
                }
            }

            // Override Ant Design carousel dots style
            .introduction-side-col {
                .ant-carousel .slick-dots {

                    li button {
                        background: #D9D9D9;
                    }

                    li.slick-active button {
                        background: #1890ff;
                    }
                }
            }



        }

        .benefits-section {


            .benefits-container {
                max-width: $max_width;
                margin: 0 auto;
            }

            .benefit-list {
                background: white;
                border-radius: $border_radius;
                overflow: hidden;
                max-width: 900px;
                margin: auto;
                // box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05);
                // border: 1px solid #eee;

                .bullet-item {
                    display: flex;
                    // align-items: flex-start;
                    gap: 12px;
                }

                .bullet-icon {
                    flex-shrink: 0;
                    margin-top: 4px;
                }

                .bullet-text {
                    flex: 1;
                }

                .icon {
                    font-size: 16px;
                    vertical-align: middle;
                    margin-right: 8px;
                }

                .after-icon {
                    color: $success !important;
                }

                .before-icon {
                    color: $error;
                }

                .transformation-header {
                    display: flex;
                    margin-bottom: 24px;
                    font-size: $h4;
                    font-weight: 600;
                    gap: 16px;

                    .header-before,
                    .header-after {
                        flex: 1;
                        padding: 16px;
                        border-radius: 8px;
                        background: #f8f8f8;
                        text-align: center;

                        .before-icon,
                        .after-icon {
                            margin-right: 8px;
                        }
                    }

                }

                .transformation-item {
                    margin-bottom: 24px;
                    padding: 16px;
                    border-radius: 8px;
                    // box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
                    border: 1px solid #eee;
                    background: white;
                    font-size: $h5;

                    &:last-child {
                        margin-bottom: 0;
                    }

                    .transformation-row {
                        display: flex;
                        align-items: center;
                        gap: 24px;

                        .col-before,
                        .col-after {
                            flex: 1;
                            padding: 16px;

                            .before-icon,
                            .after-icon {
                                margin-right: 8px;
                            }
                        }

                        .arrow-col {
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            color: #bfbfbf;

                            .arrow-icon {
                                font-size: 20px;
                            }
                        }

                        .after-icon {
                            color: #52c41a;
                        }
                    }
                }

                @media (max-width: 768px) {
                    border-radius: 8px;
                    margin: 0 16px;

                    .transformation-header {
                        display: none;
                    }

                    .transformation-item {
                        .transformation-row {
                            flex-direction: column;
                            gap: 16px;


                            .col-after {
                                width: 100%;
                                text-align: left;
                                padding: 0;
                            }

                            .arrow-col,
                            .col-before {
                                display: none;
                            }
                        }
                    }
                }
            }



            .benefits-cta {
                text-align: center;
                margin-top: 48px;

                .benefits-cta-button {
                    height: 48px;
                    padding: 0 32px;
                    font-size: 16px;
                    font-weight: 600;
                    border-radius: $border_radius;
                    box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
                }
            }




        }

        .examples-section {
            .tasks-grid {
                display: grid;
                grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
                gap: 24px;
                margin-top: 40px;

                .task-card {
                    background: white;
                    border-radius: $border_radius;
                    padding: 24px;
                    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.08);
                    transition: transform 0.2s ease;

                    &:hover {
                        transform: translateY(-2px);
                    }

                    .task-header {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        margin-bottom: 16px;

                        .task-icon {
                            .anticon {
                                font-size: 24px;
                            }
                        }

                        .task-status {
                            text-align: right;

                            .completed-badge {
                                background: #f8f9fa;
                                color: #666;
                                padding: 4px 12px;
                                border-radius: 20px;
                                font-size: 14px;
                                font-weight: 500;
                                margin-bottom: 4px;
                                display: inline-block;
                            }

                            .task-time {
                                display: block;
                                color: #666;
                                font-size: 14px;
                            }
                        }
                    }

                    .task-content {
                        color: #333;
                        font-size: 16px;
                        line-height: 1.5;
                    }
                }
            }
        }


        .testimonials-section {
            padding: 80px 0;

            .testimonials-grid {
                display: grid;
                grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
                gap: 30px;
                margin: 40px 0;
            }

            .testimonial-card {
                background: white;
                padding: 24px;
                border-radius: $border_radius;
                box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);

                .testimonial-content {
                    margin-bottom: 20px;

                    p {
                        font-size: 16px;
                        line-height: 1.6;
                        color: #4a5568;
                        // font-style: italic;
                    }
                }

                .testimonial-author {
                    display: flex;
                    align-items: center;
                    gap: 16px;

                    .author-image {
                        width: 60px;
                        height: 60px;

                        img {
                            width: 100%;
                            height: 100%;
                            border-radius: 50%;
                            object-fit: cover;
                        }
                    }

                    .author-info {
                        h4 {
                            margin: 0;
                            font-size: 16px;
                            font-weight: 600;
                            color: #2d3748;
                        }

                        p {
                            margin: 4px 0 0;
                            font-size: 14px;
                            color: #718096;
                        }
                    }
                }
            }

            .testimonials-cta {
                text-align: center;
                margin-top: 40px;
            }
        }

        .social-proof-section {
            padding: 80px 0;

            .metrics-grid {
                display: grid;
                grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
                gap: 32px;
                margin: 48px 0;

                // When there's only one child, make it span full width
                >*:only-child {
                    grid-column: 1 / -1;
                    max-width: 600px; // Optional: limit maximum width
                    margin: 0 auto; // Optional: center the single item
                }

                @media (max-width: 768px) {

                    gap: 24px;
                }
            }



            .metric-card {
                text-align: center;
                padding: 24px;
                background: white;
                border-radius: $border_radius;
                box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);

                .metric-number {
                    font-size: calc(0.9*$h2);
                    font-weight: 600;
                    color: $success;
                    margin-bottom: 8px;
                }

                .metric-label {
                    font-size: 16px;
                    line-height: 1.4;
                    color: #4a5568;
                }
            }

            .trusted-by {
                text-align: center;
                margin: 4rem 0;

                .trusted-by-label {
                    font-size: 18px;
                    color: #4a5568;
                    margin-bottom: 24px;
                }

                .logo-grid {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    flex-wrap: wrap;
                    gap: 48px;

                    img {
                        height: 32px;
                        opacity: 0.7;
                        transition: opacity 0.2s;

                        &:hover {
                            opacity: 1;
                        }
                    }
                }
            }
        }

        .logo-scroll {
            overflow: hidden;
            white-space: nowrap;
            position: relative;
            width: 100%;

            .logo-scroll-content {
                display: inline-block;
                animation: scroll 30s linear infinite;

                img {
                    height: 40px;
                    margin: 0 30px;
                    vertical-align: middle;
                    filter: grayscale(100%);
                    opacity: 0.7;
                    transition: all 0.3s ease;

                    &:hover {
                        filter: grayscale(0%);
                        opacity: 1;
                    }
                }
            }
        }

        @keyframes scroll {
            0% {
                transform: translateX(0);
            }

            100% {
                transform: translateX(-100%);
            }
        }

        .not-ready-section {
            .not-ready-content {
                text-align: center;
                max-width: 800px;
                margin: 0 auto;

                .description {
                    font-size: 18px;
                    line-height: 1.6;
                    margin-bottom: 32px;
                }

                .browse-btn {
                    font-size: 16px;
                    height: 48px;
                    padding: 0 32px;

                    .anticon {
                        margin-left: 8px;
                    }
                }
            }
        }

        .faq-section {

            .faq-grid {
                margin: 48px auto;

                .ant-collapse {
                    background: transparent;

                    .ant-collapse-item {
                        border-radius: 0;
                        margin-bottom: 0;
                        border: none;
                        border-bottom: 1px solid #eaeaea;
                        box-shadow: none;

                        .ant-collapse-header {
                            padding: 24px 0px;
                            font-size: 18px;
                            font-weight: 500;
                            color: #2c2c2c;
                            transition: all 0.3s ease;

                            &:hover {
                                color: #000;
                            }
                        }

                        .ant-collapse-content {
                            border-top: none;

                            .ant-collapse-content-box {
                                padding: 0 0px 24px 0;
                            }
                        }

                        // Style for the expand icon
                        .ant-collapse-expand-icon {
                            color: #2c2c2c;
                        }
                    }
                }

                // Add custom styling for the concierge service cards
                .ant-card {
                    height: 100%;
                    transition: all 0.3s ease;
                    border: 1px solid #eaeaea;
                    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05);
                    cursor: default;

                    &:hover {
                        transform: translateY(-4px);
                        box-shadow: 0 4px 12px rgba(0, 0, 0, 0.08);
                    }

                    .ant-card-head {
                        border-bottom: 1px solid #f0f0f0;
                        padding: 16px 24px;
                        min-height: auto;

                        .ant-card-head-title {
                            font-size: 18px;
                            font-weight: 600;
                            color: #2d3748;
                            padding: 8px 0;
                            line-height: 1.4;
                            height: 100%;
                        }
                    }

                    .ant-card-body {
                        padding: 20px;

                        ul {
                            margin: 0;
                            padding-left: 20px;

                            li {
                                color: #4a5568;
                                font-size: 15px;
                                line-height: 1.6;
                                margin-bottom: 12px;
                                position: relative;

                                &:last-child {
                                    margin-bottom: 0;
                                }

                                &::marker {
                                    color: $success;
                                }
                            }
                        }
                    }
                }

                // Responsive adjustments for mobile
                @media screen and (max-width: 768px) {
                    .ant-card {
                        .ant-card-head {
                            padding: 12px 16px;

                            .ant-card-head-title {
                                font-size: 16px;
                            }
                        }

                        .ant-card-body {
                            padding: 16px;

                            ul li {
                                font-size: 14px;
                                margin-bottom: 10px;
                            }
                        }
                    }
                }
            }

            .faq-answer {
                font-size: 16px;
                line-height: 1.6;
                color: #4a4a4a;
                margin: 0;
            }

            .chat-link {
                color: $mono_text;
                cursor: pointer;
                text-decoration: underline;

                &:hover {
                    opacity: 0.8;
                }
            }


        }

        .tools-section {
            .tools-grid {
                display: grid;
                grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
                gap: 32px;
                margin-top: 40px;

                .tool-card {
                    background: white;
                    border-radius: $border_radius;
                    overflow: hidden;
                    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.08);
                    transition: transform 0.2s ease;
                    cursor: pointer;

                    &:hover {
                        transform: translateY(-4px);
                    }

                    .tool-image {
                        width: 100%;
                        height: 200px;
                        overflow: hidden;

                        img {
                            width: 100%;
                            height: 100%;
                            object-fit: cover;
                        }
                    }

                    .tool-content {
                        padding: 24px;

                        h3 {
                            margin: 0 0 8px;
                            font-size: 20px;
                            font-weight: 600;
                            color: #2d3748;
                        }

                        p {
                            color: #718096;
                            font-size: 14px;
                            margin: 0 0 16px;
                        }

                        .tool-description {
                            color: #4a5568;
                            font-size: 16px;
                            line-height: 1.5;
                            display: -webkit-box;
                            -webkit-line-clamp: 3;
                            -webkit-box-orient: vertical;
                            overflow: hidden;
                        }
                    }
                }
            }
        }

        .final-cta-section {
            background: $success_bg !important;
            text-align: center;

        }

        .button-container {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
        }

        .comparison-section {

            .before-column,
            .after-column {
                background-color: #f0f0f0; // Light gray for "Before"
                padding: 20px;
                border-radius: 8px;
            }

            .after-column {
                background-color: #e0f7fa; // Pale blue for "After"
            }

            .column-header {
                display: flex;
                align-items: center;
                margin-bottom: 20px;

                img {
                    width: 40px;
                    height: 40px;
                    margin-right: 10px;
                }

                h3 {
                    margin: 0;
                }
            }

            .comparison-row {
                display: flex;
                align-items: center;
                padding: 10px;
                background-color: #fff;
                border-radius: 4px;
                margin-bottom: 10px;
                transition: background-color 0.3s;

                &:hover {
                    background-color: #e0e0e0;
                }

                span {
                    margin-left: 10px;
                    font-weight: bold;
                }
            }

            .divider {
                display: flex;
                justify-content: center;
                align-items: center;

                .vertical-line {
                    width: 2px;
                    height: 100%;
                    background-color: #ccc;
                }
            }
        }

        .get-started-section {
            position: fixed;
            bottom: 20px;
            right: 20px;
            display: flex;
            justify-content: center;
            z-index: 1000;


        }


    }


}

@media screen and (max-width: 768px) {
    @include styling($horizontal_padding: $mobile_horizontal_padding,
        $vertical_padding: $mobile_vertical_padding,
        $h1: $mobile_h1,
        $h1_line_height: $mobile_h1_line_height,
        $h2: $mobile_h2,
        $h2_line_height: $mobile_h2_line_height,
        $h3: $mobile_h3,
        $h3_line_height: $mobile_h3_line_height,
        $h4: $mobile_h4,
        $h4_line_height: $mobile_h4_line_height,
        $h5: $mobile_h5,
        $h5_line_height: $mobile_h5_line_height,
        $h6: $mobile_h6,
        $h6_line_height: $mobile_h6_line_height );


    .desktop-only {
        display: none;
    }

    .home-v2 {
        .phone-container {
            margin: 0 auto;
        }




        .main-cta-btn-wrapper {
            margin: 2rem 0 1rem;

            .main-cta-btn-footer {
                display: none;

            }
        }



        .introduction {
            text-align: center;

            .introduction-main-col {
                padding-bottom: 0;
            }



            .introduction-pricing {
                width: 100%;
                padding: 16px;
                text-align: center;

                .pricing-options {
                    gap: 18px;
                    width: 100%;
                }



                .pricing-label {
                    text-align: center;
                    margin-bottom: 16px;
                }
            }
        }

        .get-started-section {
            width: 100%;
            right: unset;
            padding: 0px;

            .section-cta {
                width: 100%;
                margin: auto 20px;
            }

            .main-cta-btn {
                width: 100%;

            }
        }


    }
}

@media screen and (min-width: 769px) {
    @include styling($horizontal_padding: $desktop_horizontal_padding,
        $vertical_padding: $desktop_vertical_padding,
        $h1: $desktop_h1,
        $h1_line_height: $desktop_h1_line_height,
        $h2: $desktop_h2,
        $h2_line_height: $desktop_h2_line_height,
        $h3: $desktop_h3,
        $h3_line_height: $desktop_h3_line_height,
        $h4: $desktop_h4,
        $h4_line_height: $desktop_h4_line_height,
        $h5: $desktop_h5,
        $h5_line_height: $desktop_h5_line_height,
        $h6: $desktop_h6,
        $h6_line_height: $desktop_h6_line_height );


}