@import '../../colors.scss';
@import '../../global.scss';

@mixin styling($horizontal_padding,
  $vertical_padding,
  $h1,
  $h1_line_height,
  $h2,
  $h2_line_height,
  $h3,
  $h3_line_height,
  $h4,
  $h4_line_height,
  $h5,
  $h5_line_height,
) {
  .test-modal {
    max-width: 900px;
    scroll-behavior: smooth;

    .test-tagline {
      color: $secondary_text;
      font-size: $h5;
      line-height: $h5_line_height;
    }

    .ant-modal-content,
    .ant-modal-header {
      background: white;
    }

    .ant-modal-title {
      color: $primary_text;
      margin-bottom: 0;
    }

    .ant-modal-content {

      @media screen and (max-width: $breakpoint_desktop) {
        margin-bottom: 50px;
      }
    }

    .apo-e-tag {
      background: $secondary_bg;
      display: inline-block;
      padding: 8px 20px;
      border-radius: 6px;
      font-size: 15px;
      font-weight: 600;
      margin: 20px 0;
      transition: all 0.2s ease;

      &:hover {
        transform: translateY(-1px);
        box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
      }
    }

    .optimal-apo-e {
      background: $success_bg;
      color: $success;
      box-shadow: 0 0 1px $success;
    }

    .moderate-apo-e {
      background: $warning_bg;
      color: $warning;
      box-shadow: 0 0 1px $warning;
    }

    .abnormal-apo-e,
    .high-apo-e {
      background: $error_bg;
      color: $error;
      box-shadow: 0 0 1px $error;
    }

    .test-details {
      color: $secondary_text;
      font-size: $h5;
      line-height: 1.6;
      margin-bottom: 20px;
    }

    .test-details strong,
    .test-details b {
      color: $primary_text;
      font-weight: 500;
    }

    .test-details table {
      border-spacing: 0;
      width: 100%;
      margin: 20px 0;
      border-collapse: collapse;

      td {
        border: 1px solid $border;
        padding: 12px 16px;
        max-width: 200px;
        line-height: 1.4;
      }
    }

    .ant-modal-close-icon {
      color: $secondary_text;
    }

    .test-chart-container {
      margin: 30px 0;
      padding: 10px;
      background: #fafafa;
      border-radius: 8px;
    }

    ol,
    ul {
      li {
        margin-bottom: 15px;
      }

      li:last-child {
        margin-bottom: 0;
      }
    }

    .test-references {
      border-top: 1px solid $border;
      margin-top: 35px;
      padding-top: 25px;
      background: #fafbfc;
      padding: 20px;
      border-radius: 0 0 8px 8px;


      .title {
        font-weight: 600;
        padding: 0 0 15px 0;
        font-size: 18px;
        color: $primary_text;
      }

      li {
        margin-bottom: 12px;
        line-height: 1.5;
        font-size: 15px;
      }

      a {
        color: $secondary_text;
        text-decoration: none;
        transition: color 0.2s ease;

        &:hover {
          color: darken($secondary_text, 20);
          text-decoration: underline;
        }
      }
    }
  }
}


@media screen and (max-width: 768px) {
  @include styling($horizontal_padding: $mobile_horizontal_padding,
    $vertical_padding: $mobile_vertical_padding,
    $h1: $mobile_h1,
    $h1_line_height: $mobile_h1_line_height,
    $h2: $mobile_h2,
    $h2_line_height: $mobile_h2_line_height,
    $h3: $mobile_h3,
    $h3_line_height: $mobile_h3_line_height,
    $h4: $mobile_h4,
    $h4_line_height: $mobile_h4_line_height,
    $h5: $mobile_h5,
    $h5_line_height: $mobile_h5_line_height,
  );
}

@media screen and (min-width: 769px) {
  @include styling($horizontal_padding: $desktop_horizontal_padding,
    $vertical_padding: $desktop_vertical_padding,
    $h1: $desktop_h1,
    $h1_line_height: $desktop_h1_line_height,
    $h2: $desktop_h2,
    $h2_line_height: $desktop_h2_line_height,
    $h3: $desktop_h3,
    $h3_line_height: $desktop_h3_line_height,
    $h4: $desktop_h4,
    $h4_line_height: $desktop_h4_line_height,
    $h5: $desktop_h5,
    $h5_line_height: $desktop_h5_line_height,
  );
}