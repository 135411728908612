@import '../../colors.scss';

.biomarker-layover {
  width: 100%;
  max-width: 800px !important;

  .ant-popover-inner {
    padding: 0;
  }

  @media screen and (max-width: 576px) {
    position: fixed !important;
    top: 0 !important;
    left: 0 !important;
    right: 0 !important;
    bottom: 0 !important;
    margin: 0 !important;

    .biomarker-popover {
      padding: 0;
    }
    
    .ant-popover-content {
      height: 100%;

      .ant-table-cell {
        padding-top: 5px !important;
        padding-bottom: 5px !important;
      }
      
      .ant-popover-inner {
        height: 100%;
        
        .ant-popover-inner-content {
          height: 100%;
          padding: 0;
        }
      }
    }
  }
}

.biomarker-popover {
  padding: 0;
  width: 100%;

  .close-popover-icon {
    font-size: 20px;
    color: $primary_text;

    &:active {
      opacity: 0.5;
    }
  }

  .biomarker-popover-header {
    padding: 15px 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .biomarker-title {
    font-family: 'EB Garamond';
    font-weight: 600;
    font-size: 30px;
    margin-top: 0;
    margin-bottom: 0;
    color: $primary_text;
  }

  .ant-table-cell {
    padding: 0px 10px !important;
  }

  .biomarker-name {
    font-weight: 600;
    font-size: 16px;
    padding-top: 14px;
    padding-left: 10px;
    color: $primary_text;
  }

  .biomarker-description {
    margin-bottom: 0;
    color: $primary_text;
    font-size: 16px;
  }

  @media screen and (max-width: 576px) {
    height: 100%;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    padding: 16px;
  }
}