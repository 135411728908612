@import '../../colors.scss';
@import '../../global.scss';

@mixin styling($horizontal_padding,
  $vertical_padding,
  $h1,
  $h1_line_height,
  $h2,
  $h2_line_height,
  $h3,
  $h3_line_height,
  $h4,
  $h4_line_height,
  $h5,
  $h5_line_height,
) {
  .universal-report-container {
    background: $secondary_bg;
    padding: 0;
    width: 100%;
  }

  .universal-report {
    // max-width: $width;
    width: 100%;
    margin: 0 auto;
    padding: 10px 20px 20px 20px;

    .report-section-container {
      background: white;
      margin: 10px auto 0 auto;
      border-radius: 10px;
      padding: 40px 15px;
      box-shadow: 0 0px 2px 0 $border;
    }

    .last-report-section-container {
      background: $secondary_bg;
      box-shadow: none;
    }

    .ant-anchor-ink-ball-visible {
      background-color: $success !important;
    }

    .report-section {
      max-width: 450px;
      margin: 0 auto;
      background: white;

      .test-chart {
        margin-top: 20px;
      }
    }


    .report-template-section {
      width: 100%;
      margin: 0px auto;
    }

    .white-container {
      padding: 80px 30px;
      background: white;
    }

    .grey-container {
      padding: 80px 30px;
      background: #F9F9F9;
    }

    .report-template-title {
      color: $primary_text;
      font-weight: 700;
      padding-bottom: 0px;
      margin-bottom: 30px;
      font-size: $h3;
      line-height: calc(0.9*$h3_line_height);

      span {
        color: #FE7612;
      }
    }

    .report-template-description {
      color: $secondary_text;
      font-size: $h4;
      line-height: $h4_line_height;
      margin-bottom: 0;
      margin-top: 30px;

      a {
        color: $success;
      }
    }
  }

  .coming-soon {
    text-align: center;
    padding: 25px;
    font-size: 2rem;
  }


  .mobile-toc-button {
    position: fixed;
    bottom: 80px;
    right: 24px;
    z-index: 9999;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
    width: 56px !important;
    height: 56px !important;
    display: flex !important;
    align-items: center;
    justify-content: center;
    background: #00B388;
    border: none;
    border-radius: 50% !important;
    padding: 0 !important;

    .anticon {
      font-size: 24px;
      color: white;
      background: none;
    }

    &:hover,
    &:focus {
      background: #009974;
    }


  }

  // Show only on mobile

}

@media screen and (max-width: 768px) {
  @include styling($horizontal_padding: $mobile_horizontal_padding,
    $vertical_padding: $mobile_vertical_padding,
    $h1: $mobile_h1,
    $h1_line_height: $mobile_h1_line_height,
    $h2: $mobile_h2,
    $h2_line_height: $mobile_h2_line_height,
    $h3: $mobile_h3,
    $h3_line_height: $mobile_h3_line_height,
    $h4: $mobile_h4,
    $h4_line_height: $mobile_h4_line_height,
    $h5: $mobile_h5,
    $h5_line_height: $mobile_h5_line_height,
  );
}

@media screen and (min-width: 769px) {
  @include styling($horizontal_padding: $desktop_horizontal_padding,
    $vertical_padding: $desktop_vertical_padding,
    $h1: $desktop_h1,
    $h1_line_height: $desktop_h1_line_height,
    $h2: $desktop_h2,
    $h2_line_height: $desktop_h2_line_height,
    $h3: $desktop_h3,
    $h3_line_height: $desktop_h3_line_height,
    $h4: $desktop_h4,
    $h4_line_height: $desktop_h4_line_height,
    $h5: $desktop_h5,
    $h5_line_height: $desktop_h5_line_height,
  );
}


@media (max-width: 768px) {
  .mobile-only {
    display: block;
  }

  .table-of-contents {
    border-right: none !important;
  }

  .table-of-contents .toc-title {
    display: none !important;
  }
}

// Hide on desktop
@media (min-width: 768px) {
  .mobile-only {
    display: none !important;
  }


}