.qr-code-page {
    min-height: 90vh;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
    background-color: #f5f5f5;


    .qr-code-content {
        background-color: white;
        padding: 24px;
        border-radius: 12px;
        box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
        text-align: center;
        max-width: 400px;
        width: 100%;
    }

    h1 {
        margin-bottom: 24px;
        color: #333;
        font-size: 24px;
    }

    .qr-code-wrapper {
        background-color: white;
        padding: 16px;
        border-radius: 8px;
        display: inline-block;
        margin-bottom: 20px;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
    }

    .qr-code {
        display: block;
    }

    .instructions {
        color: #555;
        margin-top: 20px;
    }

    .quest-id {
        margin-top: 12px;
        font-family: monospace;
        color: #666;
    }

    .error-message {
        color: #d32f2f;
        padding: 20px;
    }

    /* Mobile-specific adjustments */
    @media (max-width: 480px) {
        .qr-code-content {
            padding: 16px;
        }

        h1 {
            font-size: 20px;
            margin-bottom: 16px;
        }

        .qr-code-wrapper {
            padding: 12px;
        }
    }
}