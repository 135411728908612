@import '../../colors.scss';
@import '../../global.scss';

.appointments {

  width: 100%;

  .credit-count {
    background: rgba(255, 255, 255, 0.9);
    width: 15px;
    height: 15px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    float: right;
    color: $success;
    font-size: 12px;
    font-weight: 600;
    margin-left: 3px;
  }

  .appointments-container {
    padding: 16px;

    .ant-col {
      width: 100%;
    }
  }

  // Show mobile cards ONLY on small screens
  @media screen and (max-width: 768px) {
    .mobile-view {
      display: block;
      padding: 16px;
    }

    .desktop-view {
      display: none;
    }
  }

  // Show table ONLY on larger screens
  @media screen and (min-width: 769px) {
    .desktop-view {
      display: block;
    }

    .mobile-view {
      display: none;
    }
  }



  .time-column {
    white-space: nowrap;
  }

  .pending-message {
    margin-top: 8px;
    padding: 8px;
    background-color: #f8f9fa;
    border-radius: 4px;
    font-size: 0.85em;
    color: #6c757d;
    line-height: 1.4;
  }

  // .appointment-status {
  //   text-transform: capitalize;
  //   font-weight: 500;
  //   font-size: 12px;
  // }


  .ant-tag {
    border: none;

  }



  .location-column {
    .facility-name {
      font-weight: 600;
      margin-bottom: 2px;
    }

    .address-line {
      color: #666;
      line-height: 1.4;
    }
  }
}

.check-in-modal {
  .qr-container {
    text-align: center;
    padding: 20px 0;

    .qr-background {
      background-color: #f5f5f5;
      border-radius: 12px;
      padding: 24px;
      margin-bottom: 20px;

      img {
        width: 200px;
        height: 200px;
        display: block;
        margin: 0 auto;
        padding: 10px;
        background-color: white;
        border-radius: 8px;
        box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
      }
    }

    .confirmation-code {
      margin-bottom: 16px;
      padding: 12px;
      background-color: #fff;
      border: 1px solid #eee;
      border-radius: 8px;

      .label {
        font-size: 14px;
        color: #666;
        margin-bottom: 8px;
      }

      .code {
        font-size: 24px;
        font-weight: 600;
        letter-spacing: 1px;
        color: #000;
      }
    }

    .instructions {
      font-size: 14px;
      color: #666;
      line-height: 1.5;
      padding: 0 16px;
    }
  }
}