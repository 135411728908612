@import '../../colors.scss';

$background: #F8F5F3;

.heart-health-landing {
  width: 100%;
  padding: 50px 0 0 0;

  .heart-health-section {
    max-width: 1100px; 
    margin: 0 auto;
    padding: 0 15px;
  }

  .heart-health-title {
    font-family: 'EB Garamond';
    font-weight: 600;
    font-size: 45px;
    line-height: 1.3;
    margin-top: 0;
    margin-bottom: 20px;

    @media (max-width: 767px) {
      font-size: 35px;
      line-height: 1.3;
    }
  }

  .heart-health-title-2 {
    font-family: 'EB Garamond';
    font-weight: 600;
    font-size: 38px;
    line-height: 1.2;
    margin-top: 0;

    @media (max-width: 767px) {
      font-size: 32px;
    }
  }
  
  .heart-health-description {
    font-size: 24px;
    line-height: 1.5;
    color: $primary_text;
    margin-top: 20px;
    margin-bottom: 20px;

    a {
      color: $success;
    }

    @media (max-width: 767px) {
      font-size: 22px;
      line-height: 1.6;
    }
  }

  .heart-health-btn {
    height: 55px;
    width: 250px;
    font-size: 20px;
    font-weight: 600;
    border-radius: 7px;
  }

  .highlight-text {
    color: $success;
  }

  .intro-text-col {
    display: flex;
    align-items: center;
  }

  .intro-container {
    padding-bottom: 50px;

    @media (max-width: 767px) {
      padding-top: 10px;
    }

    .intro-price {
      background: rgba(12,163,127,0.2);
      color: $success;
      display: inline-block;
      font-size: 14px;
      border-radius: 5px;
      padding: 5px 8px;
      font-weight: 600;
      margin-bottom: 20px;
    }

    .price-info-icon {
      margin-left: 5px;
    }
  
    .intro-btn {
      margin-bottom: 0;
      margin-top: 30px;

      @media (max-width: 767px) {
        width: 100%;
        display: block;

        .heart-health-btn {
          width: 100%;
        }
      }
    }
  
    .start-btn {
      margin-right: 10px;

      @media (max-width: 767px) {
        margin-right: 0;
        margin-bottom: 10px;
      }
    }

    .learn-btn {
      @media (max-width: 767px) {
        display: none;
      }
    }

    .intro-img {
      width: 100%;
    }
  }

  .landing-divider {
    border-top: 1px solid $border;
  }

  .stats-container {
    background: $background;
    padding: 40px 0;

    .stat-item {
      text-align: center;
      padding: 10px 0;
    }

    .stat-value {
      font-size: 50px;
      font-weight: 600;
      margin-bottom: 0;
      color: $success;
    }

    .stat-label {
      font-size: 16px;
      color: $primary_text;
    }
  }

  .timeline-container {
    padding-top: 80px;
    padding-bottom: 100px;

    @media (max-width: 767px) {
      padding-top: 50px;
      padding-bottom: 20px;
    }

    .timeline-tabs {
      @media (max-width: 767px) {
        display: none;
      }
    }

    .ant-tabs-nav-wrap {
      justify-content: center;
    }

    .ant-tabs-nav {
      background: white;
    }

    .timeline-header {
      text-align: center;
      max-width: 600px;
      margin: 0 auto 60px auto;

      @media (max-width: 767px) {
        margin-bottom: 0px;
      }
    }

    .ant-tabs-tab {
      margin-left: 0 !important;
    }


    .ant-tabs-tab-btn {
      font-size: 24px;
      padding-left: 60px;
      padding-right: 60px;
      margin-left: 0 !important;
    }

    .timeline-label {
      color: $success;
      font-size: 16px;
      font-weight: 600;
    }

    .timeline-list {
      list-style: none;
      margin-top: 25px;
    }

    .timeline-list li {
      margin-left: 0;

      .ant-col {
        font-size: 18px;
        margin-bottom: 15px;

        &:first-child {
          width: 35px;
        }

        &:last-child {
          width: calc(100% - 35px);
        }
      }
    }

    .timeline-item {
      padding: 40px 0 0 0;
      margin-left: 0;

      @media (max-width: 767px) {
        padding: 30px 0 0 0;
      }

      .timeline-item-divider {
        margin-bottom: 50px;
      }
    }

    .timeline-text-col {
      align-items: center;
      display: flex;
    }

    .timeline-title {
      margin-bottom: 20px;
    }

    .timeline-description {
      margin-top: 0;
    }

    .timeline-body {

    }

    .timeline-icon {
      color: $success;
      margin-right: 20px;
      margin-top: 5px;
      float: left;
    }

    .timeline-img {
      width: 100%;

      @media (max-width: 767px) {
        margin-bottom: 30px;
        margin-top: 20px;
      }
    }

    .timeline-sections {
      margin-top: 24px;
      
      .timeline-section {
        scroll-margin-top: 100px; // Adjust this value based on your header height
        
        &:not(:last-child) {
          margin-bottom: 48px;

          @media (max-width: 767px) {
            margin-bottom: 0px;
          }
        }
      }
    }
  }

  .trusted-by {
    padding-top: 80px;
    padding-bottom: 40px;
    background: $background;

    @media (max-width: 767px) {
      padding-top: 50px;
    }
  }

  .brands-title {
    margin-top: 0;
    margin-bottom: 20px;

    .highlight-text {
      font-family: 'EB Garamond';
    }
  }

  .brands-button {
    margin-top: 20px;
    margin-right: 10px;

    @media (max-width: 767px) {
      margin-right: 0;
      margin-bottom: 10px;
      width: 100%;
    }
  }

  .msg-btn {
    @media (max-width: 767px) {
      width: 100%;
    }
  }

  .logo-grid {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    gap: 48px;

    img {
        height: 32px;
        opacity: 0.7;
        transition: opacity 0.2s;

        &:hover {
            opacity: 1;
        }
    }
  }

  .logo-scroll {
    overflow: hidden;
    white-space: nowrap;
    position: relative;
    width: 100%;
    margin-top: 20px;
    padding: 60px 0;

    @media (max-width: 767px) {
      padding: 30px 0 20px 0;
    }

    .logo-scroll-content {
      display: inline-block;
      animation: scroll 30s linear infinite;

      img {
        box-shadow: 0px 10px 30px rgba(128, 128, 128, 0.2); 
        height: 100px;
        margin: 0 10px;
        vertical-align: middle;
        filter: grayscale(100%);
        opacity: 0.7;
        transition: all 0.3s ease;
        background: rgba(255,255,255,0.6);
        border-radius: 10px;
        padding: 30px 35px;

        &:hover {
          filter: grayscale(0%);
          opacity: 1;
        }
      }
    }
  }

  @keyframes scroll {
    0% {
      transform: translateX(0);
    }

    100% {
      transform: translateX(-100%);
    }
  }

  .faq-section {
    padding: 80px 0 80px 0;

    @media (max-width: 767px) {
      padding: 40px 15px;
    }

    .ant-collapse-header {
      padding: 15px 0;
    }

    .faq-collapse {
      margin-top: 40px;

      @media (max-width: 767px) {
        margin-top: 10px;
      }
    }

    .ant-collapse-expand-icon {
      margin-top: 4px;
    }

    .ant-collapse-header-text {
      font-size: 20px;
      color: $primary_text;
    }

    .ant-collapse-content-box {
      font-size: 18px;
      line-height: 1.6;
      color: $primary_text;
      padding-left: 24px;

      a {
        color: $success;
      }

      @media (max-width: 767px) {
        li {
          margin-bottom: 10px;
        }
      }
    }
  }

  .testimonial-section {
    background: $background;
    padding: 60px 0;

    @media (max-width: 767px) {
      padding-bottom: 40px;
    }

    .testimonial-content-col,
    .testimonial-img-col {
      align-items: center;
      display: flex;
    }

    .testimonial-date {
      font-size: 14px;
      text-transform: uppercase;
      font-weight: 600;
      letter-spacing: 0.5px;
      color: $success;
    }

    .testimonial-content {
      border-right: 1px solid $border;
      padding-right: 60px;
      margin-right: 60px;

      @media (max-width: 767px) {
        border-right: none;
        padding-right: 0;
        margin-right: 0;
        margin-top: 40px;
      }
    }

    .testimonial-text {
      font-size: 20px;
      line-height: 32px;
      color: $primary_text;
      margin-top: 25px;
      margin-bottom: 25px;

      @media (max-width: 767px) {
        margin-top: 0px;
      }
    }

    .testimonial-author-container {
      @media (max-width: 767px) {
        margin-bottom: 20px;
      }
    }

    .testimonial-author {
      font-size: 16px;
      font-weight: 600;
      color: $primary_text;

      @media (max-width: 767px) {
        font-size: 24px;
      }
    }

    .testimonial-role {
      font-size: 16px;
      color: $primary_text;

      @media (max-width: 767px) {
        font-size: 24px;
      }
    }

    .testimonial-img {
      width: 100%;
      border-radius: 5px;
    }
  }

  .desktop-only {
    @media (max-width: 768px) {
      display: none;
    }
  }
}
