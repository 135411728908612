@import './colors.scss';
@import './global.scss';

@mixin styling($horizontal_padding,
  $vertical_padding,
  $h1,
  $h1_line_height,
  $h2,
  $h2_line_height,
  $h3,
  $h3_line_height,
  $h4,
  $h4_line_height,
  $h5,
  $h5_line_height,
) {

  a.link,
  .link {
    color: $success;

    &:active {
      color: darken($success, 10);
    }

    &:hover {
      color: darken($success, 10);
    }
  }

  a.cta-link {
    color: $success;
    text-decoration: underline;

    &:hover {
      color: $success;
      opacity: 0.8;
      text-decoration: underline;
    }

    &:active {
      color: darken($success, 5);
      opacity: 1;
      text-decoration: underline;
    }
  }

  a.muted-link {
    color: gray !important;
  }

  // .ant-btn {
  //   border-radius: 0;
  // }

  .ant-collapse {
    background: rgba(48, 36, 36, 0);

    .ant-collapse-item {
      border-bottom: 1px solid $border;
      border-top: 0 !important;

      &.ant-collapse-item-active {
        border-top: 1px solid $success;

        .ant-collapse-header-text {
          color: $primary_text;
        }
      }
    }

    .ant-collapse-header {
      padding: 20px 0;
    }

    .ant-collapse-header-text {
      font-weight: 600;
      font-size: 18px;
      //color: $secondary_text;
    }

    .ant-collapse-content-box {
      padding: 0 0 20px 0;
      font-size: 18px;
      color: $primary_text;
    }

    .ant-collapse-expand-icon {
      // font-size: calc(0.5*$h5);
      margin-top: 2px;

      svg {
        fill: $secondary_text;
      }
    }
  }


  .main-cta-btn {
    height: 50px;
    padding: 0 40px;
    font-size: 18px;
    font-weight: 600;
    // font-family: 'Lato';

    &.ant-btn-default {
      &:hover {
        border-color: lighten($success, 15);
        color: lighten($success, 15);
      }

      &:active {
        border-color: darken($success, 15);
        color: darken($success, 15);
      }
    }
  }

  .ant-typography.home-superscript {
    color: $success;
    font-weight: 800;
    text-transform: uppercase;
    display: block;
    margin-bottom: 10px;
    font-size: 12px;
    letter-spacing: 0.5px;
  }


  .svg-icon {
    width: 14px;
    height: 14px;
    // margin-right: 8px;
    color: $secondary_text;
    vertical-align: middle;
  }


  .section {

    background: #ffffff;
    padding: 4rem 0px 3rem;

    .section-container {
      max-width: $max_width;
      margin: auto;
      padding: 0px 20px;
    }

    .section-header {
      text-align: center;
    }

    .section-title {
      text-align: center;
      font-size: $h1;
      line-height: $h1_line_height;
      color: $primary_text;
      font-weight: 600;
      margin-bottom: 0px;

      span {
        color: $success;
        font-family: inherit;
      }
    }


    .section-subtitle {
      //width: 90%;
      font-weight: 400;
      margin: 20px 0px;
      color: $primary_text;
      font-size: $h3;
      line-height: $h3_line_height;
      text-align: center;

      p {
        margin-bottom: 25px;
      }
    }

    .section-label {
      text-transform: uppercase;
      font-weight: 700;
      font-size: $h5;
      color: $success;
      text-align: center;
      margin-bottom: 15px;


    }

    .section-button-container {
      text-align: center;
      margin: 50px 0px 0px;
    }
  }


  .intro-section {
    display: flex;
    padding: 100px 25px 80px;
    //max-width: $width;
    margin: 0 auto;
    background: $secondary_bg;

    .section-title {
      text-align: left;
      font-size: 45px;
      line-height: 50px;
    }

    .section-subtitle {
      text-align: left;
      margin-left: 0;
      text-align: 18px;
      margin-bottom: 35px;
    }

    .intro-row {
      width: 100%;
    }

    .intro-title {
      font-size: 50px;
      line-height: 55px;
    }

    .intro-text-container {
      height: 100%;
      display: flex;
      align-items: center;
    }

    .highlight-text {
      color: $success;
      font-family: 'EB Garamond';
    }


    .accepted-insurance {
      background: $success_bg;
      color: $success;
      border-radius: 3px;
      display: inline-block;
      padding: 6px 10px 6px 0;
      border-radius: 5px;
      font-size: 13px;
      margin-top: 20px;

      svg {
        margin-right: 2px;
      }
    }


    .intro-image-container {
      position: relative;
    }

    .intro-img {
      width: 100%;
      // border-radius: 20px;
    }

  }


  .section.dark-bg {
    background: rgba(13, 163, 128, 0.1);
    // background: linear-gradient(to bottom right, rgba(13, 163, 128, 0.2), rgba(13, 163, 128, 0));
    overflow: auto;

    .accepted-insurance {
      background: none;
    }
  }


  @media only screen and (max-width: $breakpoint_desktop) {
    .section {
      padding: 3rem 0px;
    }

    .section.intro-section {
      padding: 60px 10px;
    }

    .section.intro-section .section-title {
      font-size: 38px;
      line-height: 42px;
    }

    .section.intro-section .section-subtitle {
      font-size: 16px;
    }

    .section .section-container {
      padding: 0px 15px;
    }

    .section .section-title {
      font-size: 30px;
      line-height: 38px;
    }

    .section .section-subtitle {
      font-size: 16px;
      line-height: 25px;
    }

    .section .section-button-container {
      margin-top: 30px;
    }

    .main-cta-btn {
      padding: 0px 25px;
      font-size: 16px;
      height: 45px;
      width: 100%;
    }


    .fixed-cta-button-container {
      margin: auto;
      width: 100%;
      bottom: 20px;
      z-index: 999;
    }

    .fixed-cta-button {
      width: 100%;
    }



  }


  // stats section - move to own component


  // .stats-section {
  //   display: flex;
  //   justify-content: space-between;
  //   padding: 60px 0;

  //   .stats-row {
  //     width: 100%;
  //   }

  //   .stat-item {
  //     text-align: center;
  //     max-width: 230px;
  //     margin: 20px auto;
  //   }

  //   .stat-value {
  //     font-size: 90px;
  //     margin-bottom: 10px;
  //     display: block;
  //     font-family: 'EB Garamond';
  //   }

  //   .stat-label {
  //     font-size: 18px;
  //   }
  // }


  // @media only screen and (max-width: $breakpoint_mobile) {

  //   .stats-section {
  //     padding: 30px 0px;
  //   }

  //   .stats-section .stat-value {
  //     font-size: 75px;
  //   }

  //   .stats-section .stat-label {
  //     font-size: 16px;
  //   }
  // }
}


@media screen and (max-width: 768px) {
  @include styling($horizontal_padding: $mobile_horizontal_padding,
    $vertical_padding: $mobile_vertical_padding,
    $h1: $mobile_h1,
    $h1_line_height: $mobile_h1_line_height,
    $h2: $mobile_h2,
    $h2_line_height: $mobile_h2_line_height,
    $h3: $mobile_h3,
    $h3_line_height: $mobile_h3_line_height,
    $h4: $mobile_h4,
    $h4_line_height: $mobile_h4_line_height,
    $h5: $mobile_h5,
    $h5_line_height: $mobile_h5_line_height,
  );
}

@media screen and (min-width: 769px) {
  @include styling($horizontal_padding: $desktop_horizontal_padding,
    $vertical_padding: $desktop_vertical_padding,
    $h1: $desktop_h1,
    $h1_line_height: $desktop_h1_line_height,
    $h2: $desktop_h2,
    $h2_line_height: $desktop_h2_line_height,
    $h3: $desktop_h3,
    $h3_line_height: $desktop_h3_line_height,
    $h4: $desktop_h4,
    $h4_line_height: $desktop_h4_line_height,
    $h5: $desktop_h5,
    $h5_line_height: $desktop_h5_line_height,
  );
}