@import '../../colors.scss';
@import '../../global.scss';

@mixin styling($horizontal_padding,
    $vertical_padding,
    $h1,
    $h1_line_height,
    $h2,
    $h2_line_height,
    $h3,
    $h3_line_height,
    $h4,
    $h4_line_height,
    $h5,
    $h5_line_height,
    $h6,
    $h6_line_height,
) {


    .recommendation-collapse {

        box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.1);
        border: 1px solid rgba(0, 0, 0, 0.15);
        border-radius: 5px;
        margin-bottom: 10px;
        cursor: pointer;

        &:hover {
            border-color: darken(#f0f0f0, 10%);
        }



        .ant-collapse-header {
            align-items: center !important;
            background: white;
            border-radius: 8px !important;
            padding: 20px !important;


            .ant-collapse-header-text {
                font-weight: 500;
                color: #262626;
                font-size: $h4;
            }

            .ant-collapse-expand-icon {
                margin-top: 0px !important;
            }

        }

        // @media (max-width: 768px) {
        //     .ant-collapse-header {
        //         padding: 12px 12px !important;
        //     }
        // }


        .ant-collapse-item-active {
            .ant-collapse-header {
                border-radius: 8px 8px 0 0 !important;
            }
        }


        .ant-collapse-content {

            // background: inherit !important;
            .ant-collapse-content-box {
                padding: 10px 20px !important;
                color: #595959;
                font-size: $h5;
                line-height: $h5_line_height;
            }
        }



        .recommendation-header {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;

            @media (max-width: 768px) {
                flex-direction: column;
                align-items: flex-start;
                gap: 10px;
            }
        }

        .recommendation-title {
            font-size: $h4;
            line-height: $h4_line_height;
            font-weight: 500;
            color: #2c3e50;
        }



        .recommendation-content {


            @media (max-width: 768px) {
                padding: 0px 8px;
            }

            .description-block {
                margin-bottom: 16px;
                font-size: $h5;
                line-height: $h5_line_height;


                // Section headers
                .recommendation-heading {
                    font-size: $h4;
                    color: #2c3e50;
                    font-weight: 600;
                    margin-top: 24px;
                    padding-bottom: 12px;
                    border-bottom: 2px solid #f0f0f0;


                }

                strong {
                    font-weight: 600;
                }

                // Body text
                p {
                    color: #4a4a4a;
                    margin-bottom: 16px;
                    font-size: $h5;
                    line-height: $h5_line_height;
                }

                // list items 
                .recommendation-list {
                    margin: 12px 0;
                    margin-left: -24px;
                    font-size: $h5;
                    line-height: $h5_line_height;

                    .recommendation-list-item {
                        margin-bottom: 8px;

                        &:last-child {
                            margin-bottom: 0;
                        }
                    }
                }
            }

            .section-title {
                font-size: $h5;
                font-weight: 600;
                color: #2c3e50;
                margin-bottom: 12px;
                border-bottom: 1px solid #f0f0f0;
            }

            .heading-with-icon {
                display: flex;
                align-items: center;
                gap: 8px;

                .bullet-point {
                    color: #4caf50;
                    font-size: 20px;
                }
            }


            .foodTable {
                margin: 16px 0;

                .food-item-content {
                    display: flex;
                    flex-direction: column;
                    gap: 4px;
                }

                .food-main {
                    display: flex;
                    align-items: flex-start;
                    gap: 4px;
                }

                .food-name {
                    font-weight: 500;
                }

                .food-details {
                    color: #666;
                    font-size: 0.9em;
                }

                .detail-label {
                    color: #888;
                    font-style: italic;
                }

                .food-note {
                    color: #666;
                    font-size: 0.9em;
                    font-style: italic;
                }

                .ant-table {
                    background: #fafafa;
                    overflow: hidden;
                }

                .ant-table-tbody>tr>td {
                    padding: 12px 16px;
                    border-bottom: 1px solid #f0f0f0;
                    font-size: 14px;
                    line-height: 1.6;

                    // Category headers (Whole grains:, Legumes:, etc.)
                    strong {
                        color: #2c3e50;
                        font-weight: 600;
                        font-size: 15px;
                        display: inline-block;
                        margin-right: 8px;
                    }
                }

                // Add hover effect on rows
                .ant-table-tbody>tr:hover>td {
                    background-color: #f6f9f6 !important;
                }

                // Style for the food items
                .ant-table-tbody>tr>td p {
                    margin: 0;
                    color: #34495e;
                }

                // Add more spacing between different food categories
                .ant-table-tbody>tr:not(:last-child)>td {
                    border-bottom: 1px solid #eaeaea;
                }

                // Style for the Eat More/Eat Less indicators
                .ant-table-tbody>tr>td span {
                    &[role="img"] {
                        margin-right: 8px;
                        font-size: 16px;
                    }
                }

                // Add subtle divider between sections
                .ant-table-tbody>tr.category-divider>td {
                    border-bottom: 2px solid #e8e8e8;
                }

                .ant-table-cell {
                    vertical-align: top;
                }
            }
        }


    }

    .food-section {
        // background: white;
        // border-radius: $border_radius;
        // box-shadow: 0 2px 8px rgba(0, 0, 0, 0.06);
        // padding: 20px;
        // margin-top: 16px;


        // Food Categories Section
        .food-categories {
            .table-header {
                margin-bottom: 20px;

                h4 {
                    font-size: $h5;
                    font-weight: 600;
                    color: #2c3e50;
                    margin-bottom: 8px;
                }

                .table-description {
                    font-size: $h5;
                    color: #666;
                    margin: 0;
                    line-height: 1.5;
                }
            }
        }

        // Legend for Eat More/Eat Less
        .food-legend {
            display: flex;
            align-items: center;
            gap: 24px;
            margin-bottom: 20px;
            padding: 12px 16px;
            background: #f8f9fa;
            border: 1px solid #eaeaea;

            .legend-item {
                display: flex;
                align-items: center;
                gap: 8px;
                font-size: 14px;
                color: #4a4a4a;

                .dot {
                    width: 10px;
                    height: 10px;
                    border-radius: 50%;
                    margin-right: 8px;

                    &.eat-more {
                        background-color: #4caf50;
                    }

                    &.eat-less {
                        background-color: #f44336;
                    }
                }
            }
        }

        // Food Table Styling
        .foodTable {
            .ant-table {
                background: transparent;
                border-radius: 0;

                .ant-table-tbody {
                    >tr {
                        &:hover>td {
                            background-color: #f8faf8;
                        }

                        >td {
                            padding: 12px 16px;
                            border-bottom: 1px solid #f0f0f0;
                            transition: background-color 0.3s ease;

                            // Category headers
                            strong {
                                color: #2c3e50;
                                font-weight: 600;
                                font-size: $h5;
                                display: inline-block;
                                margin-right: 8px;
                            }

                            // Food items
                            .food-item {
                                display: flex;
                                align-items: center;
                                gap: 12px;
                                color: #34495e;
                                font-size: $h6;
                                line-height: $h6_line_height;

                                &.eat-more {
                                    color: #2e7d32;

                                    // &::before {
                                    //     content: "•";
                                    //     color: #4caf50;
                                    //     font-size: 24px;
                                    //     line-height: 0;
                                    //     margin-right: 8px;
                                    // }
                                }

                                &.eat-less {
                                    color: #c62828;

                                    // &::before {
                                    //     content: "•";
                                    //     color: #f44336;
                                    //     font-size: 24px;
                                    //     line-height: 0;
                                    //     margin-right: 8px;
                                    // }
                                }
                            }
                        }

                        &.category-divider>td {
                            background-color: #f8f9fa;
                            font-weight: 600;
                            color: #2c3e50;
                            padding-top: 16px;
                            padding-bottom: 16px;
                        }
                    }
                }
            }
        }
    }

    .recommendation-actions {
        display: flex;
        gap: 8px;

        .action-button {
            padding: 6px 16px;
            border-radius: 4px;
            border: none;
            cursor: pointer;
            font-weight: 500;

            &.order-button {
                background-color: #2e90ff;
                color: white;

                &:hover {
                    background-color: #1a7ef7;
                }
            }

            &.schedule-button {
                background-color: #4bb521;
                color: white;

                &:hover {
                    background-color: #409619;
                }
            }
        }

        @media (max-width: 768px) {
            width: 100%;
            display: none;

            .action-button {
                flex: 1;
                min-width: 0; // Allows buttons to shrink if needed
                white-space: nowrap;
            }
        }
    }



}


@media screen and (max-width: 768px) {
    @include styling($horizontal_padding: $mobile_horizontal_padding,
        $vertical_padding: $mobile_vertical_padding,
        $h1: $mobile_h1,
        $h1_line_height: $mobile_h1_line_height,
        $h2: $mobile_h2,
        $h2_line_height: $mobile_h2_line_height,
        $h3: $mobile_h3,
        $h3_line_height: $mobile_h3_line_height,
        $h4: $mobile_h4,
        $h4_line_height: $mobile_h4_line_height,
        $h5: $mobile_h5,
        $h5_line_height: $mobile_h5_line_height,
        $h6: $mobile_h6,
        $h6_line_height: $mobile_h6_line_height,
    );

}

@media screen and (min-width: 769px) {
    @include styling($horizontal_padding: $desktop_horizontal_padding,
        $vertical_padding: $desktop_vertical_padding,
        $h1: $desktop_h1,
        $h1_line_height: $desktop_h1_line_height,
        $h2: $desktop_h2,
        $h2_line_height: $desktop_h2_line_height,
        $h3: $desktop_h3,
        $h3_line_height: $desktop_h3_line_height,
        $h4: $desktop_h4,
        $h4_line_height: $desktop_h4_line_height,
        $h5: $desktop_h5,
        $h5_line_height: $desktop_h5_line_height,
        $h6: $desktop_h6,
        $h6_line_height: $desktop_h6_line_height,
    );
}