@import '../../colors.scss';

.test-results-modal {
  .category-item {
    margin-top: 20px;
  }

  .category-name {
    color: $primary_text;
    font-size: 12px;
    font-weight: 700;
    display: block;
    margin-bottom: 10px;
  }

  .test-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 2px 0;

    &:hover {
      background: $secondary_bg;
    }
  }

  .test-name {
    color: $primary_text;
    font-size: 12px;
    width: 190px;
  }

  .test-unit {
    color: $secondary_text;
    font-size: 12px;
    width: 100px;
    text-align: right;
  }

  .ant-form-item {
    margin: 0;
  }

  .field-container {
    flex: 1;
  }


  .ant-radio-wrapper {
    padding: 0;
    border: 0;
    font-size: 12px;
  }

  .ant-radio-group .ant-radio-checked .ant-radio-inner {
    border-color: $success;

    &:after {
      background: $success;
    }
  }

  .ant-radio-group .ant-radio-wrapper-checked {
    background: rgba(0, 0, 0, 0);
  }

  .ant-select-arrow {
    margin-top: -10px;
  }

  .add-ons-select {
    .ant-select-selector {
      height: auto !important;
      min-height: 32px;

      .ant-select-selection-overflow {
        flex-wrap: wrap;
      }
    }
  }

  .ant-select-selection-item {
    background-color: transparent;
    padding: 0;
    height: unset;
  }

  .ant-select-selection-overflow-item {
    background-color: transparent;
    padding: 0;
  }

  .ant-select-selection-item-content {
    padding: 2px 8px;
    margin-inline-end: 0px;



    .ant-select-selection-item-remove {
      background-color: #f5f5f5;
      color: #999;
      margin: 0 8px 0 4px;
      padding-right: 4px;

      &:hover {
        color: #666;
      }
    }
  }

  // Style for the "+X more" tag
  .ant-select-selection-item-maxTagPlaceholder {
    background-color: #f5f5f5;
    border: 1px solid #e8e8e8;
    border-radius: 4px;
    padding: 2px 8px;
    color: #666;
  }
}