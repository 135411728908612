@import '../../colors.scss';
@import '../../global.scss';

$width: 768px;

.blog-border {
  width: 100%;
  height: 1px;
  background-color: $border;
  margin: 0;
}

.related-blogs {
  max-width: 1100px;
  margin: 40px auto 50px auto;
  padding: 0 10px;
  width: 100%;
}

.related-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
  width: 100%;
}

.related-title-text {
  font-size: 60px;
  font-style: italic;
  font-family: 'EB Garamond';
  color: $primary_text;
  margin: 0 !important;

  @media (max-width: 768px) {
    font-size: 50px;
  }

  @media (max-width: 480px) {
    font-size: 45px;
  }

  .mobile-text {
    display: none;
  }
  
  @media (max-width: 768px) {
    .desktop-text {
      display: none;
    }
    .mobile-text {
      display: inline;
    }
  }
}

.view-all-link {
  color: $success;
  font-size: 16px;
}

.loading-blog {
  height: calc(100vh - 350px);
  background-color: $secondary_bg;
  display: flex;
  align-items: center;
  justify-content: center;

  .loading-blog-text {
    font-size: 18px;
    color: $secondary_text;
  }

  .loading-blog-spinner {
    margin-right: 10px;
  }
}

.linkedin-icon {
  color: #0A66C2; // LinkedIn's official blue
}

.facebook-icon {
  color: #1877F2; // Facebook's official blue
}

.twitter-icon {
  color: #1DA1F2; // Twitter/X's classic blue
}

.reddit-icon {
  color: #FF4500;
}

.copy-icon {
  color: #bbb;
}

.blog {


  .blog-post {
    margin: 0 auto;
  }

  .blog-intro {
    width: $width;
    max-width: 100%;
    margin: 0 auto;
    padding: 20px 10px 60px 10px;
  }

  .blog-superscript {
    text-align: center;
    margin-top: 60px;
    margin-bottom: 20px;
    text-transform: uppercase;
    font-size: 14px;
    color: $secondary_text;
  }

  .blog-body {
    width: $width;
    max-width: 100%;
    margin: 0 auto;
    padding: 0px 10px;
  }

  .blog-image-wrapper {
    position: relative;
    width: 100%;
    padding-top: 35%;
    height: auto;
    max-width: 1100px;
    margin: 20px auto 50px auto;

    @media (max-width: 1024px) {  // Tablet breakpoint
      padding-top: 45%;
    }

    @media (max-width: 768px) {
      padding-top: 50%;

      .blog-image {
        border-radius: 0px;
      }
    }

    @media (max-width: 480px) {
      padding-top: 65%;

      .blog-image {
        border-radius: 0px;
      }
    }

    &.tall {
      padding-top: 45%;

      @media (max-width: 1024px) {  // Tablet breakpoint
        padding-top: 55%;
      }

      @media (max-width: 768px) {
        padding-top: 60%;

        .blog-image {
          border-radius: 0px;
        }
      }

      @media (max-width: 480px) {
        padding-top: 75%;

        .blog-image {
          border-radius: 0px;
        }
      }
    }

    &.bordered {
      border: 1px solid $border;
    }
  }

  .blog-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    display: block;
    border-radius: 5px;

    &.padded {
      padding: 20px;
    }

    &.bottom {
      object-position: center bottom;
    }
  }

  .blog-author {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 25px;
  }

  .blog-avatar {
    margin-right: 7px;
  }

  .blog-heading {
    line-height: 1.2;
    width: $width;
    margin: 0 auto 30px auto;
    color: $primary_text;
    max-width: 100%;
    font-size: 60px;
    font-family: 'EB Garamond';
    text-align: center;

    @media (max-width: 768px) {
      font-size: 50px;
    }

    @media (max-width: 480px) {
      font-size: 45px;
    }
  }

  .blog-audio {
    height: 50px;
    width: 100%;
    margin: 25px auto 40px auto;
    display: block;
  }

  .blog-chart {
    width: 100%;
    margin-bottom: 30px;
  }

  .blog-subheading {
    font-size: 24px;
    line-height: 1.6;
    margin: 20px auto 0 auto;
    color: $primary_text;
    max-width: 100%;
    text-align: center;
  }

  .blog-title {
    font-weight: 700;
    font-size: 21px;
    line-height: 1.4;
    margin-top: 30px;
    margin-bottom: 20px;
    color: $primary_text;
    max-width: 100%;
  }

  .blog-description {
    font-size: 18px;
    line-height: 1.6;
    margin: 20px 0 25px 0;
    color: $primary_text;
    max-width: 100%;
  }

  .blog-link {
    color: $success !important;
    border-bottom: 1px dotted $success;
    text-decoration: none;
  }

  .blog-table {
    .ant-table-thead tr:first-child .ant-table-cell:first-child {
      border-top-left-radius: 8px !important;
    }

    .ant-table-thead tr:first-child .ant-table-cell:last-child {
      border-top-right-radius: 8px !important;
    }
  }

  td.blog-cell-custom {
    padding: 0 !important;
  }

  .blog-cell-inner {
    padding: 8px 15px;
  }

  .blog-list {
    margin: 0;
    padding-left: 30px;
    max-width: 100%;
  }

  .blog-list li {
    font-size: 18px;
    line-height: 1.6;
    margin-bottom: 20px;
    color: $primary_text;
  }

  .blog-section {
    background: $secondary_bg;
    padding: 10px 20px;
    border-radius: 10px;

    .blog-title:first-child {
      margin-top: 20px;
    }
  }

  .blog-list li strong {
    font-weight: 700;
  }

  .blog-figure {
    text-align: center;
  }

  .blog-caption {
    display: block;
    margin-top: 0.5rem;
    color: #666;
    font-size: 0.9rem;
    font-style: italic;
  }
}

.sticky-blog-header {
  border-top: 1px solid $border;
  border-bottom: 1px solid $border;
  padding: 15px 0;
  background: rgba(255, 255, 255, 0.95);

  .sticky-blog-header-inner {
    width: $width;
    margin: 0 auto;
    padding: 0 10px;
    max-width: 100%;
    white-space: nowrap;

    overflow-x: auto;
    -webkit-overflow-scrolling: touch; // Smooth scrolling on iOS
    scrollbar-width: none; // Hide scrollbar on Firefox
    -ms-overflow-style: none; // Hide scrollbar on IE/Edge

    &::-webkit-scrollbar {
      display: none; // Hide scrollbar on Chrome/Safari
    }
  }

  .blog-label {
    font-size: 14px;
    margin-right: 30px;
    color: $primary_text;
  }

  .blog-label .anticon {
    margin-right: 6px;
    transform: scale(1.2);
    color: $primary_text;
  }

  .blog-label-link {
    color: $success;

    .anticon {
      color: $success;
    }

    .blog-label {
      color: $success;
    }
  }
}

.sources {
  .source-list {
    margin: 0;
    padding: 0 0 0 20px;
    font-size: 13px;
    line-height: 1.5
  }

  li {
    color: $secondary_text;
    margin-bottom: 15px;
  }

  a {
    color: $success;
    border-bottom: 1px dotted $success;
    text-decoration: none;
  }
}

.share-footer {
  width: 100%;
  width: $width;
  margin: 30px auto 80px auto;
}

.share-footer-title {
  font-weight: 700;
  font-size: 21px;
  line-height: 1.4;
  color: $primary_text;
  display: block;
  width: 100%;
  margin-bottom: 10px !important;
}

.share-buttons {
  display: flex;
  padding: 0px 10px;
  gap: 12px;
  flex-wrap: wrap;
  cursor: pointer;

  @media (max-width: 480px) {
    flex-direction: column;
    align-items: flex-start;
  }

  button, a {
    cursor: pointer !important;
  }  
}

.share-button {
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  transition: all 0.2s ease;
  font-size: 14px;
  padding: 0;
  margin-right: 15px;

  @media (max-width: 480px) {
    margin-right: 0;
  }

  &.linkedin {
    color: #666;
    
    &:hover, &:active {
      color: #0A66C2;
    }
  }

  &.twitter {
    color: #666;
    
    &:hover, &:active {
      color: #1DA1F2;
    }
  }

  &.reddit {
    color: #666;
    
    &:hover, &:active {
      color: #FF4500;
    }
  }

  &.facebook {
    color: #666;
    
    &:hover, &:active {
      color: #1877F2;
    }
  }

  &.copy {
    color: #666;
    
    &:hover, &:active {
      color: $success;
    }
  }

  &:hover {
    transform: translateY(-1px);
  }
}