@import '../../colors.scss';

.confirm-interest {
    .question-btn {
        font-weight: 600;
        padding: 0 35px;
        height: 40px;
        font-size: 16px;

        &:hover {
            border: 1px solid lighten($success, 15);
            color: lighten($success, 15);
        }

        &:active {
            border: 1px solid $success;
            color: $success;
        }
    }
}