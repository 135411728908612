@import '../../colors.scss';

.tasso-layover {
  width: 100%;
  max-width: 470px !important;

  .ant-popover-inner {
    padding: 0;
  }

  @media screen and (max-width: 576px) {
    position: fixed !important;
    top: 0 !important;
    left: 0 !important;
    right: 0 !important;
    bottom: 0 !important;
    max-width: 100vw !important;

    .ant-popover-content,
    .ant-popover-inner,
    .ant-popover-inner-content {
      height: 100%;
    }
  }
}

.tasso-popover {
  padding: 20px;
  width: 100%;

  .tasso-popover-header {
    padding: 0 0 20px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .close-popover-icon {
    font-size: 20px;
    color: $primary_text;

    &:active {
      opacity: 0.5;
    }
  }

  .tasso-img {
    width: 100%;
    margin-bottom: 20px;
    border-radius: 10px;
  }
  
  .tasso-title {
    font-family: 'EB Garamond';
    font-weight: 600;
    font-size: 30px;
    margin-top: 0;
    margin-bottom: 0;
    color: $primary_text;
  }
  
  .tasso-description {
    font-size: 20px;
    line-height: 1.4;
    color: $primary_text;
    margin-top: 10px;
    margin-bottom: 10px;
    color: $primary_text;
  }
}