@import '../../colors.scss';
@import '../../global.scss';

@mixin styling($horizontal_padding,
  $vertical_padding,
  $h1,
  $h1_line_height,
  $h2,
  $h2_line_height,
  $h3,
  $h3_line_height,
  $h4,
  $h4_line_height,
  $h5,
  $h5_line_height,
  $h6,
  $h6_line_height,
) {
  .testkits {
    width: 100%;

    .testkits-container {
      width: 100%;
      padding: 16px;

      .loading-container {
        display: flex;
        justify-content: center;
        padding: 40px 0;
      }

      .card-grid-view {
        display: grid;
        grid-template-columns: 1fr;
        gap: 16px;

        // For tablets (2 cards per row)
        @media (min-width: 768px) {
          grid-template-columns: repeat(2, 1fr);
        }

        // For desktop (3 cards per row)
        @media (min-width: 1024px) {
          grid-template-columns: repeat(3, 1fr);
        }

        // For large desktop (4 cards per row)
        @media (min-width: 1440px) {
          grid-template-columns: repeat(4, 1fr);
        }
      }

      // Style for individual cards
      .testkit-card {
        border: 1px solid #e8e8e8;
        background-color: #fff;
        transition: box-shadow 0.3s;
        height: 100%;
        display: flex;
        flex-direction: column;

        &.complete {
          opacity: 0.7;
          background-color: #f5f5f5;

          .testkit-card-content {
            color: #666;
          }
        }


        &:hover {
          box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
        }

        // Card header
        .testkit-card-header {
          margin-bottom: 12px;

          .testkit-title {
            font-weight: 600;
            font-size: 16px;
            margin-bottom: 4px;
            color: #000;
          }

          .ordered-date {
            font-size: 14px;
            color: #666;
          }
        }

        // Card rows
        .testkit-card-row {
          margin-bottom: 12px;
          display: flex;

          .row-label {
            min-width: 80px;
            font-weight: 500;
            color: #666;
          }

          .row-value {
            flex: 1;
          }

          &.tracking {
            .link {
              color: rgba(0, 0, 0, 0.65);
              text-decoration: none;

              &:hover {
                text-decoration: underline;
              }
            }
          }
        }

        // Status indicators
        .testkit-status {

          font-size: 12px;
          margin-left: 0;
          font-weight: unset;

          .ant-tag {
            border: none;

          }

          .ant-tag-default {
            border: 1px solid #D9D9D9;
          }


          // &.complete-status,
          // &.completed-status {
          //   background: $success_bg;
          //   color: $success;
          // }

          &.canceled-status,
          &.failed-status,
          &.cancel-status {
            color: $error;
            background: $error_bg;
            border: none;
          }

          &.confirmed-status,
          &.delivered-status,
          &.shipped-status {
            color: $success;
            background: $success_bg;
            border: none;
          }

          &.fulfilling-status {
            color: $warning;
            background: $warning_bg;
            border: none;
          }
        }

        .test-name {
          font-size: $h5;
          font-weight: 600;
          color: #262626;
          margin-bottom: 16px;
          line-height: $h5_line_height;
        }
      }
    }

  }
}

@media screen and (max-width: 768px) {
  @include styling($horizontal_padding: $mobile_horizontal_padding,
    $vertical_padding: $mobile_vertical_padding,
    $h1: $mobile_h1,
    $h1_line_height: $mobile_h1_line_height,
    $h2: $mobile_h2,
    $h2_line_height: $mobile_h2_line_height,
    $h3: $mobile_h3,
    $h3_line_height: $mobile_h3_line_height,
    $h4: $mobile_h4,
    $h4_line_height: $mobile_h4_line_height,
    $h5: $mobile_h5,
    $h5_line_height: $mobile_h5_line_height,
    $h6: $mobile_h6,
    $h6_line_height: $mobile_h6_line_height,
  );

}

@media screen and (min-width: 769px) {
  @include styling($horizontal_padding: $desktop_horizontal_padding,
    $vertical_padding: $desktop_vertical_padding,
    $h1: $desktop_h1,
    $h1_line_height: $desktop_h1_line_height,
    $h2: $desktop_h2,
    $h2_line_height: $desktop_h2_line_height,
    $h3: $desktop_h3,
    $h3_line_height: $desktop_h3_line_height,
    $h4: $desktop_h4,
    $h4_line_height: $desktop_h4_line_height,
    $h5: $desktop_h5,
    $h5_line_height: $desktop_h5_line_height,
    $h6: $desktop_h6,
    $h6_line_height: $desktop_h6_line_height,
  );
}