@import '../../global.scss';
@import '../../colors.scss';

.test-chart-drawer {
  position: relative;

  .ant-drawer-body {
    padding: 24px;
    position: relative;
    background-color: $secondary_bg;
    overflow-y: auto;
  }

  &.has-order-button {
    .ant-drawer-body {
      // padding-bottom: 80px; // Make space for the mobile order button
    }
  }

  &.panel-drawer {
    .drawer-back-button {
      margin-right: 16px;
    }
  }

  .panel-drawer-content {
    padding: 0 0 24px 0;

    .panel-header {
      margin-bottom: 24px;

      .panel-title {
        font-size: 28px;
        font-weight: 600;
        margin-bottom: 12px;
        color: #333;
      }

      .panel-tagline {
        font-size: 16px;
        color: #666;
        margin-bottom: 16px;
        line-height: 1.5;
      }

      .panel-price {
        margin-top: 16px;

        .price-tag {
          font-size: 16px;
          padding: 4px 12px;
          font-weight: 500;
        }
      }
    }

    .panel-description {
      margin-bottom: 24px;

      h4 {
        margin-bottom: 16px;
      }

      .panel-details {
        font-size: 15px;
        line-height: 1.6;
        color: #333;

        p {
          margin-bottom: 12px;
        }

        a {
          color: #1890ff;
          text-decoration: none;

          &:hover {
            text-decoration: underline;
          }
        }
      }
    }

    .panel-tests {
      margin-bottom: 32px;

      h4 {
        margin-bottom: 16px;
        display: flex;
        align-items: center;

        .anticon {
          margin-right: 8px;
        }
      }

      .categories-collapse {
        background: #fff;
        border-radius: 8px;

        .ant-collapse-item {
          border-radius: 4px;
          overflow: hidden;
          margin-bottom: 8px;
          border: 1px solid #f0f0f0;

          .ant-collapse-header {
            padding: 12px 16px;
            background: #fafafa;

            .category-header {
              display: flex;
              justify-content: space-between;
              align-items: center;
              width: 100%;
            }
          }

          .ant-collapse-content {
            border-top: 1px solid #f0f0f0;
          }
        }
      }

      .test-list-item {
        padding: 12px 16px;

        &:hover {
          background-color: #f9f9f9;
        }

        .test-item-content {
          width: 100%;

          .test-item-title {
            display: flex;
            align-items: center;
            margin-bottom: 4px;

            .test-abbreviation {
              margin-left: 8px;
              font-size: 14px;
            }
          }

          .test-tagline {
            font-size: 14px;
            line-height: 1.5;
            color: #666;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
          }
        }
      }
    }

    .panel-order-section {
      margin-top: 32px;
    }
  }

  .order-sidebar {
    position: sticky;
    top: 24px;
    margin-top: 24px;
  }

  @media (max-width: 768px) {

    .ant-drawer-body {
      padding: 0px;
    }

    /* in mobile, footer in the way */
    .test-chart-section:last-child {
      margin-bottom: 68px;


    }

    &.has-order-button .test-chart-section:last-child {
      margin-bottom: 175px;
    }

    // .test-chart-component-container {
    //   .mobile-footer {
    //     bottom: 70px;
    //   }
    // }
  }

  .drawer-back-button {
    display: flex;
    height: 32px;
    color: #333;


    .anticon {
      margin-right: 6px;
      background: none;

      &:hover {
        color: #333;
        background: none;
      }
    }
  }

  // Override Ant Design's default drawer header padding
  .ant-drawer-header {
    padding: 16px;
    display: flex;
    align-items: center;
  }

}

// Mobile footer for order button
.mobile-footer {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background: #fff;
  border-top: 1px solid #f0f0f0;
  padding: 12px 16px;
  z-index: 1000;
  box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.05);

  .ant-btn {
    width: 100%;
  }
}

// Make sure we have the same scrollbar styling as in the main container
.ant-drawer-body {

  /* Custom scrollbar styling */
  &::-webkit-scrollbar {
    width: 10px;
  }

  &::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 5px;
  }

  &::-webkit-scrollbar-thumb {
    background: #c1c1c1;
    border-radius: 5px;

    &:hover {
      background: #a8a8a8;
    }
  }
}