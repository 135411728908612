@import '../../../colors.scss';
@import '../../../global.scss';

@mixin styling($horizontal_padding,
    $vertical_padding,
    $h1,
    $h1_line_height,
    $h2,
    $h2_line_height,
    $h3,
    $h3_line_height,
    $h4,
    $h4_line_height,
    $h5,
    $h5_line_height,
    $h6,
    $h6_line_height,
) {

    .description-block {
        &:not(:last-child) {

            .self-report-box {
                // border-bottom: 2px solid #eaeaea;
                padding-bottom: 16px;
            }
        }
    }

    .self-report-box {
        background: $success_bg;
        // border: 2px solid #eaeaea;
        // border-radius: $border_radius;
        padding: 25px 20px;
        transition: all 0.3s ease;
        // box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
        margin-bottom: 16px;
        cursor: unset;



        .product-box-header {
            color: #666;
            font-size: $h4;
            margin-bottom: 8px;
        }

        .product-content {

            .product-title {
                font-size: $h4;
                font-weight: 600;
                color: #2c2c2c;
                margin-bottom: 8px;
            }

            .product-subtitle {
                color: #666;
                font-size: $h4;
                margin-bottom: 8px;
            }

            .product-directions {
                font-size: $h4;
                margin-bottom: 8px;

            }

            .product-description {
                font-size: $h5;
                color: #666;
                margin-bottom: 16px;
                line-height: 1.5;
                max-width: 100%;
                word-wrap: break-word;
            }

            .product-buy {
                margin-top: 8px;

                .product-buy-btn {
                    background: $success;
                    border: 2px solid $success;
                    color: #fff;
                    font-weight: 500;
                    height: 40px;
                    transition: all 0.3s ease;


                    .anticon {
                        font-size: 16px;
                        margin-right: 8px;
                    }
                }
            }
        }


    }
}



@media screen and (max-width: 768px) {
    @include styling($horizontal_padding: $mobile_horizontal_padding,
        $vertical_padding: $mobile_vertical_padding,
        $h1: $mobile_h1,
        $h1_line_height: $mobile_h1_line_height,
        $h2: $mobile_h2,
        $h2_line_height: $mobile_h2_line_height,
        $h3: $mobile_h3,
        $h3_line_height: $mobile_h3_line_height,
        $h4: $mobile_h4,
        $h4_line_height: $mobile_h4_line_height,
        $h5: $mobile_h5,
        $h5_line_height: $mobile_h5_line_height,
        $h6: $mobile_h6,
        $h6_line_height: $mobile_h6_line_height,
    );

}

@media screen and (min-width: 769px) {
    @include styling($horizontal_padding: $desktop_horizontal_padding,
        $vertical_padding: $desktop_vertical_padding,
        $h1: $desktop_h1,
        $h1_line_height: $desktop_h1_line_height,
        $h2: $desktop_h2,
        $h2_line_height: $desktop_h2_line_height,
        $h3: $desktop_h3,
        $h3_line_height: $desktop_h3_line_height,
        $h4: $desktop_h4,
        $h4_line_height: $desktop_h4_line_height,
        $h5: $desktop_h5,
        $h5_line_height: $desktop_h5_line_height,
        $h6: $desktop_h6,
        $h6_line_height: $desktop_h6_line_height,
    );
}