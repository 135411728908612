@import '../../../../colors.scss';
@import '../../../../global.scss';


@mixin styling($horizontal_padding,
  $vertical_padding,
  $h1,
  $h1_line_height,
  $h2,
  $h2_line_height,
  $h3,
  $h3_line_height,
  $h4,
  $h4_line_height,
  $h5,
  $h5_line_height,
  $h6,
  $h6_line_height) {


  .test-picker {
    width: 100%;

    // Section headers (A, B, C, etc.)
    h2 {
      font-size: 24px;
      font-weight: 600;
      color: #262626;
      margin: 24px 0 16px;
      padding-left: 20px;

      &:first-child {
        margin-top: 0;
      }
    }

    .adm-space {
      width: 100%;
      display: block;
    }

    .adm-space-item {
      display: block;
      width: 100%;
      padding-bottom: 0px !important;
    }

    .adm-selector-item-disabled {
      opacity: 0.7;
    }

    .adm-selector-item {
      max-width: none;
      width: 100%;
      padding: 10px;
      margin-bottom: 12px;
      border: 1px solid #e8e8e8;
      border-radius: 8px;
      transition: all 0.2s ease;
      background: white;

      &:hover {
        border-color: $success;
        box-shadow: 0 2px 8px rgba(0, 0, 0, 0.06);
      }

      &.adm-selector-item-active {
        background: #f6ffed;
        border-color: $success;
      }
    }

    .test-picker-label {
      text-align: left;
      position: relative;
      width: 100%;

      .option-title {
        font-size: 16px;
        font-weight: 500;
        color: #262626;
        margin-bottom: 0;
        display: flex;
        align-items: center;
        justify-content: space-between;
      }

      .option-price {
        color: $success;
        font-weight: 500;
        font-size: 15px;
      }
    }

    .col-2 {
      width: 100%;
      padding: 0px 10px;
    }

    .test-picker-abbr {
      display: inline-block;
      width: 28px;
      height: 28px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 12px;
      border-radius: 2px;
      color: $success;
      background: white;
      border: 1px solid $success_light;
    }

    .option-description {
      color: $secondary_text;
      font-size: 14px;
    }

    .option-tag {
      text-transform: uppercase;
      font-size: 10px;
      font-weight: 600;
      background: white;
      border: 1px solid $success_light;
      border-radius: 3px;
      color: $success;
      padding: 3px 12px;
      position: absolute;
      right: 0;
      bottom: -16px;
    }

    .adm-selector-check-mark-wrapper {
      display: none;
    }

    .test-picker-check {
      margin-left: auto;
      color: $success;
      display: none;
      position: absolute;
      right: 10px;
      top: 50%;
      transform: translateY(-50%);
    }

    .adm-selector-item-active,
    .adm-selector-item-multiple-active {
      background: $green_bg;
      box-shadow: inset 0 0 1px $success, inset 0 0 1px $success, inset 0 0 1px $success;

      .test-picker-abbr {
        color: white;
        background: $success;
        border: 1px solid $success;
      }

      .test-picker-check {
        display: inline-block;
      }

      .option-tag {
        color: white;
        background: $success;
        border: 1px solid $success;
      }

      .info-icon {
        display: none;
      }
    }

    .ant-input-status-error {
      background: $error_bg !important;
    }

    .ant-form-item-explain-error {
      float: left;
      background: $error_bg;
      padding: 5px 10px;
      border-radius: 3px;
      font-size: 12px;
      margin-top: 10px;
      margin-bottom: 10px;
    }

    .paid-status {
      background: $green_bg;
      padding: 15px;
      border-radius: 5px;
      color: $success;
      font-size: 16px;
      font-weight: 600;
      max-width: 470px;
      margin-top: -8px;

      .anticon {
        margin-right: 5px;
        opacity: 0.6;
      }
    }


    .skip-btn {
      height: 41px;
    }

    .search-container {
      display: flex;
      align-items: center;
      gap: 8px;
      margin-bottom: 16px;

      .test-search-input {
        width: 100%;

        &.ant-input-affix-wrapper-focused {
          border-color: $success !important;
          box-shadow: 0 0 0 2px rgba($success, 0.2) !important;
        }
      }

    }

    .test-picker-content {
      display: flex;
      gap: 20px;
      position: relative;
      height: calc(100vh - 500px);
      min-height: 300px;
      border: 1px solid rgb(230, 230, 230);
      border-radius: 8px;

    }

    .letter-index {
      position: sticky;
      top: 0;
      height: 100%;
      overflow-y: auto;
      height: calc(100vh - 502px);
      min-height: 300px;
      border-radius: 8px 0 0 8px;

      &::-webkit-scrollbar {
        width: 0;
      }

      &:hover {
        &::-webkit-scrollbar {
          width: 4px;
        }
      }

      &::-webkit-scrollbar-track {
        background: #f1f1f1;
      }

      &::-webkit-scrollbar-thumb {
        background: #888;
        border-radius: 4px;
      }

      .letter-list {
        display: flex;
        flex-direction: column;
        gap: 2px;
        height: 100%;
        overflow-y: auto;
        padding: 4px;
        background: #f8f8f8;

        .letter-item {
          width: 24px;
          height: 24px;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          border-radius: 4px;
          font-weight: 500;
          font-size: 12px;

          &.has-items {
            color: #666;

            &:hover {
              background-color: rgba(#333, 0.1);
            }

            &.active {
              background-color: #999;
              color: white;
            }
          }

          &.no-items {
            color: #d9d9d9;
            cursor: default;
          }
        }
      }
    }

    .test-list {
      flex: 1;
      overflow-y: auto;
      height: 100%;
      width: 100%;
      padding: 16px 16px 16px 0px;

      &::-webkit-scrollbar {
        width: 0;
      }

      &:hover {
        &::-webkit-scrollbar {
          width: 4px;
        }
      }

      &::-webkit-scrollbar-track {
        background: #f1f1f1;
      }

      &::-webkit-scrollbar-thumb {
        background: #888;
        border-radius: 4px;
      }
    }

    .letters-container {
      margin-right: 16px;
      font-size: 18px;
      color: #8c8c8c;
      font-weight: 500;
    }

    .info-icon {
      font-size: 20px;
      margin-left: 8px;
      position: absolute;
      right: 10px;
      top: 50%;
      transform: translateY(-50%);

      &:hover {
        color: $success;
      }
    }

    .letter-section {
      margin-bottom: 16px;
    }


    .panel-test-count {
      font-size: 0.9em;
      color: rgba(0, 0, 0, 0.45);
      display: inline-block;
      margin-left: 6px;
    }

    .option-metadata {
      display: inline-flex;
      align-items: center;
      gap: 4px;
      margin-left: 6px;


      .info-icon {
        color: #cccccc;

        &:hover {
          color: #999999;
        }
      }
    }

    .floating-summary {

      padding: 0px;

      .summary-content {
        max-width: $max_flow_width;
        margin: 0 auto;
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 12px;

        @media (max-width: $breakpoint_mobile) {
          padding: 16px;

          position: fixed;
          bottom: 0;
          left: 0;
          right: 0;
          background: white;
          box-shadow: 0 -1px 6px rgba(0, 0, 0, 0.1);
          z-index: 1000;
          background-color: #f6f6f6;

        }


      }


      .selected-count {
        border: 1px solid #eee;
        border-radius: 8px;
        background: #fcfcfc;
        padding: 0px 10px;
        height: 42px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;

        .count-group {
          display: flex;
          align-items: center;
          gap: 4px;

          .count-value {
            font-size: $h5;
            font-weight: 600;
            color: $success;


            &:contains('0') {
              color: gray;
            }
          }

          .count-label {
            font-size: $h6;
            color: #666;
            line-height: $h6_line_height;
          }

          .price-value {
            font-size: $h5;
            font-weight: 600;
            color: $success;
            margin-left: 16px;

            &:contains('0') {
              color: gray;
            }
          }
        }
      }

      .summary-actions {
        display: flex;
        gap: 12px;

        .skip-btn {
          @media (max-width: $breakpoint_mobile) {
            display: none;
          }
        }

      }
    }


    @media (max-width: $breakpoint_mobile) {
      .test-picker-content {
        min-height: 250px;
        height: calc(100vh - 385px);

      }

      .test-list {
        -webkit-overflow-scrolling: touch;
        overflow-y: auto;
        padding: 16px;
      }

      .adm-selector-item {
        padding: 10px;
        margin-bottom: 16px;

      }

      .test-picker-label {
        .option-title {
          font-size: 15px;
          line-height: 1.4;
        }
      }

      .letter-index {
        display: none;
      }

      .test-picker-field {
        margin-bottom: 12px;
      }
    }
  }


  .selected-tests-modal {
    .ant-modal-content {

      .ant-modal-header {
        border-bottom: 1px solid #f0f0f0;

        .ant-modal-title {
          font-size: 20px;
          font-weight: 600;
        }
      }
    }

    .selected-tests-list {
      max-height: 60vh;
      overflow-y: auto;


      .selected-test-item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 16px;
        margin: 8px 0;
        background: #fafafa;
        border-radius: 8px;
        transition: all 0.3s ease;

        &:hover {
          background: #f0f0f0;
          transform: translateX(4px);
        }

        .test-info {
          display: flex;
          justify-content: space-between;
          align-items: center;
          flex: 1;
          margin-right: 16px;
          gap: 12px;

          .test-name {
            font-weight: 500;
            color: #262626;
            flex: 1;
          }

          .test-price {
            font-weight: 600;
            color: $success;
            font-size: $h5;
            white-space: nowrap;
          }
        }

        .anticon-delete {
          color: #999999;
          font-size: $h5;
          transition: all 0.2s ease;

          &:hover {
            transform: scale(1.1);
            color: #666666;
          }
        }
      }

      .total-section {
        position: sticky;
        bottom: 0;
        background: white;
        margin-top: 24px;
        padding: 20px 16px;
        border-top: 2px solid #f0f0f0;
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: $h4;

        span {
          &:first-child {
            color: #8c8c8c;
            font-weight: 500;
          }
        }

        .total-price {
          font-size: $h4;
          font-weight: 600;
          color: $success;
        }
      }
    }

    .ant-empty {
      margin: 32px 0;

      .ant-empty-description {
        color: #8c8c8c;
        font-size: 16px;
      }
    }
  }
}


@media screen and (max-width: $breakpoint_mobile) {
  @include styling($horizontal_padding: $mobile_horizontal_padding,
    $vertical_padding: $mobile_vertical_padding,
    $h1: $mobile_h1,
    $h1_line_height: $mobile_h1_line_height,
    $h2: $mobile_h2,
    $h2_line_height: $mobile_h2_line_height,
    $h3: $mobile_h3,
    $h3_line_height: $mobile_h3_line_height,
    $h4: $mobile_h4,
    $h4_line_height: $mobile_h4_line_height,
    $h5: $mobile_h5,
    $h5_line_height: $mobile_h5_line_height,
    $h6: $mobile_h6,
    $h6_line_height: $mobile_h6_line_height );
}

@media screen and (min-width: calc($breakpoint_mobile + 1px)) {
  @include styling($horizontal_padding: $desktop_horizontal_padding,
    $vertical_padding: $desktop_vertical_padding,
    $h1: $desktop_h1,
    $h1_line_height: $desktop_h1_line_height,
    $h2: $desktop_h2,
    $h2_line_height: $desktop_h2_line_height,
    $h3: $desktop_h3,
    $h3_line_height: $desktop_h3_line_height,
    $h4: $desktop_h4,
    $h4_line_height: $desktop_h4_line_height,
    $h5: $desktop_h5,
    $h5_line_height: $desktop_h5_line_height,
    $h6: $desktop_h6,
    $h6_line_height: $desktop_h6_line_height );


}