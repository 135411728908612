@import '../../colors.scss';

.search-p2 {
  font-size: 21px;
  color: $primary_text;

  &.bold {
    font-weight: 600;
  }
}

.pricing-search {
  width: 100%;
  padding: 10px 15px 10px 20px;
  background: #fff;
  border-radius: 8px;
  margin-top: 50px;
  display: block;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  transition: background 0.3s ease;

  input {
    transition: background 0.3s ease;
  }

  &.error {
    background: $error_bg;
    transition: background 0.3s ease;

    input {
      transition: all 0.3s ease;
      background: $error_bg;
      color: $error;
    }
  }

  .pricing-search-label {
    font-weight: 600;
    display: block;
    font-size: 16px;
    margin-top: 10px;
  }

  .pricing-search-label-icon {
    color: $success;
    margin-right: 3px;
  }

  .pricing-search-address {
    border: none;
    width: 100%;
    padding-left: 0;
    padding-right: 0;
    font-size: 16px;
  }

  .search-address-btn-col {
    @media (max-width: 767px) {
      width: 100%;
    }
  }

  .search-address-btn {
    width: 100%;
    height: 69px;
    margin-bottom: 4px;
    font-size: 18px;
    font-weight: 600;
    padding: 0 50px;

    @media (max-width: 767px) {
      margin-top: 20px;
    }
  }
}

.clickable-input-col {
  cursor: text;
}

.search-facility-item {
  background: white;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  padding: 15px 20px;
  margin-top: 20px;
  position: relative;
}

.search-facility-name {
  margin-top: 0;
  margin-bottom: 5px !important;
  padding-right: 70px;
}

.search-facility-location {
  color: $secondary_text;
  font-size: 14px;
}

.search-facility-duration {
  font-size: 14px;
  color: $secondary_text;
  margin-top: 20px;
  margin-bottom: 0 !important;

  .anticon {
    margin-right: 5px;
  }
}

.search-facility-price {
  color: $success;
  top: 15px;
  right: 20px;
  position: absolute;
}

.schedule-facility-btn {
  position: absolute;
  bottom: 11px;
  right: 20px;
  font-size: 16px;
  font-weight: 600;
  height: 39px;
  padding: 0 10px 0 12px;
}