.subscribe-button {
  position: fixed;
  bottom: 15px;
  right: 25px;
  z-index: 1000;
  width: 110px;
  height: 40px;
  font-size: 14px;
  padding: 12px 24px;
  border-radius: 5px;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease, visibility 0.3s ease;
  
  &:hover {
    transform: translateY(-2px);
    transition: transform 0.2s ease;
  }

  &.visible {
    opacity: 1;
    visibility: visible;
  }
}