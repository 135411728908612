@import '../../colors.scss';
@import '../../global.scss';

@mixin styling($horizontal_padding,
  $vertical_padding,
  $h1,
  $h1_line_height,
  $h2,
  $h2_line_height,
  $h3,
  $h3_line_height,
  $h4,
  $h4_line_height,
  $h5,
  $h5_line_height,
  $h6,
  $h6_line_height,
) {
  .dashboard-container {
    background-color: $primary_bg;
    width: 100%;
    padding: 20px;

    .dashboard {
      max-width: 700px;
      margin: 0 auto;
    }


    @media (max-width: $breakpoint_mobile) {
      padding: 0px;
    }


    .secondary-link {
      color: $secondary-text;
      text-decoration: underline;
    }

    .dashboard-main {
      padding: 30px 0px 0px 0px;
      border-right: 1px solid #F0F0F0;
    }

    .page-header {
      border: 0px;
    }

    .page-header .page-header-title {
      font-size: $h3;
      line-height: $h3_line_height;
    }

    .test-catalog-container {

      padding: 0px 15px;

    }


    .section-card {
      background-color: transparent;
      // border-radius: 0px;
      border: none;
      // padding: 16px;

      .ant-card-head {
        border: none;
        padding: 0px;

        .ant-card-head-title {
          font-size: $h3;
          font-weight: 500;
        }
      }


      .ant-card-body {
        padding: 0px;
      }


      .section-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 24px;
        border-bottom: 1px solid #F0F0F0;

      }

      .section-card-content {
        background-color: $primary_bg_light;
        padding: 16px;
        border: 1px solid darken($primary_bg_light, 3%);

        .ant-card-body {
          padding: 20px;
        }
      }

      .ant-card-actions {
        background-color: $primary_bg_light;
        border-top: 1px solid darken($primary_bg_light, 3%);
        // border-radius: 8px;
        // margin-top: 12px;

        @media (max-width: $breakpoint_mobile) {
          margin: 12px 12px;
          border-radius: 8px;
        }
      }

      @media (max-width: $breakpoint_mobile) {
        .section-card-content {
          padding: 0px;
          background-color: transparent;
          border: none;
        }

        .ant-card-body {
          padding: 0px 12px;
        }
      }

      .see-more-link {
        font-size: $h5;
        line-height: $h5_line_height;
        color: $secondary_text;
      }
    }

    .ant-divider {
      margin: $h1 0px 24px;

      &:first-child {
        margin-top: 24px;
      }

      .ant-divider-inner-text {
        font-size: $h3;
        line-height: $h3_line_height;
        font-weight: 600;
        color: #262626;
      }

    }


    .recommendations-card {
      .ant-card-actions {
        margin-top: 0px;
      }
    }


    .research-card {
      .research-title {
        font-size: $h4;
        line-height: $h4_line_height;
        font-weight: 600;
        color: #262626;
      }

      .research-description {
        font-size: $h6;
        line-height: $h6_line_height;
        color: #595959;
        margin-bottom: 12px;
      }

      .research-meta {
        // display: none;
        font-size: $h6;
        line-height: $h6_line_height;
        color: $secondary-text;
      }
    }




    .gray-link {
      color: $secondary_text;
      text-decoration: none;

      &:hover {
        color: darken($secondary_text, 15%) !important;
      }
    }


    .balance-card {

      .balance-amount {
        transition: all 0.3s ease;
        display: block;
        margin-bottom: 8px;
        font-size: 32px;
        font-weight: 600;

        &.balance-updated {
          color: $success;
          transform: scale(1.05);
        }
      }
    }

    .balance-content {
      padding: 16px 0 0px;

      .balance-amount {
        font-size: $h3;
        font-weight: 600;
        color: #262626;
        transition: color 0.3s ease;

        &.balance-updated {
          animation: balanceUpdate 1s ease;
          color: $success;
        }
      }

    }

    .appointments-card {
      .appointment-card .appointment-card-content .tests {
        font-size: $h4;
        line-height: $h4_line_height;
        font-weight: 600;
        color: #262626;
      }
    }


    .tests-card {

      .test-catalog {
        margin-bottom: 0px;
        padding: 0px 6px;

        .ant-row {
          row-gap: 12px !important;
        }

        .ant-col {
          padding-left: 6px !important;
          padding-right: 6px !important;
        }
      }

      .test-catalog-item {

        .test-title-description-container {
          height: 10em;
        }

        .test-title {
          font-size: $h4;
          line-height: $h4_line_height;
        }

        .test-description {
          font-size: $h6;
          line-height: $h6_line_height;
          color: $secondary_text;
        }

        .test-price {
          font-size: $h6;
          line-height: $h6_line_height;
          color: $secondary_text;
        }


      }

      .recommended-note {
        display: none;
      }
    }

    .longevity-score-card {
      background: #ffffff;
      overflow: hidden;

      // Risk-based variations
      &.low-risk,
      &.optimal {
        border-left: 4px solid $success;

        .ant-statistic-content {
          color: $success;
        }
      }

      &.moderate-risk,
      &.moderate,
      &.borderline {
        border-left: 4px solid#d48806;

        .ant-statistic-content {
          color: #d48806;
        }
      }

      &.high-risk,
      &.at-risk,
      &.high {
        border-left: 4px solid #cf1322;

        .ant-statistic-content {
          color: #cf1322;
        }
      }

      .score-container {
        padding: 24px;
        cursor: pointer;
        transition: all 0.2s ease;
        text-align: center;



        .ant-statistic {
          .ant-statistic-title {
            font-size: 14px;
            color: rgba(0, 0, 0, 0.45);
            margin-bottom: 16px;
          }

          .ant-statistic-content-value {
            font-size: $h1_line_height;
            font-weight: 600;
            line-height: 1;
          }

          .ant-statistic-content-suffix {
            font-size: $h3;
            opacity: 0.7;
            font-weight: 400;
          }

        }


      }

      @media (max-width: 768px) {
        .score-container {
          padding: 16px 0px;
          text-align: left;

          .ant-statistic {
            .ant-statistic-content {
              font-size: 36px;
            }
          }
        }
      }


    }

    .stat-card {
      height: 100%;

      &.optimal {
        // background: linear-gradient(145deg, rgba(82, 196, 26, 0.05) 0%, rgba(82, 196, 26, 0.1) 100%);

        .ant-statistic-content-value,
        .ant-statistic-content-suffix {
          color: $success;
        }

        &:hover {
          background: rgba(82, 196, 26, 0.15);
        }
      }

      &.borderline {
        // background: linear-gradient(145deg, rgba(250, 173, 20, 0.05) 0%, rgba(250, 173, 20, 0.1) 100%);

        .ant-statistic-content-value,
        .ant-statistic-content-suffix {
          color: $warning;
        }


        &:hover {
          background: rgba(250, 173, 20, 0.15);
        }
      }

      &.at-risk {
        // background: linear-gradient(145deg, rgba(255, 77, 79, 0.05) 0%, rgba(255, 77, 79, 0.1) 100%);


        .ant-statistic-content-value,
        .ant-statistic-content-suffix {
          color: $error;
        }

        &:hover {
          background: rgba(255, 77, 79, 0.15);
        }
      }
    }




    .credit-card {
      height: 100%;

      .credit-card-content {
        .credit-title {
          font-size: $h5;
          font-weight: 600;
          color: #262626;
          margin-bottom: 8px;
        }

        // Add spacing between text and button
        .ant-row {
          gap: 16px;
        }
      }

      // Responsive adjustments
      @media (max-width: 576px) {
        .credit-card-content {
          .ant-row {
            flex-direction: column;
            row-gap: 8px;

            .ant-btn {
              align-items: baseline;
              justify-content: flex-start;
              display: inline-flex;
              margin-left: 0;
            }
          }
        }
      }
    }

  }


  @media only screen and (min-width: $breakpoint_desktop) {
    .dashboard .dashboard-main {
      border-right: 1px solid #F0F0F0;
      min-height: 1200px;
    }
  }

  @media only screen and (max-width: $breakpoint_desktop) {
    .dashboard .dashboard-main {
      border: 0;
      padding: calc($vertical_padding/2) 0 0 0;
      // margin-top: -25px;
    }


  }

  @media only screen and (max-width: $breakpoint_mobile) {

    // .dashboard {
    //   padding-top: $h1;
    // }

    .dashboard .dashboard-alert {
      padding: 10px 15px;
    }
  }
}




@media screen and (max-width: 768px) {
  @include styling($horizontal_padding: $mobile_horizontal_padding,
    $vertical_padding: $mobile_vertical_padding,
    $h1: $mobile_h1,
    $h1_line_height: $mobile_h1_line_height,
    $h2: $mobile_h2,
    $h2_line_height: $mobile_h2_line_height,
    $h3: $mobile_h3,
    $h3_line_height: $mobile_h3_line_height,
    $h4: $mobile_h4,
    $h4_line_height: $mobile_h4_line_height,
    $h5: $mobile_h5,
    $h5_line_height: $mobile_h5_line_height,
    $h6: $mobile_h6,
    $h6_line_height: $mobile_h6_line_height,
  );

}

@media screen and (min-width: 769px) {
  @include styling($horizontal_padding: $desktop_horizontal_padding,
    $vertical_padding: $desktop_vertical_padding,
    $h1: $desktop_h1,
    $h1_line_height: $desktop_h1_line_height,
    $h2: $desktop_h2,
    $h2_line_height: $desktop_h2_line_height,
    $h3: $desktop_h3,
    $h3_line_height: $desktop_h3_line_height,
    $h4: $desktop_h4,
    $h4_line_height: $desktop_h4_line_height,
    $h5: $desktop_h5,
    $h5_line_height: $desktop_h5_line_height,
    $h6: $desktop_h6,
    $h6_line_height: $desktop_h6_line_height,
  );
}

@keyframes balanceUpdate {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.1);
  }

  100% {
    transform: scale(1);
  }
}