@import '../../global.scss';
@import '../../colors.scss';


.test-page {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    background-color: $secondary_bg;
    padding: 24px;

    .test-page-chart-container {
        flex: 1;
        overflow-y: auto;
        padding: 0;
        max-width: $max_width;
        margin: 0 auto;
        // box-shadow: 0 2px 8px rgba(0, 0, 0, 0.06);
        width: 100%;
    }

    .test-chart-component {
        height: 100%;
    }




    .test-chart-loading {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        min-height: 100vh;
        width: 100%;
        background-color: white;
    }

    @media (min-width: 768px) {
        .test-chart-section {
            padding: 32px;
        }

        .chart-header {
            padding: 32px 32px 16px 32px;
        }
    }

    @media (max-width: 767px) {
        &.test-page {
            padding: 0;
        }
    }
}