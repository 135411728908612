@import '../../colors.scss';
@import '../../global.scss';

@mixin styling($horizontal_padding,
  $vertical_padding,
  $h1,
  $h1_line_height,
  $h2,
  $h2_line_height,
  $h3,
  $h3_line_height,
  $h4,
  $h4_line_height,
  $h5,
  $h5_line_height,
  $h6,
  $h6_line_height,
) {
  .loading {
    text-align: center;
    padding: 100px 0;

    .loading-text {
      color: $secondary_text;
    }
  }

  .gameplan-v2 {
    // padding: 24px;
    background: #f5f5f5;
    min-height: 100vh;
    width: 100%;

    .gameplan-main {
      padding: 0 32px;
    }

    .gameplan-toc {
      background-color: #f8f9fa;
      border-right: 1px solid #e8e8e8;
      min-height: calc(100vh - 64px); // Adjust based on your header height
      position: sticky;
      top: 0;
      overflow-y: auto;

      // Add a subtle shadow for depth
      box-shadow: 2px 0 4px rgba(0, 0, 0, 0.05);

      // Style the TOC title if present
      h3,
      h4 {
        color: #1a1a1a;
        margin-bottom: 16px;
        font-weight: 600;
      }

      // Style anchor links
      .ant-anchor {
        padding-left: 0;

        .ant-anchor-link {
          padding: 8px 0;

          .ant-anchor-link-title {
            color: #595959;
            font-size: 14px;
            transition: color 0.3s;

            &:hover {
              color: #1890ff;
            }
          }

          &-active {
            .ant-anchor-link-title {
              color: #1890ff;
              font-weight: 500;
            }
          }
        }
      }
    }

    // Section styling
    .gameplan-section {
      margin: 1.5rem auto;
      padding: 2rem;
      background: #fff;
      box-shadow: 0 2px 12px rgba(0, 0, 0, 0.08);
      transition: all 0.2s ease;
      max-width: $max_width;
      // border-radius: 10px;

      .section-title {
        margin-top: 1rem;
        margin-bottom: 1rem;
        font-size: 1.5rem;
        font-weight: 600;
        display: flex;
        align-items: center;
        border: 0px;


      }

      .section-description {
        padding-bottom: $h5;
        border-bottom: 1px solid #f0f0f0;
        color: rgba(0, 0, 0, 0.65);
        font-size: 1rem;
        line-height: 1.6;
      }

      .biomarker-list,
      .recommendation-list {
        margin: 1rem 0;

        // .recommendation-item {
        //   background: rgba(0, 0, 0, 0.02);
        //   border-radius: 8px;
        //   padding: 1.5rem;
        //   margin-bottom: 1rem;
        //   transition: all 0.2s ease;

        //   &:hover {
        //     background: rgba(0, 0, 0, 0.04);
        //   }
        // }

        .action-items-header {
          display: block;
          margin: 2rem 0 1rem;
          font-size: 18px;
          color: #333;

          .anticon {
            margin-right: 8px;
          }

        }

        .test-item-list .show-all-button {
          background: white;
          border: 1px solid #e8e8e8;
          box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

          color: #333;

          &:hover {
            background: #f0f0f0 !important;
          }
        }
      }

      .secondary-biomarkers-button-container {
        margin-top: 8px;
        background: #FBFBFB;
        padding: 6px 10px;
        border: 1px solid #e8e8e8;

      }

      .secondary-biomarkers-button {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 50px;
        font-size: $h5;

        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
        border: 1px solid rgba(0, 0, 0, 0.15);
        border-radius: 5px;
        width: 100%;
        font-weight: 500;
        color: #333;
        margin: 8px auto;

        &:hover,
        &:focus {
          border-radius: 4px;
          color: #333;
          background-color: #FBFBFB;
        }

        .anticon {
          font-size: $h5;
        }

        .secondary-biomarkers-badge {
          .ant-badge-count {
            background-color: #f0f0f0;
            color: #333;
            font-size: $h6;
            min-width: 20px;
            height: 20px;
            line-height: 20px;
            border-radius: 10px;
            padding: 0 6px;
            box-shadow: none;
          }
        }
      }

      .recommendation-list {
        .recommendation-list-items {
          background: #FBFBFB;
          border: 1px solid #E6E6E6;
          // border-radius: 5px;
          padding: 10px 10px 0 10px;
          margin-bottom: 1rem;
        }
      }
    }

    // Add hover effect
    .gameplan-section:hover {
      transform: translateY(-2px);
      box-shadow: 0 4px 16px rgba(0, 0, 0, 0.12);
    }


  }
}

@media screen and (max-width: 991px) {
  @include styling($horizontal_padding: $mobile_horizontal_padding,
    $vertical_padding: $mobile_vertical_padding,
    $h1: $mobile_h1,
    $h1_line_height: $mobile_h1_line_height,
    $h2: $mobile_h2,
    $h2_line_height: $mobile_h2_line_height,
    $h3: $mobile_h3,
    $h3_line_height: $mobile_h3_line_height,
    $h4: $mobile_h4,
    $h4_line_height: $mobile_h4_line_height,
    $h5: $mobile_h5,
    $h5_line_height: $mobile_h5_line_height,
    $h6: $mobile_h6,
    $h6_line_height: $mobile_h6_line_height,
  );
}

@media screen and (min-width: 992px) {
  @include styling($horizontal_padding: $desktop_horizontal_padding,
    $vertical_padding: $desktop_vertical_padding,
    $h1: $desktop_h1,
    $h1_line_height: $desktop_h1_line_height,
    $h2: $desktop_h2,
    $h2_line_height: $desktop_h2_line_height,
    $h3: $desktop_h3,
    $h3_line_height: $desktop_h3_line_height,
    $h4: $desktop_h4,
    $h4_line_height: $desktop_h4_line_height,
    $h5: $desktop_h5,
    $h5_line_height: $desktop_h5_line_height,
    $h6: $desktop_h6,
    $h6_line_height: $desktop_h6_line_height,
  );
}

// Responsive adjustments
@media (max-width: 991px) {

  @media (max-width: 768px) {

    .gameplan-v2 {

      .gameplan-section {

        margin-top: 5px;
        margin-bottom: 0px;
        padding: 16px;

      }

      .gameplan-main {
        padding: 0;
      }

    }
  }


  .mobile-toc-button {
    position: fixed;
    bottom: 80px;
    right: 25px;
    z-index: 1000;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  }

  .mobile-toc-popover {

    .ant-popover-inner-content {
      padding: 0;
      max-height: 80vh;
      // overflow-y: auto;
    }

    .mobile-toc-menu {
      min-width: 250px;

      .ant-anchor {
        padding: 12px;
      }

      .ant-btn-text {
        text-align: left;
      }

      .view-toggle-button {
        text-align: left;
        padding: 8px;
        // border-bottom: 1px solid #f0f0f0;
        margin-bottom: 12px;


      }
    }
  }

  .view-toggle-container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 12px;
    margin-left: 12px;
    margin-right: 12px;


    .view-toggle-segment {
      // width: calc(100% - 32px); // Account for padding

      .ant-segmented {
        background-color: white;
        padding: 4px;
      }

      .ant-segmented-item {
        padding: 4px 16px;
        transition: all 0.3s;

        m &-selected {
          background: white;
          border-radius: 6px;
          box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
        }
      }

      .ant-segmented-item-label {
        font-size: 14px;
        min-height: 28px;
        line-height: 28px;
      }
    }
  }


  .mobile-only {
    display: block !important;
  }


}



.secondary-biomarkers-modal {

  max-height: 80vh;
  overflow: auto;

  @media screen and (max-width: $breakpoint_mobile) {


    .ant-modal-content {
      padding: 24px 16px;

      .ant-modal-header {
        .anticon {
          display: none;
        }
      }

    }

    .test-item-list-v2 {
      padding: 0;
      border: 0;
    }
  }
}