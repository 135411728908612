@import '../../../../colors.scss';

.confirm-appointment {
  .detail-list {
    background: $secondary_bg;
    border-radius: 4px;
    padding: 5px 10px;
  }

  .detail-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 30px;
  }

  .detail-label {
    font-weight: 600;
    font-size: 13px;
    line-height: 1.2;
  }

  .detail-value {
    font-size: 13px;
    text-align: right;
    line-height: 1.2;
  }
}