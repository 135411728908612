@import '../../colors.scss';
@import '../../global.scss';

@mixin styling($horizontal_padding,
    $vertical_padding,
    $h1,
    $h1_line_height,
    $h2,
    $h2_line_height,
    $h3,
    $h3_line_height,
    $h4,
    $h4_line_height,
    $h5,
    $h5_line_height,
    $h6,
    $h6_line_height,
) {


    .packages {
        padding: 2rem 2rem 4rem;
        max-width: 1200px;
        margin: 0 auto;

        .packages-header {
            text-align: center;
            margin-bottom: 3rem;
            // padding: 2rem 0;

            h1 {
                font-size: $h2;
                margin-bottom: 0.5rem;
                color: #333;
                font-weight: 600;
            }

            p {
                font-size: 1.2rem;
                color: #666;
                max-width: 600px;
                margin: 0 auto;
            }
        }

        .packages-container {
            display: grid;
            grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
            gap: 2rem;
        }

        .package-card {
            display: grid;
            grid-template-rows: auto auto auto auto 1fr;
            padding: 2rem;
            border: 1px solid #e8e8e8;
            border-radius: 8px;
            background: white;

            .package-title {
                font-size: 1.5rem;
                margin-bottom: 1rem;
                min-height: 3.6rem;
                display: flex;
                align-items: center;
                font-weight: 700;
            }

            .package-description {
                color: #666;
                font-size: $h5;
                min-height: 4.5rem;
            }

            .price-display {
                font-size: 2rem;
                font-weight: 600;
                margin-bottom: 1.5rem;
                // text-align: center;
            }

            .book-now-btn {
                margin-bottom: 2rem;
                width: 100%;
                background-color: #1F2937;
                color: #F9FAFB;
                font-weight: 600;
                border: none;
                transition: background-color 0.2s ease;

                &:hover {
                    background-color: #374151 !important;
                }
            }

            .package-includes {
                position: relative;
                padding-top: 1.5rem;
                font-size: $h5;

                &:before {
                    content: '';
                    position: absolute;
                    top: 0;
                    left: -2rem;
                    right: -2rem;
                    height: 1px;
                    background: #e8e8e8;
                }

                h3 {
                    margin-bottom: 1rem;
                }

                ul {
                    list-style-type: none;
                    padding: 0;
                    margin: 0;

                    li {
                        margin-bottom: 1rem;
                        padding-left: 1.5rem;
                        position: relative;
                        display: flex;
                        align-items: flex-start;
                        justify-content: space-between;

                        &:before {
                            content: '';
                            position: absolute;
                            left: 0;
                            top: 0.25em;
                            background-image: url('../../assets/svg/tick.svg');
                            background-size: contain;
                            background-repeat: no-repeat;
                            width: 1em;
                            height: 1em;
                            color: $success;
                        }

                        &:last-child {
                            margin-bottom: 0;
                        }

                        .info-icon {
                            margin-left: 1rem;
                            color: #666;
                            cursor: pointer;
                            flex-shrink: 0;
                            margin-top: 0.25rem;
                        }
                    }
                }
            }
        }

        // Add recommended badge styling
        // .package-card:nth-child(2) {
        //     position: relative;
        //     border: 2px solid #ffd700;

        //     &::before {
        //         content: 'Recommended';
        //         position: absolute;
        //         top: 1rem;
        //         right: 1rem;
        //         background: #ffd700;
        //         padding: 0.25rem 0.75rem;
        //         border-radius: 20px;
        //         font-size: 0.8rem;
        //         font-weight: 500;
        //     }
        // }
    }


}



@media screen and (max-width: 768px) {
    @include styling($horizontal_padding: $mobile_horizontal_padding,
        $vertical_padding: $mobile_vertical_padding,
        $h1: $mobile_h1,
        $h1_line_height: $mobile_h1_line_height,
        $h2: $mobile_h2,
        $h2_line_height: $mobile_h2_line_height,
        $h3: $mobile_h3,
        $h3_line_height: $mobile_h3_line_height,
        $h4: $mobile_h4,
        $h4_line_height: $mobile_h4_line_height,
        $h5: $mobile_h5,
        $h5_line_height: $mobile_h5_line_height,
        $h6: $mobile_h6,
        $h6_line_height: $mobile_h6_line_height,
    );



}

@media screen and (min-width: 769px) {
    @include styling($horizontal_padding: $desktop_horizontal_padding,
        $vertical_padding: $desktop_vertical_padding,
        $h1: $desktop_h1,
        $h1_line_height: $desktop_h1_line_height,
        $h2: $desktop_h2,
        $h2_line_height: $desktop_h2_line_height,
        $h3: $desktop_h3,
        $h3_line_height: $desktop_h3_line_height,
        $h4: $desktop_h4,
        $h4_line_height: $desktop_h4_line_height,
        $h5: $desktop_h5,
        $h5_line_height: $desktop_h5_line_height,
        $h6: $desktop_h6,
        $h6_line_height: $desktop_h6_line_height,
    );
}