.admin-foods {
    background-color: #fff;

    .no-image-placeholder {
        width: 60px;
        height: 60px;
        background-color: #f5f5f5;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 12px;
        color: #999;
        border: 1px solid #e8e8e8;
        border-radius: 4px;
    }

    // Fix the name column width
    .name-column {
        min-width: 200px !important;
        width: 200px !important;
    }



    // Make sure the table is properly responsive
    @media (max-width: 768px) {
        .ant-table {
            font-size: 12px;

            .ant-table-cell {
                padding: 8px;
            }
        }

        .ant-btn-sm {
            font-size: 12px;
            padding: 0 4px;
        }
    }

    // Enhance fixed columns with shadows
    .ant-table-container {
        .ant-table-fixed-left {
            .ant-table-cell-fix-left-last {
                box-shadow: 6px 0 6px -4px rgba(0, 0, 0, 0.1);
            }
        }

        .ant-table-fixed-right {
            .ant-table-cell-fix-right-first {
                box-shadow: -6px 0 6px -4px rgba(0, 0, 0, 0.1);
            }
        }
    }

    // Better table styles for fixed columns
    .ant-table-sticky-scroll {
        position: sticky !important;
        bottom: 0;
        z-index: 2;
        background: #fff;
    }

    // Ensure the table has enough horizontal space
    .ant-table-wrapper {
        overflow-x: auto;

        .ant-spin-nested-loading {
            overflow: visible;
        }

        .ant-spin-container {
            overflow: visible !important;
        }
    }
}