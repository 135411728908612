@import '../../../colors.scss';
@import '../../../global.scss';

@mixin styling($horizontal_padding,
    $vertical_padding,
    $h1,
    $h1_line_height,
    $h2,
    $h2_line_height,
    $h3,
    $h3_line_height,
    $h4,
    $h4_line_height,
    $h5,
    $h5_line_height,
) {
    .product-detail-questions {
        padding: 0;
        margin-left: auto;
        margin-right: auto;

        .ant-collapse-header {
            padding: 20px 0px !important;
        }

        .ant-collapse-header-text {
            font-size: $h4 !important;
            line-height: $h4_line_height !important;
            font-weight: 600 !important;
        }

        .ant-collapse-expand-icon {
            font-size: $h5;
            line-height: $h5_line_height;
            margin-top: 2px;
        }

        .answer {
            font-size: $h4;
            line-height: $h4_line_height;
        }

        .ant-collapse-arrow {
            font-size: $h5;
            line-height: $h5_line_height;
        }
    }
}


@media screen and (max-width: $breakpoint_tablet) {
    @include styling($horizontal_padding: $mobile_horizontal_padding,
        $vertical_padding: $mobile_vertical_padding,
        $h1: $mobile_h1,
        $h1_line_height: $mobile_h1_line_height,
        $h2: $mobile_h2,
        $h2_line_height: $mobile_h2_line_height,
        $h3: $mobile_h3,
        $h3_line_height: $mobile_h3_line_height,
        $h4: $mobile_h4,
        $h4_line_height: $mobile_h4_line_height,
        $h5: $mobile_h5,
        $h5_line_height: $mobile_h5_line_height,

    );
}

@media screen and (min-width: $breakpoint_tablet) {
    @include styling($horizontal_padding: $desktop_horizontal_padding,
        $vertical_padding: $desktop_vertical_padding,
        $h1: $desktop_h1,
        $h1_line_height: $desktop_h1_line_height,
        $h2: $desktop_h2,
        $h2_line_height: $desktop_h2_line_height,
        $h3: $desktop_h3,
        $h3_line_height: $desktop_h3_line_height,
        $h4: $desktop_h4,
        $h4_line_height: $desktop_h4_line_height,
        $h5: $desktop_h5,
        $h5_line_height: $desktop_h5_line_height,

    );
}