@import '../../colors.scss';
@import '../../global.scss';

.settings {
    width: 100%;

    .patient-header-container {
        background: $secondary_bg;

        .patient-header {
            margin-left: auto;
            margin-right: auto;
            width: 100%;
            padding: 15px;
            border-bottom: 1px solid $border;
            align-items: center;

            .user-name {
                color: $primary_text;
                font-weight: 600;
            }

            .user-demographics {
                color: $secondary_text;
                font-weight: 500;
                margin-left: 2px;
            }



        }
    }

    .tabs-container {
        background: $secondary_bg;

        .ant-tabs-content-holder {
            background: white;
        }

        .ant-tabs-tab {
            padding: 10px 15px !important;
        }

    }

    .view-patient-skeleton {
        width: 100%;
        padding: 0 15px;
    }

    .ant-skeleton-paragraph li,
    .ant-skeleton-title {
        height: 30px !important;
    }
}