@import '../../colors.scss';

.order-modal {
  .ant-select-arrow {
    margin-top: -5px;
  }



  .ant-select .ant-select-selector .ant-select-selection-item {
    height: unset;
  }

  .ant-select-multiple .ant-select-selection-item {
    margin-top: 2px;
    margin-bottom: 2px;
  }

  .ant-select-multiple .ant-select-selector {
    height: auto;
    min-height: 32px;
    padding: 2px 4px;
  }
}