@import '../../colors.scss';
@import '../../global.scss';

.kit-activation-container {
    max-width: 700px;
    margin: 40px auto;
    padding: 0 35px;
    min-height: 50vh;

    .help-link {
        color: gray;
    }


    .kit-activation-loading {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 300px;

        p {
            margin-top: 16px;
            color: #666;
        }
    }


    .kit-activation-card {
        box-shadow: 0 4px 12px rgba(0, 0, 0, 0.08);

        .help-link {
            color: $success;
        }


        .page-title {
            text-align: center;
            margin-bottom: 24px;

            .anticon {
                margin-right: 12px;
                color: $success;
            }
        }

        .activation-steps {
            margin-bottom: 32px;

            @media (max-width: 576px) {
                display: none;
            }

            &.ant-steps {

                // This is the key selector for horizontal step connectors
                &.ant-steps-horizontal,
                &:not(.ant-steps-vertical) {
                    .ant-steps-item-title {
                        &::after {
                            // This is the actual line between steps in horizontal mode
                            background-color: #e8e8e8; // Default state
                        }
                    }

                    // For completed steps, make the line green
                    .ant-steps-item-finish {
                        .ant-steps-item-title {
                            &::after {
                                background-color: $success !important;
                            }
                        }
                    }
                }

                // Other styling for icons, etc.
                .ant-steps-item-finish {
                    .ant-steps-item-icon {
                        border-color: $success;

                        .ant-steps-icon {
                            color: $success;
                        }
                    }
                }

                .ant-steps-item-process {
                    .ant-steps-item-icon {

                        .ant-steps-icon {
                            color: $success;
                        }
                    }
                }
            }
        }

        .kit-selection {
            .instructions {
                font-size: 16px;
                margin-bottom: 24px;
            }

            .loading-kits {
                text-align: center;
                padding: 40px 0;

                .ant-typography {
                    display: block;
                    margin-top: 16px;
                }
            }

            .kit-radio-group {
                width: 100%;
                display: flex;
                flex-direction: column;
                gap: 16px;

                .kit-option {
                    // margin-bottom: 16px;
                    border-radius: 8px;
                    position: relative;
                    cursor: pointer;
                    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.09); // Subtle shadow

                    // Hover state - subtle visual feedback
                    &:hover {
                        border-color: #d9d9d9; // Slightly darker border
                        transform: scale(1.01);
                    }

                    .hidden-radio {

                        // Hide the actual radio button
                        .ant-radio {
                            position: absolute;
                            opacity: 0;
                            height: 0;
                            width: 0;
                            pointer-events: none;
                        }

                        // Make the content fill the entire clickable area
                        width: 100%;
                        display: block;

                        // Remove any inner paddings from ant-radio-wrapper
                        &.ant-radio-wrapper {
                            margin-right: 0;
                            padding: 0;
                        }
                    }

                    .kit-details-container {
                        padding: 0px 24px;
                        width: 100%;

                        .kit-name {
                            font-weight: 500;
                            font-size: 16px;
                            color: #000000;
                        }

                        .kit-meta {
                            margin-top: 8px;
                            color: #8c8c8c;
                            font-size: 14px;
                        }
                    }
                }
            }
        }

        .activation-form {
            .selected-kit-info {
                background-color: #f9f9f9;
                padding: 16px;
                border-radius: 8px;
                margin-bottom: 24px;

                .ant-typography {
                    margin: 0;

                    &:first-child {
                        margin-bottom: 8px;
                    }
                }
            }

            .instructions {
                font-size: 16px;
                margin-bottom: 24px;
            }

            .error-message {
                margin-bottom: 24px;
            }

            .form-buttons {
                display: flex;
                justify-content: flex-end;
            }
        }

        .success-content {
            .anticon {
                color: $success;
            }

            .kit-details {
                margin-top: 32px;
                background-color: #f9f9f9;
                padding: 20px;
                border-radius: 8px;

                .detail-item {
                    margin-bottom: 12px;
                    display: flex;

                    .ant-typography {
                        &:first-child {
                            width: 150px;
                            flex-shrink: 0;
                        }
                    }
                }
            }
        }

        .help-section {
            text-align: center;
            margin-top: 32px;
            padding-top: 20px;
            border-top: 1px solid #f0f0f0;

            .help-text {
                color: #666;
                font-size: 14px;
            }
        }
    }

    // Mobile responsiveness
    @media (max-width: 576px) {

        margin: 20px auto;

        .kit-activation-card {
            .activation-form .form-buttons {
                flex-direction: column;

                button {
                    width: 100%;
                    margin-right: 0 !important;
                    margin-bottom: 12px;
                }
            }

            .success-content .kit-details .detail-item {
                flex-direction: column;

                .ant-typography:first-child {
                    width: 100%;
                    margin-bottom: 4px;
                }
            }
        }
    }
}

// Disable card hover effects
.no-hover-effect {
    &.ant-card {
        transition: none;
        box-shadow: none !important;

        &:hover {
            box-shadow: none !important;
            border-color: #e8e8e8 !important; // Keep the default border color
            transform: none !important;
        }
    }
}

// For the specific card
.kit-activation-card {
    &:hover {
        cursor: default; // Ensure cursor doesn't change on hover
    }
}