@import '../../colors.scss';
@import '../../global.scss';

@mixin styling($horizontal_padding,
    $vertical_padding,
    $h1,
    $h1_line_height,
    $h2,
    $h2_line_height,
    $h3,
    $h3_line_height,
    $h4,
    $h4_line_height,
    $h5,
    $h5_line_height,
    $h6,
    $h6_line_height,
) {

    // Update existing order button styles
    .order-button-container {
        .order-button {
            background: linear-gradient(to right, #0ca37f, #09866a);
            border: none;
            font-weight: 600;
            height: auto;
            padding: 10px 20px;
            border-radius: 8px;
            box-shadow: 0 2px 8px rgba(12, 163, 127, 0.2);
            transition: all 0.3s;

            .ant-radio-group .ant-radio-wrapper-checked {
                background: unset !important;
                border-color: unset !important;
            }

            .ant-radio-group .ant-radio-wrapper {
                border: unset !important;
                padding: unset !important;
            }

            .anticon {
                margin-right: 8px;
                font-size: 16px;
            }

            &:hover,
            &:focus {
                background: linear-gradient(to right, #09866a, #076e58);
                transform: translateY(-2px);
                box-shadow: 0 4px 12px rgba(12, 163, 127, 0.3);
            }

            &:active {
                transform: translateY(0);
                box-shadow: 0 2px 4px rgba(12, 163, 127, 0.2);
            }
        }
    }

    .order-accordion-container {
        margin-bottom: 16px;

        .order-button {
            background: $success; //linear-gradient(to right, #0ca37f, #09866a);
            border: none;
            font-weight: 600;
            height: auto;
            padding: 10px 20px;
            border-radius: 8px;
            box-shadow: 0 2px 8px rgba(12, 163, 127, 0.2);
            transition: all 0.3s;

            .anticon {
                margin-right: 8px;
                font-size: 16px;
            }

            &:hover,
            &:focus {
                background: linear-gradient(to right, #09866a, #076e58);
                transform: translateY(-2px);
                box-shadow: 0 4px 12px rgba(12, 163, 127, 0.3);
            }

            &:active {
                transform: translateY(0);
            }
        }

        .order-options-accordion {
            margin-top: 16px;

            .test-options-collapse {
                background-color: white;
                border-radius: 8px;
                overflow: hidden;
                box-shadow: 0 2px 10px rgba(0, 0, 0, 0.08);
                border: none;

                .ant-collapse-item {
                    border-bottom: 1px solid #f0f0f0;

                    &:last-child {
                        border-bottom: none;
                    }

                    &.option-panel {
                        &.recommended-panel {
                            .ant-collapse-header {
                                background-color: rgba(12, 163, 127, 0.05);

                                &:hover {
                                    background-color: rgba(12, 163, 127, 0.08);
                                }

                                .panel-header-content {
                                    .panel-title {
                                        color: #0ca37f;
                                    }
                                }
                            }
                        }

                        .ant-collapse-header {
                            padding: 16px 20px;
                            transition: background-color 0.2s;

                            &:hover {
                                background-color: rgba(0, 0, 0, 0.02);
                            }

                            .panel-header-content {
                                display: flex;
                                justify-content: space-between;
                                align-items: center;
                                width: 100%;

                                .panel-title-area {
                                    display: flex;
                                    align-items: center;
                                    gap: 12px;

                                    .panel-title {
                                        font-size: 16px;
                                        font-weight: 600;
                                        color: #333;
                                    }

                                    .recommended-tag,
                                    .best-value-tag {
                                        font-size: 12px;
                                        padding: 1px 8px;
                                        border-radius: 4px;
                                        font-weight: 500;
                                        display: flex;
                                        align-items: center;
                                        gap: 4px;
                                        height: auto;
                                        line-height: normal;

                                        .anticon {
                                            font-size: 10px;
                                        }
                                    }
                                }

                                .panel-price {
                                    font-size: 18px;
                                    font-weight: 700;
                                    color: #333;
                                }
                            }
                        }

                        .ant-collapse-content {
                            .ant-collapse-content-box {
                                padding: 0;
                            }
                        }
                    }
                }

                .panel-details {
                    padding: 16px 20px 20px;
                    background-color: #f9f9f9;
                    border-top: 1px solid #f0f0f0;

                    .panel-description {
                        font-size: 14px;
                        color: #555;
                        margin-bottom: 16px;
                        line-height: 1.5;
                    }

                    .panel-includes {
                        margin-bottom: 16px;

                        .includes-label {
                            font-size: 14px;
                            font-weight: 500;
                            margin-bottom: 8px;
                            color: #444;
                            display: flex;
                            align-items: center;
                            gap: 6px;
                        }

                        .includes-tags {
                            display: flex;
                            flex-wrap: wrap;
                            gap: 8px;
                            padding-left: 4px;

                            .tag-item {
                                margin: 0;
                                font-size: 12px;
                                padding: 4px 10px;
                                border-radius: 6px;
                                transition: all 0.2s;

                                &.highlighted-tag {
                                    font-weight: 600;
                                    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
                                }
                            }

                            .show-more-button,
                            .show-less-button {
                                height: auto;
                                padding: 4px 10px;
                                font-size: 12px;
                                color: #666;
                                border: 1px dashed #d9d9d9;
                                border-radius: 6px;
                                background: rgba(0, 0, 0, 0.02);

                                &:hover {
                                    color: #0ca37f;
                                    border-color: #0ca37f;
                                    background: rgba(12, 163, 127, 0.05);
                                }

                                .anticon {
                                    font-size: 10px;
                                    margin-left: 4px;
                                }
                            }
                        }
                    }

                    .panel-benefits {
                        margin-bottom: 20px;

                        .benefits-label {
                            font-size: 14px;
                            font-weight: 500;
                            margin-bottom: 8px;
                            color: #444;
                        }

                        .benefits-list {
                            margin: 0;
                            padding-left: 20px;

                            li {
                                font-size: 14px;
                                color: #555;
                                margin-bottom: 6px;

                                &:last-child {
                                    margin-bottom: 0;
                                }
                            }
                        }
                    }

                    .panel-action {
                        display: flex;
                        flex-direction: column;
                        align-items: flex-start;

                        .order-now-button {
                            background: linear-gradient(to right, #0ca37f, #09866a);
                            border: none;
                            padding: 8px 16px;
                            height: auto;
                            font-weight: 600;
                            font-size: 14px;
                            border-radius: 6px;
                            margin-bottom: 12px;

                            .anticon {
                                margin-right: 6px;
                            }
                        }

                        .secure-checkout {
                            font-size: 12px;
                            color: #666;
                            display: flex;
                            align-items: center;
                            gap: 6px;

                            .anticon {
                                color: #0ca37f;
                            }
                        }
                    }
                }
            }
        }
    }

    // Mobile footer styles
    .mobile-footer {
        .order-accordion-container {
            width: 100%;

            .order-button {
                width: 100%;
            }

            .order-options-accordion {
                margin-bottom: 60px; // Add space for the fixed footer

                .test-options-collapse {
                    .panel-header-content {
                        flex-wrap: wrap;
                        gap: 8px;

                        .panel-title-area {
                            width: 100%;
                            justify-content: space-between;
                        }
                    }

                    .panel-details {
                        .panel-action {
                            .order-now-button {
                                width: 100%;
                            }
                        }
                    }
                }
            }
        }
    }

    // Responsive adjustments
    @media (max-width: 768px) {
        .order-accordion-container {
            .order-options-accordion {
                .test-options-collapse {
                    .ant-collapse-item {
                        .ant-collapse-header {
                            padding: 12px 16px;

                            .panel-header-content {
                                .panel-title-area {
                                    .panel-title {
                                        font-size: 15px;
                                    }
                                }

                                .panel-price {
                                    font-size: 16px;
                                }
                            }
                        }
                    }

                    .panel-details {
                        padding: 16px;
                    }
                }
            }
        }
    }

    .test-selection-container {
        // margin: 20px 0;
        background-color: white;
        // border-radius: 12px;
        padding: 20px;
        box-shadow: 0 1px 4px rgba(0, 0, 0, 0.05);
        border: 1px solid #f0f0f0;
    }

    .test-selection-content {
        .selection-title {
            font-size: 18px;
            font-weight: 500;
            color: #333;
            margin-top: 0;
            margin-bottom: 16px;
            padding-bottom: 12px;
            border-bottom: 1px solid #f0f0f0;
        }

        .test-radio-group {
            width: 100%;
            display: flex;
            flex-direction: column;
            gap: 12px;

            .test-option {
                border: 1px solid #f0f0f0;
                border-radius: 8px;
                overflow: hidden;
                transition: all 0.2s;

                &:hover {
                    border-color: #d9d9d9;
                }

                &.selected {
                    border-color: #0ca37f;
                    box-shadow: 0 1px 4px rgba(12, 163, 127, 0.1);
                }

                .option-header {
                    display: flex;
                    justify-content: space-between;
                    align-items: baseline;
                    padding: 12px 16px;
                    background-color: #fafafa;
                    font-size: $h6;

                    .ant-radio-wrapper {
                        margin-right: 0;


                        .option-title {
                            font-weight: 500;
                            margin-right: 8px;
                        }

                        .option-tag {
                            font-size: 11px;
                            padding: 0 6px;
                            margin-left: 4px;
                            height: auto;
                            line-height: 1.5;
                            border-radius: 4px;
                            vertical-align: text-top;
                        }
                    }

                    .option-price {
                        font-weight: 600;
                        color: #333;
                        flex-shrink: 0;
                        // line-height: 2;
                    }
                }

                .option-details {
                    padding: 0px 16px 16px;
                    background-color: white;
                    border-top: 1px solid #f5f5f5;

                    .option-description {
                        font-size: 14px;
                        color: #666;
                        margin-bottom: 12px;
                        line-height: 1.5;
                    }

                    .option-includes {
                        margin-top: 12px;
                        padding: 14px;
                        background-color: rgba(0, 0, 0, 0.02);
                        border-radius: 8px;
                        border: 1px solid #f0f0f0;


                        .includes-label {
                            font-size: 14px;
                            font-weight: 600;
                            margin-bottom: 8px;
                            color: #555;
                            display: flex;
                            align-items: center;
                            gap: 6px;
                        }

                        .includes-tags {
                            display: flex;
                            flex-wrap: wrap;
                            gap: 8px;
                            padding-left: 4px;

                            .tag-item {
                                margin: 0;
                                font-size: 12px;
                                padding: 4px 10px;
                                border-radius: 6px;
                                transition: all 0.2s;

                                &.highlighted-tag {
                                    font-weight: 600;
                                    background-color: $warning_bg;
                                }
                            }

                            .show-more-button,
                            .show-less-button {
                                height: auto;
                                padding: 4px 10px;
                                font-size: 12px;
                                color: #666;
                                border: 1px dashed #d9d9d9;
                                border-radius: 6px;
                                background: rgba(0, 0, 0, 0.02);

                                &:hover {
                                    color: #0ca37f;
                                    border-color: #0ca37f;
                                    background: rgba(12, 163, 127, 0.05);
                                }

                                .anticon {
                                    font-size: 10px;
                                    margin-left: 4px;
                                }
                            }
                        }
                    }


                }

                .option-benefits {
                    ul {
                        margin: 0;
                        padding-left: 14px;

                        li {
                            font-size: $h6;
                            color: #555;
                            margin-bottom: 6px;

                            &:last-child {
                                margin-bottom: 0;
                            }
                        }
                    }
                }
            }
        }

        .order-action {
            margin-top: 20px;
            display: flex;
            flex-direction: column;
            align-items: center;

            .learn-more-button {
                height: auto;
                width: 100%;
                margin-bottom: 8px;
            }

            .order-button {
                height: auto;
                padding: 10px 24px;
                font-size: $h5;
                font-weight: 600;
                background: $success; //linear-gradient(to right, #0ca37f, #09866a);
                border: none;
                border-radius: 8px;
                box-shadow: 0 2px 6px rgba(12, 163, 127, 0.2);
                margin-bottom: 8px;
                width: 100%;

                &:hover {
                    background: linear-gradient(to right, #09866a, #076e58);
                    box-shadow: 0 3px 8px rgba(12, 163, 127, 0.3);
                }


            }

            .secure-payment {
                font-size: 12px;
                color: #666;
                display: flex;
                align-items: center;
                gap: 6px;

                .anticon {
                    color: $success;
                }
            }
        }
    }

    .mobile-order-button-container {
        .secure-payment {
            width: 100%;
            text-align: center;
            justify-content: center;
            font-size: 12px;
            color: #666;
            display: flex;
            align-items: center;
            gap: 6px;


            .anticon {
                color: $success;
            }
        }
    }

    // Mobile order button
    .mobile-order-button {
        height: auto;
        padding: 12px 20px;
        font-size: $h5;
        font-weight: 600;
        background: $success;
        border: none;
        border-radius: 8px;
        box-shadow: 0 2px 6px rgba(12, 163, 127, 0.2);
        margin: 10px 0;

        &:hover {
            background: linear-gradient(to right, #09866a, #076e58);
            box-shadow: 0 3px 8px rgba(12, 163, 127, 0.3);
        }

        .anticon {
            margin-right: 8px;
        }

    }

    // Mobile drawer styles
    .mobile-order-drawer {

        .ant-drawer-header {
            padding: 14px 16px;
            border-bottom: 1px solid #f0f0f0;

            .ant-drawer-title {
                font-size: $h5;
                font-weight: 500;
                color: #333;
            }
        }

        .ant-drawer-body {
            padding: 16px;
            max-height: calc(80vh - 55px);
            overflow-y: auto;
        }

        .test-selection-content {
            .selection-title {
                display: none; // Hide title as drawer already has one
            }

            .test-radio-group {
                .test-option {
                    .option-header {
                        padding: 10px 12px;

                        .ant-radio-wrapper {
                            width: 100%;
                            margin-bottom: 8px;

                            .option-title {
                                font-size: $h6;
                            }
                        }

                        .option-price {
                            font-size: $h6;
                            margin-left: 30px; // Align with radio text
                        }
                    }

                    .option-details {
                        padding: 0px 12px 12px;

                        .option-description {
                            font-size: $h6;
                        }

                        .option-benefits {
                            ul {
                                li {
                                    font-size: $h6;
                                }
                            }
                        }
                    }
                }
            }
        }

        .order-action {
            margin-top: 16px;

            .order-button {
                max-width: none;
                width: 100%;
            }
        }
    }

    // Special styling for the test chart component
    .test-chart-component {
        .test-selection-container {
            margin: 0;
            border-radius: 0;
            border-left: none;
            border-right: none;
            box-shadow: none;
            background-color: #fafafa;
        }
    }

    .test-selection-content {

        // Direct child selector for .test-radio-group
        >.test-radio-group {
            &.ant-radio-group {

                // Target radio wrappers directly
                .ant-radio-wrapper {
                    border: unset !important;
                    padding: unset !important;

                    &-checked {
                        background: unset !important;
                        border-color: unset !important;
                    }
                }
            }

            // Ensure test-option takes precedence for styling
            .test-option {
                cursor: pointer;

                &:hover {
                    border-color: #d9d9d9;
                }

                &.selected {
                    border-color: $success;
                    box-shadow: 0 1px 4px rgba(12, 163, 127, 0.1);
                }
            }
        }
    }
}


@media screen and (max-width: 768px) {
    @include styling($horizontal_padding: $mobile_horizontal_padding,
        $vertical_padding: $mobile_vertical_padding,
        $h1: $mobile_h1,
        $h1_line_height: $mobile_h1_line_height,
        $h2: $mobile_h2,
        $h2_line_height: $mobile_h2_line_height,
        $h3: $mobile_h3,
        $h3_line_height: $mobile_h3_line_height,
        $h4: $mobile_h4,
        $h4_line_height: $mobile_h4_line_height,
        $h5: $mobile_h5,
        $h5_line_height: $mobile_h5_line_height,
        $h6: $mobile_h6,
        $h6_line_height: $mobile_h6_line_height,
    );
}

@media screen and (min-width: 769px) {
    @include styling($horizontal_padding: $desktop_horizontal_padding,
        $vertical_padding: $desktop_vertical_padding,
        $h1: $desktop_h1,
        $h1_line_height: $desktop_h1_line_height,
        $h2: $desktop_h2,
        $h2_line_height: $desktop_h2_line_height,
        $h3: $desktop_h3,
        $h3_line_height: $desktop_h3_line_height,
        $h4: $desktop_h4,
        $h4_line_height: $desktop_h4_line_height,
        $h5: $desktop_h5,
        $h5_line_height: $desktop_h5_line_height,
        $h6: $desktop_h6,
        $h6_line_height: $desktop_h6_line_height,
    );
}