@import '../../colors.scss';

$width: 1020px;
$bg: #F9FBFC;

.home {
  // Shared
  .home-section {
    padding: 0 10px;
    width: 100%;
    max-width: $width;
    margin: 0 auto;
    position: relative;
  }

  // Fonts
  .home-h1 {
    font-weight: 600;
    font-size: 55px;
    line-height: 1.2;
    color: $primary_text;
  }

  .home-h2 {

  }

  .home-p1 {
    font-weight: 400;
    font-size: 26px;
    line-height: 1.5;
    color: $primary_text;
  }

  // Intro

  .home-intro {
    background: $bg;
    position: relative;
  }

  .intro-text-container {
    padding-left: calc((100vw - 1020px) / 2 + 10px);

    @media (max-width: 767px) {
      padding-left: 10px;
      padding-right: 10px;

      .home-h1 {
        margin-top: 50px;
      }

    }
  }

  .intro-h1 {
    margin-top: 100px;
    margin-bottom: 35px;
  }

  .intro-p1 {
    margin-top: 0;
    margin-bottom: 0;
  }

  .intro-img {
    width: 100%;
    z-index: 2;
    position: relative;
    aspect-ratio: 5/6;
    object-fit: cover;
    min-height: 100%;
  }

  .intro-img-lg {
    padding-right: calc((100vw - 1020px) / 2 + 10px);
    aspect-ratio: auto;
  }
  // Search

  .search-progress {
    transform: translateY(0);
    transition: transform 0.5s ease-out;
    z-index: 3;
    margin-top: 50px;
    top: 6px;

    &.searching {
      top: 25px;
      transform: translateY(-100%);
    }

    @media (min-width: 768px) {
      width: calc($width - 20px);
      margin-left: calc((100vw - 1020px) / 2 + 10px);
    }
    
    @media (max-width: 767px) {
      width: calc(100% - 20px);
      margin-left: 10px;
    }

    .ant-progress-outer {
      .ant-progress-inner {
        border-radius: 8px 8px 0 0 !important;
        height: 20px !important;
        
        .ant-progress-bg {
          border-radius: 8px 8px 0 0 !important;
          height: 20px !important;
        }
      }
    }
  }

  .home-search {
    @media (min-width: 768px) {
      width: calc($width - 20px);
      margin-left: calc((100vw - 1020px) / 2 + 10px);
      padding: 10px 15px 10px 20px;
      border-radius: 8px;
      margin-top: -20px;
      margin-bottom: 80px;
      box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    }
    
    @media (max-width: 767px) {
      width: calc(100vw - 20px);
      margin-left: 10px;
      margin-right: 0;
      padding: 15px;
      border-radius: 0;
      margin-top: -20px;
      margin-bottom: 40px;
      box-shadow: none;
      box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    }
    
    background: #fff;
    z-index: 5;
    position: relative;
  }

  .search-label {
    font-weight: 600;
    display: block;
    font-size: 16px;
    margin-top: 10px;
    margin-bottom: 0;
  }

  .info-icon {
    margin-left: 8px;
    color: $secondary_text;
    cursor: pointer;
    font-size: 14px;
  }

  .search-input {
    border: 0;
    font-size: 16px;
    padding-top: 9px;
    padding-bottom: 8px;
    padding-left: 0;
    width: 100%;
  }

  .search-select {
    width: 100%;

    .ant-select-selection-item {
      background: white;
      padding-top: 7px !important;
      padding-left: 0 !important;
    }

    .ant-select-selector {
      border: none !important;
      padding-left: 0 !important;
      padding-right: 0 !important;
      font-size: 16px;
      
      // For multiple select, remove left padding from tags and input
      .ant-select-selection-item,
      .ant-select-selection-search {
        padding-left: 0;
      }

      // Ensure input area aligns properly when no tags are selected
      .ant-select-selection-search-input {
        margin-left: 0 !important;
      }

      // Fix cursor position for multiple select
      .ant-select-selection-search {
        left: 0 !important;
        margin: 0 !important;
      }

      .ant-select-selection-overflow-item {
        margin-left: 0px !important;
      }

      // Fix placeholder position for multiple select
      .ant-select-selection-placeholder {
        left: 0 !important;
        margin-left: 0 !important;
        font-size: 16px !important;
      }
    }
  }
  
  .search-btn-col {
    @media (min-width: 768px) {
      width: 200px;
    }

    @media (max-width: 767px) {
      width: 100%;
      margin-top: 20px;
    }
  }

  .search-btn {
    width: 100%;
    height: 69px;
    margin-bottom: 4px;
    font-size: 18px;
    font-weight: 600;
  }
  
  // How It Works
  .how-section {
    padding-top: 40px;
    padding-bottom: 50px;
    
    @media (min-width: 768px) {
      padding-bottom: 70px;
    }
  }

  .how-section-item {
    margin-top: 30px;
  }
  
  .how-number {
    font-size: 80px;
    margin-bottom: 0;
    margin-top: 0;
    background: linear-gradient(
      to bottom,
      $success 80%,
      rgba(12, 163, 127, 0.4) 100%
    );
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
    display: inline-block;
  }

  .how-title {
    font-weight: 600;
    font-size: 21px;
    color: $primary_text;
    margin-bottom: 15px;
  }

  .how-description {
    font-size: 18px;
    color: $primary_text;
    margin-bottom: 20px;
  }

  // Trusted

  .home-trusted {
    padding: 70px 0 50px 0;
    background: #F8F5F3;
  }

  .trusted-h1 {
    text-align: center;
    margin-bottom: 15px;
    margin-top: 0;
    color: $primary_text;
  }

  .trusted-p1 {
    text-align: center;
    margin-top: 0;
    margin-bottom: 10px;
    color: $secondary_text;
  }

  .logo-scroll {
    overflow: hidden;
    white-space: nowrap;
    position: relative;
    width: 100%;
    padding-bottom: 85px;
    background: #F8F5F3;

    .logo-scroll-content {
      display: inline-block;
      animation: scroll 30s linear infinite;

      img {
        height: 40px;
        margin: 0 30px;
        vertical-align: middle;
        filter: grayscale(100%);
        opacity: 0.5;
        transition: all 0.3s ease;

        &:hover {
          filter: grayscale(0%);
          opacity: 1;
        }
      }
    }
}

  @keyframes scroll {
    0% {
      transform: translateX(0);
    }

    100% {
      transform: translateX(-100%);
    }
  }

  // Loading Recommended Tests

  .recommended-loading {
    background: $bg;
    height: 200px;
    display: flex;
    align-items: center;
    text-align: center;
  }

  .recommended-loading-text {
    font-size: 18px;
    color: rgba(0,0,0,0.4);

    .ant-spin {
      margin-right: 10px;
    }
  }

  // Recommended

  .recommended-section {
    background: $bg;
    padding: 60px 0;
  }

  .recommended-item-col {
    margin-bottom: 20px;
  }

  .insurance-accepted {
    color: $secondary_text;
  }

  .recommended-item {
    background: #fff;
    border-radius: 5px;
    overflow: hidden;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    height: 100%;
    position: relative;
    padding-bottom: 30px;
  }

  .recommended-item-footer {
    position: absolute;
    width: calc(100% - 40px);
    left: 20px;
    bottom: 20px;
  }

  .recommended-item-image {
    width: 100%;
    aspect-ratio: 5/4;
    object-fit: cover;
    object-position: top;

    .ant-skeleton,
    .ant-skeleton-image {
      width: 100% !important;
      height: 100% !important;
      aspect-ratio: 4/3;
    }
  }

  .recommended-item-content {
    padding: 20px 20px;
  }

  .recommended-item-title {
    font-weight: 600;
    font-size: 21px;
    color: $primary_text;
    margin-bottom: 15px;
  }

  .recommended-item-category {
    color: $success;
    background: #E7F6F2;
    font-size: 14px;
    padding: 4px 8px;
    border-radius: 4px;
    font-weight: 600;
    margin-bottom: 20px;
    display: inline-block;
  }
  
  .recommended-item-tagline {
    font-size: 18px;
    color: $primary_text;
    margin-bottom: 30px;
  }
  
  .recommended-item-cost {
    font-weight: 600;
    font-size: 21px;
    color: $primary_text;
  }

  .cost-info-icon {
    font-size: 21px;
    transform: scale(0.6);
    cursor: pointer;
    color: $secondary_text;
    margin-left: 5px;
  }

  .recommended-item-btn {
    float: right;
    height: 40px;
    font-weight: 600;
    font-size: 16px;
    padding: 0 10px;
    margin-top: -5px;
  }

  // Concierge

  .concierge-section {
    padding: 30px 0 60px 0;
  }

  .concierge-h1 {
    margin-top: 30px;
    margin-bottom: 10px;
  }
  
  .concierge-p1 {
    margin-top: 0;
    margin-bottom: 0;
  }

  .phone-container {
    position: relative;
    width: 324px;
    height: 720px;
    margin: 0 auto;

    .phone-frame {
      padding: 102px 19px 0px 20px;
      position: relative;
      width: 100%;
      height: 100%;
      background-image: url('../../assets/png/phone-small-frame.png');
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;

      .gif-slide {
        width: 100%;
        height: 100%;

        img.phone-video {
          width: 100%;
          height: 100%;
          object-fit: cover;
          object-position: center;
        }
      }
    }
  }

  .perks-container {
    border-radius: 8px;
    padding: 25px;
    margin-top: 30px;
    margin-bottom: 40px;
    background: linear-gradient(to right, #f0f7ff, #ffffff);
    border: 1px solid #91caff;
    
    .perk-item {
      display: flex;
      align-items: flex-start;
      margin-bottom: 25px;

      &:last-child {
        margin-bottom: 0;
      }

      .perk-check {
        color: $success;
        font-size: 18px;
        margin-right: 12px;
        margin-top: 4px;
      }

      .perk-content {
        .perk-title {
          font-weight: 600;
          font-size: 18px;
          color: #1a1a1a;
          margin-bottom: 4px;
          margin-top: 0;
        }

        .perk-description {
          font-size: 16px;
          color: #666;

          .perk-link {
            color: $success;
            cursor: pointer;
            border-bottom: 1px dotted $success;
            text-decoration: none;
          }
        }
      }

    }
  }

  .join-container {
    display: flex;
    align-items: center;
    gap: 20px;
  }

  .join-btn {
    padding: 0px 40px;
    height: 50px;
    font-size: 18px;
    font-weight: 600;
  }

  .join-price {
    color: $secondary_text;
    font-weight: 600;
    font-size: 18px;
  }

  .explore-all-btn {
    width: 100%;
    height: 60px;
    margin-top: 24px;
    background: white;
    border: 1px solid #f0f0f0;
    border-radius: 8px;
    font-size: 18px;
    font-weight: 500;
    color: $primary_text;
    padding: 0 24px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.02);
    transition: all 0.3s;
    position: relative;

    &:hover {
      background: #fafafa;
      border-color: #e8e8e8;
    }

    .anticon {
      font-size: 16px;
      position: absolute;
      right: 24px;
    }
  }

  .recommended-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 24px;
    white-space: nowrap;

    .recommended-h1 {
      margin: 30px 0;
      white-space: nowrap;
      padding-right: 20px;
    }

    .recommended-filter {
      width: calc(100% - 280px);
      text-align: right;

      @media (max-width: 768px) {
        display: none;
      }
    }

    .recommended-filter-label {
      font-size: 13px;
      text-transform: uppercase;
      font-weight: 300;
      color: $secondary_text;
      margin-right: 20px;
    }

    .recommended-filter-tabs {
      display: inline-block;
      width: auto;

      .ant-tabs-nav:before,
      .ant-tabs-nav {
        border-bottom: 0 !important;
      }

      .ant-tabs-tab {
        font-size: 13px;
        text-transform: uppercase;
        font-weight: 400;
        padding: 3px 10px;
        border-bottom: 0;
        background: #E7F6F2;
        margin: 0 4px;
        border-radius: 4px;

        .ant-tabs-tab-btn {
          color: $success;
        }
      }

      .ant-tabs-tab-active {
        background: $success;

        .ant-tabs-tab-btn {
          color: white !important;
        }
      }

      .ant-tabs-ink-bar {
        display: none;
      }
    }
  }

  .recommended-grid {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -12px;
    
    .recommended-grid-item {
      width: 33.333%;
      padding: 12px;
      
      .recommended-item-col {
        width: 100%;
        padding: 0;
      }
      
      .recommended-item {
        height: 100%;
        display: flex;
        flex-direction: column;
        
        .recommended-item-image {
          height: 200px;
          width: 100%;
          object-fit: cover;
        }
        
        .recommended-item-content {
          flex: 1;
          display: flex;
          flex-direction: column;
          
          .recommended-item-tagline {
            flex: 1;
          }
          
          .recommended-item-footer {
            margin-top: auto;
          }
        }
      }
    }
  }

  @media (max-width: 1200px) {
    .recommended-grid {
      .recommended-grid-item {
        width: 50%;
      }
    }
  }

  @media (max-width: 768px) {
    .recommended-grid {
      .recommended-grid-item {
        width: 100%;
      }
    }
  }
}

.biomarker-link {
  border-bottom: 1px dotted #666;
  cursor: help;
  font-size: 18px;

  
  &:hover {
    color: #0ca37f; // Your theme color
  }
}