@import '../../colors.scss';
@import '../../global.scss';

@mixin styling($horizontal_padding,
    $vertical_padding,
    $h1,
    $h1_line_height,
    $h2,
    $h2_line_height,
    $h3,
    $h3_line_height,
    $h4,
    $h4_line_height,
    $h5,
    $h5_line_height,
    $h6,
    $h6_line_height,
) {


    .recommendation-item {
        box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.1);
        border: 1px solid rgba(0, 0, 0, 0.15);
        border-radius: 5px;
        margin-bottom: 10px;
        cursor: pointer;
        background: white;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: space-between;



        .recommendation-item-title {
            font-size: $h4;
            line-height: $h4_line_height;
            font-weight: 500;
            color: #2c3e50;
            display: inline-block;
            margin-right: 10px;
        }

        .recommendation-arrow {
            color: #bbb;
            font-size: $h4;
            position: absolute;
            right: 16px;
            top: 50%; // Position at 50% from the top of the title container
            transform: translateY(-50%); // Perfect vertical centering
            transition: all 0.2s ease;

            @media screen and (max-width: $breakpoint_tablet) {
                display: none;
            }
        }

        .recommendation-content {
            flex: 1;
        }
    }



}


@media screen and (max-width: 768px) {
    @include styling($horizontal_padding: $mobile_horizontal_padding,
        $vertical_padding: $mobile_vertical_padding,
        $h1: $mobile_h1,
        $h1_line_height: $mobile_h1_line_height,
        $h2: $mobile_h2,
        $h2_line_height: $mobile_h2_line_height,
        $h3: $mobile_h3,
        $h3_line_height: $mobile_h3_line_height,
        $h4: $mobile_h4,
        $h4_line_height: $mobile_h4_line_height,
        $h5: $mobile_h5,
        $h5_line_height: $mobile_h5_line_height,
        $h6: $mobile_h6,
        $h6_line_height: $mobile_h6_line_height,
    );

}

@media screen and (min-width: 769px) {
    @include styling($horizontal_padding: $desktop_horizontal_padding,
        $vertical_padding: $desktop_vertical_padding,
        $h1: $desktop_h1,
        $h1_line_height: $desktop_h1_line_height,
        $h2: $desktop_h2,
        $h2_line_height: $desktop_h2_line_height,
        $h3: $desktop_h3,
        $h3_line_height: $desktop_h3_line_height,
        $h4: $desktop_h4,
        $h4_line_height: $desktop_h4_line_height,
        $h5: $desktop_h5,
        $h5_line_height: $desktop_h5_line_height,
        $h6: $desktop_h6,
        $h6_line_height: $desktop_h6_line_height,
    );
}